/* Functional Component styles */
.dropdown-wrapper {

  transition: border-color 100ms;
  background-color: #fff;
  border-style: solid;
  border-radius: var(--form-input-border-radius);
  border-width: var(--form-input-border-width);
  border-color: var(--form-input-border-color);
  margin: var(--form-input-wrapper-margin);

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    border-color: #bdbdbd;
  }
  
  &:focus-within {
    border-color: var(--color-light-main);

    svg, label {
      color: var(--color-main);
    }

    svg.arrowIcon {
      transform: rotate(180deg);
    }
  }

  label {

    top: -0.75rem;
    z-index: 1;
    padding: 0 10px;
    left: 0.5rem;

    &::after{
      content: "";
      height: 0.3rem;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff;
      z-index: -1;
    }

  }

  .selection {
    transition: background-color 100ms;
    display: flex;

    .dropdown-value, .dropdown-value-selected{
      padding: var(--form-input-padding);
      padding-right: 50px;
      flex: 1;
    }

    .dropdown-value {
      background-color: transparent;
      z-index: 1;
      outline: none;
      border: none;
      min-height: 48px;
      line-height: normal;
    }

    .dropdown-value-selected {
      z-index: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.placeholder {
        font-weight: 400;
        font-size: .9rem;
        opacity: 0.5;
      }
    }
    
    span {

      width: 30px;
      pointer-events: none;
      right: 0;
      top: 0;

      svg {
        height: 25px;
        width: 25px;
        transition: 150ms;
      }
    }

		span.selection__loader {
			bottom: 0;
    	right: 1.5rem;
		}

    .deleteIcon {
      right: 25px;
      z-index: 100;
      cursor: pointer;
      pointer-events: all;
  
      svg {
        cursor: pointer;
      }
    }
  }

  .selection:hover, .dropdown .item:hover {
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    width: 100%;
    left: 0;
    top: 120%;
    overflow-y: auto;
    max-height: 250px;
    z-index: 99;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d1d1d1;

		&__notFoundText {
			text-align: center;
			margin: 1rem 0;

			&--small {
				margin: .2rem 0;
				font-size: .8rem;
			}
		}
  }

  &.listOverflow {
    margin-bottom: 350px;
  }
}
