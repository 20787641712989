/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policy-wrapper{

  
  .policy-number {
    margin: 15px;

    h1 {
      font-size: 1.3rem;
    }
  }

  footer {
    border-top: var(--dashboard-border-style);
    border-radius: var(--dashboard-border-radius);
    box-shadow: var(--dashboard-box-shadow);
    background-color: #fff;
    position: sticky;
    bottom: 0;
    
    a {
      margin: 1rem;
    }

    & > a, & > button, & section > a, & section > button {
      font-size: .8rem;
      min-width: 200px;
      height: 40px;
      box-sizing: border-box;
      margin: 1rem;

      @media only screen and (max-width: 610px) {
        margin: 1rem auto;
      }
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    padding-bottom: 75px;

    footer {
      position: fixed;
      width: 100%;
    }
  }
}