/* Functional Component styles */
.dropdown-item {

  padding: 5px 10px;
  transition: 150ms;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}