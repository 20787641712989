div#personal-data-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;

	section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
	
		h1, p {
			text-align: center;
		}
		
		h1 {
			margin-top: 30px;
			margin-bottom: 10px;
		}
	
		p.text {
			margin-bottom: 50px;
		}

		form {
			width: 100%;
			max-width: 700px;

			div.form-section {
				width: 100%;
				justify-content: space-evenly;
				margin-bottom: 30px;

				@media only screen and (max-width: 540px){
					flex-direction: column;
					margin-bottom: 0;

					div.inline-input {
						margin-bottom: 10px;
					}
				}
			}
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;

			h1, p {
				margin: 0;
			}

			p.text {
				margin-bottom: 15px;
			}
		}
	}
}