/* Functional Component styles */
.card {

  border-radius: 10px;
  box-shadow: 0 2px 4px #dddddd;
  max-width: 375px;
  min-width: 300px;
  min-height: 120px;
  margin: 15px;
  transition: 100ms;
  background-color: #fff;

  &[card-hover]:hover {
    cursor: pointer;
    background-color: #f0f0f0;

    .card-arrow {
      color: var(--color-light-main);
    }
  }

  & > section.flex, & > section:last-of-type {
    padding: .8rem;
  }

  .item-image {
    width: 120px;

    img {
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .card-arrow{
    width: 20px;
    border-left: 1px solid #e0e0e0;
    color: var(--color-grey);
  }
}