/* Functional Component styles */
.skeleton-loader {
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #e2e2e2;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &[skeleton-type=text] {
    height: 1rem;
    max-width: 200px;
    margin: 5px;
  }

  &[skeleton-type=square] {
    height: 100%;
  }
  
  &[skeleton-type=radial] {
    border-radius: 50%;
    height: 100%;
  }
}
