/* Functional Component styles */
#profile-info-wrapper {
  padding: 1rem;

  h1 {
    margin-bottom: 2rem;
  }

  & > div {
    border-top: 1px solid var(--color-translucent-blue);

    .referral-code, .referral-count {
      margin: 25px 0;

      h2 {
        font-size: 0.8rem;
        font-weight: 600;
      }

      p {
        font-size: 1.5rem;
        font-weight: bold;

        button {
          margin-left: 10px;
        }
      }

      &.referral-count {
        svg {
          width: 40px;
          height: 40px;
          margin: 5px 0;
        }
      }
    }

    .profile-field {
      padding: 0.5rem;
      min-width: 120px;

      h2 {
        font-size: 1rem;
      }
    }
  }

  & > div:nth-of-type(1) {
    border-top: none;
  }

  @media only screen and (max-width: 500px) {

    h1 {
      margin-bottom: 1rem;
    }

    & > .space-evenly {

      justify-content: space-around;
      margin: 10px 0;

      .referral-code, .referral-count {
        margin: 0 5px;

        p {
          font-size: 1.2rem;
        }
      }
    }
    
    
    div.space-around{

      flex-direction: column;
      align-items: flex-start;  
      border-top: none;

      .profile-field {

        border-top: 1px solid var(--color-translucent-blue);
        box-sizing: border-box;
        width: 100%;

        h2, p {
          text-align: left;
          font-size: 1.1rem;
        }
      }

    }
  }
}
