/* Functional Component styles */
.picture-zoom-wrapper {

  .zoom-handler{
    
    left: 0;
    top: 0;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .zoom {

    width: 500px;
    height: 500px;
      
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    button {
      
      right: -10px;
      top: -10px;
      border-radius: 50%;
      border: none;
      height: 30px;
      width: 30px;
      box-shadow: 0 0 5px #727272;
      font-size: 1.2rem;
      background-color: #fff;

      svg {
        color: var(--color-grey);
      }
    }

    @media only screen and (max-width: 580px) {
      width: 300px;
      height: 300px;
    }
  }
}