@import "/src/styles/v1/variables/screen/screen.scss";

div.button-section {
	display: flex;
	height: 85px;
	width: 100%;
	align-items: center;
	justify-content: center;
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	position: sticky;
	bottom: 0;
	z-index: 999;

	button {
		width: 200px;
		height: 40px;
		letter-spacing: 0;
		margin: 0 10px;

		&.back-btn {
			border-width: 1px;
		}
	}

	button.skip {
		font-size: 1rem;
		position: absolute;
    right: 20px;
		width: 80px;
		background-color: #fff;
		cursor: pointer;
		border: none;
		color: var(--color-main);
	}

	@media only screen and (max-width: $mobileWidth) {
		position: fixed;
		bottom: 0px;
		margin-top: 40px;
		border-radius: 0px;
		flex-direction: column-reverse;
		justify-content: space-evenly;
		padding: 5px 0;

		button {
			height: 35px;
			font-size: .9rem;

			&.skip {
				bottom: 25px;
				top: auto;
				right: 0;
				box-shadow: none;
			}
		}
	}
}