/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policy-renewal-wrapper{

  & > div {
    min-height: calc(100vh - var(--header-height));
    padding: 1rem 2rem 2rem;

  }

  .renewal-content{

    border-radius: var(--dashboard-border-radius);
    border: var(--dashboard-border-style);

    &[is-last-step] {
      margin: 0;
      padding: 0;
      border: none;
    }

    .section-title{
      font-size: 1.5rem;
      letter-spacing: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  
    .section-subtitle{
      font-size: 1.1rem;
      font-weight: 400;
    }
    

    .button-wrapper {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
}