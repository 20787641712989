/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.policyManagement{

  $boxShadow: 0 0 10px var(--color-light-grey);
  $borderRadius: 8px;

  &__section {

    margin: 0 1rem;
    flex-wrap: wrap;

    &--actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: minmax(min-content, max-content);
      margin: 0 auto;

      .card {
        margin-left: 3rem;
        margin-right: 3rem;
      }
    }

    &__item {
      box-shadow: $boxShadow;
      border-radius: $borderRadius;
      padding: 1rem;
      margin: 1rem 1.5rem;
      min-width: 300px;

      & > h2 {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      a {
        margin: .5rem auto 0;
        max-width: 300px;
      }

      & > ul {
  
        li {
          font-size: .95rem;
          margin: 5px 0;
          padding-bottom: .55rem;
  
          span {
            font-weight: bold;
            margin-left: 10px;
          }
        }

        li:not(:last-child) {
          border-bottom: 1px solid var(--color-light-grey);
        }
      }

    }

    &__card {

      h2 {
        font-size: 1rem;
      }

      p {
        font-size: .8rem;
      }
    }
  }

  @media only screen and (max-width: $mobileWidth) {
   
    &__section {
  
      &--actions {
        grid-template-columns: 1fr;

        .card {

          max-width: 500px;
        }
      }
    }
  }
}