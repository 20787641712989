/* Functional Component styles */
#endorsements-configuration-wrapper {

  h1 {
    font-size: 1.4rem;
  }

  .endorsements-changes {

    padding: 2rem 2rem 0;
    margin: 1rem 0;
  }

  .endorsement-add-button{

    &[disabled-button] {
      opacity: .3;
      pointer-events: none;
    }

    button {
      background-color: transparent;
      border: none;
      margin: auto;

      svg, span {
        color: var(--color-main);
        font-weight: bold;
      }

      svg {
        border-radius: 50%;
        border: 2px solid var(--color-main);
        font-size: 1.3rem;
        padding: 2.5px;
      }

      span {
        margin: 5px 0;
      }
    }
  }

  @media only screen and (max-width: 750px)  {
    .endorsements-changes {

      padding: 0;
      margin: 0;
    }
  }
}