/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.plan-summary-management-wrapper {

	.back-button {
		padding-left: 0;
		text-align: left;
	}

	&.quoter {
		
		section.plan-summary-content {
			padding-bottom: 10rem;
		}

		section.plan-management-bottom-section {

			@media only screen and (max-width: $mobileWidth) {
				min-height: 230px;

				div.checkbox-wrapper {
					font-size: .85rem;
				}
			}
		}
	}
		
  .plan-summary-content {
    padding: 2rem;

    & > section:nth-of-type(1) {
    
      h1 {
        font-size: 1.6rem;
      }
  
      p {
        font-size: 1.1rem;
        font-weight: normal;
      }
    }

    & > section:nth-of-type(2) {
      padding: 1rem;
    }

    @media only screen and (max-width:800px) {
      padding: .5rem 0;

      & > section:nth-of-type(2) {
        padding: 1rem 0;
      }
      
    }

    section.flex {

			&.baseline {
				align-items: baseline;
			}

      & > aside {
        width: 300px;
        min-width: 300px;
        padding: 1rem;
        margin: 0 0.5rem;
        position: sticky;
        top: calc(var(--header-height) + 1rem);
        border-radius: 10px;
        box-shadow: 0 0 5px var(--color-light-grey);
    
				&.quoter {
					top: calc(var(--header-height) + 6rem);
				}

        h3 {
    
          font-size: 1rem;
          font-weight: 600;
        }
    
        .plan-payment-type {
          max-width: 300px;
          margin: 0 auto .5rem;
  
					p.segment {
						background-color: #f5f5f5;
    				padding: .6rem;
						border-radius: 30px;
						border: 1px solid #ccc;
						display: flex;
						justify-content: center;
						position: relative;
						max-width: 150px;
						margin: 1rem auto 1rem;
						box-shadow: 0 0 .5rem -.3rem var(--color-grey);
						user-select: none;
						font-size: .9rem;
						text-align: center;
						font-weight: bold;
					}
        }
    
        .plan-cost {
    
          margin: 0 auto;
          padding: 0.75rem;
          border-top: 1px solid var(--color-light-grey);
          transition: 100ms;
    
          p {
            font-size: .85rem;
    
            &.discount-text {
              margin: 1rem auto 0;
              max-width: 230px;
            }
          }
    
          h5 {
            font-size: 1.4rem;
            line-height: .9;
            margin-top: 7px;
            font-weight: bold;
            color: var(--color-main);
          }
    
          span {
            font-size: .85rem;
            color: var(--color-main);
            line-height: .8;
          }
    
          .text-line-through {
            font-size: 1rem;
          }
        }
    
        .plan-cost:nth-of-type(1){
          border-top: none;
        }
    
        .monthly-wrapper {
          margin: .4rem 0;
    
          & > section {
            margin-top: .5rem;
          }
    
          .monthly-cost {
            color: var(--color-main);
						font-size: 1.7rem;
          }
        }

				div.bottom-section {
					padding-top: 1.5rem;
					border-top: 1px solid var(--color-light-grey);

					form {
						text-align: left;
						max-width: 250px;
						margin: 0 auto;
						
						div {
							font-size: .8rem;
						}
					}

					button {
						margin: 1.5rem 0 0;
						max-width: 220px;
					}

					@media only screen and (max-width: $mobileWidth) {
						display: none;
					}
				}

				@media only screen and (max-width: $mobileWidth) {
					display: none;
				}
      }
    }
  }

  .plan-management-bottom-section {
    height: 120px;
		padding: 0.5rem 1rem;

		border-top-left-radius: 30px;
		border-top-right-radius: 30px;

		div.title {
			padding: .5rem 0 0;

			span {
				background-color: #f5f5f5;
				padding: .3rem 1rem;
				border-radius: 30px;
				border: 1px solid #ccc;

				position: relative;
				max-width: 150px;
				margin: 0 .5rem 0 0;
				box-shadow: 0 0 .5rem -.3rem var(--color-grey);
				user-select: none;
				font-size: .9rem;
				text-align: center;
				font-weight: bold;
			}
		}

		div.amounts {
			padding-top: 1.2rem;

			& > h3 {
				font-size: 2rem;
				color: var(--color-main);
			}
			
			div.subscription {
				
				div {

					h3 {
						color: var(--color-main);
						font-size: 1.6rem;
						line-height: 1;
					}

					p {
						font-size: .8rem;
					}
				}

				svg {
					font-size: 1.5rem;
					margin: 0 1rem;
				}
			}

			div.monthly {

				h5 {
					color: var(--color-main);
					font-size: 2rem;
					margin: 0;
					line-height: 1;
				}

				p {
					margin: 0;
					font-size: .9rem;
				}
			}
		}

		form {
			border-top: 1px solid var(--color-light-grey);
    	padding-top: 0.5rem;
		}

    .plan-button {
      margin: 0;
			max-width: 200px;

			button {
				width: 100%;
			}

			@media only screen and (max-width: $mobileWidth){
				max-width: 250px;

				button { 
					max-width: none;
				}
			}

			@media only screen and (max-width: 400px) {
				margin: .2rem;

				button {
					
					svg {
						display: none;
					}
				}
			}
    }

		button.payment-types {
			display: none;
		}

		@media only screen and (min-width: 1031px) {
			display: none;
		}

		@media only screen and (max-width: $mobileWidth) {
			height: 250px;
			max-height: none !important;

			button.payment-types {
				display: block;
				margin: 0 0 .5rem;
				color: var(--color-main);
				font-weight: 600;
			}
		}
  }

  @media only screen and (max-width:800px) {
    padding: 0;
  }

  @media only screen and (max-width: $mobileWidth) {
		&.default {
			margin-bottom: 90px;
		}
  }

	div.payment-type-modal {
		padding: 1rem;
		width: 300px;

		p.segment {
			background-color: #f5f5f5;
			padding: .6rem;
			border-radius: 30px;
			border: 1px solid #ccc;
			display: flex;
			justify-content: center;
			position: relative;
			max-width: 150px;
			margin: 1.5rem auto 1rem;
			box-shadow: 0 0 .5rem -.3rem var(--color-grey);
			user-select: none;
			font-size: .9rem;
			text-align: center;
			font-weight: bold;
		}

		svg {
			position: absolute;
			top: .5rem;
			right: .5rem;
			color: var(--color-grey);
			font-size: 1.5rem;
		}

		div.plan-cost {
			margin: 1rem 0;

			h5 {
				color: var(--color-main);
				font-size: 1.5rem;
				line-height: 1.2;
	
				&.text-line-through {
					margin: 1rem 0 0;
					font-size: 1rem;
				}
			}

			span {
				font-size: .9rem;
			}
	
			p.discount-text {
				font-size: .8rem;
			}

			div.monthly-wrapper {
				margin: .5rem 0 0;

				p {
					margin: .3rem 0;
				}

				h4 {
					font-size: 1.6rem;
					color: var(--color-main);

					&.text-line-through {
						font-size: .8rem;
						line-height: 1;
						margin: 0;
					}
				}
			}
		}

	}
}