@import "/src/styles/v1/variables/screen/screen.scss";

div#proposal-wrapper {
  h1 {
    margin: 30px 0;
  }

  section {
    div.claim-details {
      flex-wrap: wrap;
      border-bottom: 1px solid #d9d9d9;
      div.item {
        flex: 0 0 33.33%;
        margin-bottom: 20px;
        p {
          text-align: center;
          &:first-of-type {
            font-weight: bold;
          }
        }
      }
    }

    div.claim-terms {
      padding: 0 20px 20px;
      border-bottom: 1px solid #d9d9d9;
      h3 {
        margin: 40px 0 20px;
        font-size: 1.3rem;
      }
      p {
        text-align: justify;
        margin: 0 0 15px;
        line-height: 1.3;
      }

      
      @media only screen and (max-width: 650px) {
        h3 {
          margin: 15px 0;
        }
      }
    }

    div.claim-amount {
      flex-wrap: wrap;
      div.item {
        width: 25%;
        margin-bottom: 20px;
        p {
          text-align: center;
          &:first-of-type {
            font-weight: bold;
          }
        }
      }
    }

    .checkbox-accept-proposal{
      padding: 0px 40px 20px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    padding-bottom: 50px;
  }

  @media only screen and (max-width: 650px) {
  
    h1 {
      margin: 15px 0 0;
    }

    
    div.claim-amount div.item {
      width: auto !important;
      display: flex;
      justify-content: space-between;
    }

    div.claim-details, div.claim-amount {
      display: block;
      padding: 1rem 0;

      div.item {
        margin: 0 30px;
        padding: 5px 0;
        border-bottom: 1px solid var(--color-light-grey);

        p {
          text-align: left !important;
        }
      }

      div.item:last-of-type {
        border-bottom: none;
      }
    }
  }
}
