#insurance-init-wrapper {

  max-width: 1000px;
  overflow-x: hidden;
  margin: auto;
  

  .insurance-info {

    margin-top: 4rem;
    white-space: nowrap;
    

    section {
  
      padding: 3rem 2rem 0;
      white-space: break-spaces;
  
      span {
        margin: auto;
        height: 180px;
        width: 180px;
      }
  
      h1 {
        font-size: 1.5rem;
        margin: 20px 0;
      }
  
      p {
        margin: 30px 0;
      }

      @media only screen and (max-width: 420px) {
        span {
          height: 140px;
          width: 140px;
        }
      }

      @media only screen and (max-height: 620px) {
        span {
          height: 140px;
          width: 140px;
        }
      }
    }

  }


  .carousel{

    margin: 1rem;
    
    span {
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: var(--color-translucent-blue);
      transition: 350ms;
      margin: 0 5px;

      &[is-active] {
        background-color: var(--color-main);
      }
    }
  }

  .insurance-info-button {

    height: 120px;
    left: 0;
    right: 0;
    bottom: 0;

    & > span {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    button {
  
      border-radius: 50%;
      background-color: var(--color-main);
      padding: 0;
      border: none;
      width: 60px;
      height: 60px;
      color: #fff;
      font-size: 1.8rem;
  
      svg {
        width: 30px;
        height: 30px;
      }
  
      &::before {
        content: "";
        border-radius: 50%;
        position: absolute;
        width: 100%;
        height: 100%;
        top: -2px;
        left: -2px;
        border: 2px solid #fff;
      }
    }
  }
  
}

$barColor: #243EC6;
$backColor: #eee;
$step: 1; // step of % for created classes

$loops: calc(100 / $step);
$loopsRounded: round($loops);
$increment: calc(360 / $loopsRounded);
$half: calc($loopsRounded / 2);
$halfRounded: round($half);
@for $i from 0 through $loopsRounded{
    .progress-#{calc($i*$step)}{
        @if $i < $halfRounded{
            $nextdeg: 90deg + ( $increment * $i );
            background-image: linear-gradient(90deg, $backColor 50%, transparent 50%, transparent), linear-gradient($nextdeg, $barColor 50%, $backColor 50%, $backColor);
        }
        @else{
            $nextdeg: -90deg + ( $increment * ( $i - $halfRounded ) );
            background-image: linear-gradient($nextdeg, $barColor 50%, transparent 50%, transparent), linear-gradient(270deg, $barColor 50%, $backColor 50%, $backColor);
        }
    }
}