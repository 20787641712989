/* Functional Component styles */
#insurance-completion-wrapper {
  padding: 2rem;

  h1, h2 {
    font-weight: normal;
    font-size: 1.4rem;
    margin: 10px 0;

		b {
			font-size: 2rem;
		}
  }

	h2 {
		margin: 1rem 0;
	}

  svg {

    width: 60px;
    height: 60px;
    background-color: var(--color-green);
    color: #fff;
    border-radius: 50%;
    margin: 15px 0;
  }

  button {
    bottom: 10px;
    max-width: 300px;
    left: 0;
    right: 0;
    margin: auto;
  }
}