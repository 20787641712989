/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.suggestion {
  position: relative;
  max-width: 500px;
  
  &__list {
    @include theme.shadow;
    @extend %bg-color-white;
    z-index: 99999;
    position: absolute;
    width: 100%;
    top: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-sizing: border-box;

    &__item {
      @extend %border-bottom-color-light-grey;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      padding: 10px;

      &:hover {
        @extend %bg-color-light-grey;
        cursor: pointer;
      }

      &__text {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__item:last-child {
      border: none;
    }
  }

	@include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
		
		.inputWrapper {
			max-width: 350px;
			margin: 0 auto;
		}
	}
}