@use '/src/styles/v2/index.scss' as theme;

.claimRegister {

  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9991;
  width: 100%;
  background-color: #fff;
  padding: 0 !important;
  position: fixed;
  overflow-y: auto;

  &__data-panel {

    margin-top: 30px;

    &__section {

      padding: 10px 15px;
      margin: 25px 0;
      border-radius: 5px;
      position: relative;
      @include theme.shadow;
      @include theme.flexbox-content-vertical-center;

      &__icon {
        margin-right: 20px;
        @include theme.flexbox-content-center;

        &__marker {
          height: 15px;
          width: 15px;
          margin-right: 15px;
          border-radius: 50%;

          &--valid {
            @extend %bg-color-green;
          }

          &--invalid {
            @extend %bg-color-red;
          }
        }

        &__bg {
          @include theme.background-image(50px, 50px);
        }
      }

      &__title {
        @include theme.flex(1);
      }

      &__arrow {

        svg {
          width: 20px;
          height: 20px;
          @extend %text-color-grey;
        }
      }

      &--disabled {
        pointer-events: none;
        opacity: .4;
      }
    }

    &__infoSection {
      @include theme.flexbox;
      padding: 20px 15px;

      &__icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;

        span {
          display: block;
          width: 60px;
          height: 60px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &__button {
        min-width: 100px;
        margin-left: 10px;
      }
    }

    &__separator {
      margin: 40px 0;
      border-top: 2px solid var(--color-light-grey);
    }
  }

  &__button-back {
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 10;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__completion {
    @include theme.flexbox-column-vertical-center;
    justify-content: space-evenly;
    height: 450px;
    margin: 20px;

    &__logo {
      height: 150px;
      margin: 60px;

      img {
        width: 200px;
        height: auto;
      }
    }

    &__title {
      margin: 20px 0;
    }

    &__link {

      margin-top: 50px;

      a {
        @extend %text-color-main;
      }
    }
  }

  &__logo {

    height: 100%;
    @include theme.flexbox-column-content-center;

    img {
      width: 200px;
      height: auto;
    }

    svg {
      margin: 30px;
      width: 40px;
      height: 40px;
    }
  }

  &__button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    @include theme.top-shadow;
    z-index: 100;

    &>button {
      max-width: 275px;
      margin: auto;
      display: block;
    }

    &__info {

      margin-top: 15px;

      button {
        @extend %text-color-main;
        font-weight: bold;
      }
    }
  }
}