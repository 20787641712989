@use '/src/styles/v2/index.scss' as theme;

.logoWrapper {
  padding: 15px;
  @include theme.flexbox;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  &__image {
    width: 130px;
    height: 40px;
    display: block;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    @include theme.respond-to(600px) {
      width: 80px;
      height: 40px;
    }

    &--full {
      width: 200px;
      height: auto;
    }
  }
}