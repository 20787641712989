/* Functional Component styles */
.product-limit-wrapper {
  max-width: 300px;
  text-align: center;
  padding: 1rem;

  h1 {
    font-size: 1.1rem;
    margin: 10px 0;
  }

  p {
    font-size: .9rem;
    margin: 20px 0 30px;
  }

  button {
    max-width: 150px;
  }

  @media only screen and (max-width: 400px) {
    max-width: 250px;
  }
}