/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#rewards-wrapper {
  .current-user-referrals-info {
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 5px;

    & > section {
      border-right: 1px solid #eee;
    }

    section {
      h2 {
        font-size: 0.8rem;
        font-weight: normal;
      }

      p {
        font-size: 1.5rem;
        font-weight: bold;
      }

      span {
        width: 40px;
        height: 40px;
        margin: 5px 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      h3 {
        font-weight: 600;
        font-size: .8rem;
        margin: 5px 0;
        color: var(--color-main);

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    section:last-child {
      border-right: none;
    }

    .fab-link {

      position: fixed;
      bottom: 25px;
      right: 25px;
      display: none;

      a {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        padding: 0;
        display: flex;

        svg {
          margin: 0;
          height: 35px;
          width: 35px;
        }
      }
    }

    @media only screen and (max-width: $mobileWidth) {

      flex-direction: column;
      padding: 1rem 0;

      & > section:last-child {
        display: none;
      }

      & > section:first-child {
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid #eee;
      }

      .fab-link {
        display: inherit;
      }
    }
  }

  .rewards-list {
    margin-top: 1rem;

    h2 {
      font-size: 1.2rem;
      margin: 1rem;
      font-weight: 600;
    }

    ul {

      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 auto;

      li {

        height: 160px;

        .reward-item {
          min-width: 140px;

          .reward-type {
            background-color: var(--color-light-main);
            color: #fff;
            font-size: 0.6rem;
            border-radius: 5px;
            font-weight: bold;
            padding: 0.25rem 0.75rem;
          }

          .reward-points {
            font-size: 0.8rem;
            font-weight: bold;

            span {
              width: 20px;
              height: 20px;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          h3 {
            font-size: 1.1rem;
            line-height: 1.1;
          }

          h4 {
            font-weight: normal;
            font-size: 0.9rem;
          }

          @media only screen and (max-width: 500px) {

            min-width: 110px;
            
            .reward-type {
              font-size: .5rem;
              padding: 0 5px;
            }

            .reward-points {

              font-size: .7rem;

              span {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 740px) {
        display: flex;
        flex-direction: column;
      }

      @media only screen and (max-width: 500px) {

        width: 100%;

        li {
          justify-content: center;
        }
      }
    }

    .empty-rewards-list {

      span {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 100px;
        height: 100px;
      }

      h3 {
        font-weight: normal;
        font-size: 1.3rem;
        margin: 5px 0;

        span {
          font-weight: bold;
        }
      }

      h4 {
        font-weight: normal;
        font-size: 1rem;
        max-width: 450px;
      }
    }
  }
}
