/* Functional Component styles */
.current-user-referrals-info {
  .skeleton-loader {
    width: 100px;
  }

  &.skeleton-referrals {
    height: 120px;

    section div section {
      height: 90px;
      justify-content: space-evenly
    }
  }
}

.rewards-list {
  ul {

    li {
      .skeleton-loader {
        width: 300px;
        height: 120px;
        margin: 15px;
      }
    }
  }
  
}
