/* Functional Component styles */
.status-component-wrapper {

  .status-item {
    
    &.status-label {
      border-radius: 50%;
      margin-right: 5px;
      width: 7px;
      height: 7px;
  
      &[status-type=available] {
        background-color: var(--color-green);
      }
      &[status-type=expired] {
        background-color: #c8c4d6;
      }
      &[status-type=deactive] {
        background-color: var(--color-red);
      }
      &[status-type=active] {
        background-color: var(--color-main);
      }
      &[status-type=toBegin] {
        background-color: var(--color-green);
      }
      &[status-type=pending] {
        background-color: var(--color-yellow);
      }

    }
  }

  .status-background {
    padding: 2px 8px;
    font-size: 0.65rem;
    border-radius: 5px;
    font-weight: 600;
    margin: 0.5px;

    &[status-type=available] {
      color: var(--color-green);
      background-color: #f3ffe5;
    }

    &[status-type=expired] {
      background-color: #c8c4d6;
    }
    &[status-type=deactive] {
      color: var(--color-red);
      background-color: #ffe5e5;
    }
    &[status-type=active] {
      color: var(--color-main);
      background-color: var(--color-translucent-blue);
    }
    &[status-type=toBegin] {
      background-color: #d3fbd8;
      color: var(--color-green);
    }
    &[status-type=pending] {
      background-color: #fff7ca;
      color: var(--color-yellow);
    }

  }
}