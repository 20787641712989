/* Functional Component styles */
#user-email-validation-wrapper {
  height: calc(100vh - var(--header-height));
  background: var(--login-background);
  background-size: var(--login-background-size);

  .spinner {

    width: 50px;
    height: 50px;
  }

  .email-validation-image {

    span {
      width: 120px;
      height: 120px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin: auto;

      &.email-validation-icon {

        width: 60px;
        height: 60px;
        border: 3px solid var(--color-red);
        border-radius: 50%;
        color: var(--color-red);
        font-size: 2.5rem;
      }
    }
  }

  h2 {
    font-size: 1.8rem;
    margin: 1rem;
    font-weight: bold;
    padding: 0 2rem;
  }

  h3 {

    font-size: 1.2rem;
    font-weight: normal;
  }

  a {
    width: 250px;
    margin: 3rem 0;
  }
}
