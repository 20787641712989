@import "/src/styles/v1/variables/screen/screen.scss";

/* Functional Component styles */
.policyPaymentMethod {

  &__section {

    padding: 1rem 2rem;

    &__payment-card {
      min-height: 210px;
      width: 280px;
      margin: auto;

      p {
        left: 0;
        right: 0;
        margin: auto;
        font-size: 1.2rem;
        color: #fff;
        font-weight: 500;
        span {
          color: #fff;
          margin: 0 5px;
        }
      }
      p:nth-of-type(1) {
        bottom: 90px;
        letter-spacing: 3px;
        user-select: none;
      }
      p{
        bottom: 40px;
      }
      .background-image {
        width: 60px;
        height: 60px;
        bottom: 15px;
        right: 20px;
      }
    }

    &--max-width {
      max-width: 450px;
    }

    &__form {
      

      
      &__input {
        &--input-cvv {
          width: 70px;
        }
      }



      &__section {
        margin-top: 30px;

        &--margin-top {
          margin-top: 15px;
        }
        
        &--card {

          .input-label-wrapper:nth-of-type(1) {
            width: 100%;

            input {
              letter-spacing: 3px;
            }
          }
        }
      }

      &__submit {

        margin: 30px 0;
  
        button {
          max-width: 250px;
        }
      }

      h3 {
        font-size: 1rem;
        font-weight: 600;
      }

      p {
        font-size: .8rem;
      }

      .input-label-wrapper, .input-card-wrapper, .dropdown-wrapper {
        margin-right: 10px;
      }

      .dropdown-wrapper {
        flex: 1;
      }
    }

    h2 {
      font-size: 1.2rem;
    }
    
    h3 {
      font-size: 1rem;
    }

  }

  @media only screen and (max-width: $mobileWidth) {
      flex-direction: column;
      align-items: flex-start;

      &__section {

        width: 100%;
        box-sizing: border-box;

        &--max-width {
          max-width: 600px;
        }

        &__payment-card {
          margin-left: auto;
          margin-right: auto;
        }

        &__form {

          &__submit {
            justify-content: flex-start !important;
          }
        }
      }
  }

  // & > section {

  //   padding: 1rem 2rem;

  //   h2 {
  //     font-weight: 600;
  //     font-size: 1.2rem;
  //   }




  //   }    
  // }

}
