@import "/src/styles/v1/variables/screen/screen.scss";

#login-wrapper {

  background: var(--login-background);
  background-size: var(--login-background-size);
  height: calc(100vh - var(--header-height));

  form {

    max-width: var(--login-content-wrapper-max-width);
    padding: var(--login-content-wrapper-padding);  
    margin: auto;
    
    .login-title {


      h1 {
        font-size: 1.8rem;
      }

      span {
        font-size: 1.8rem;
        margin: 0 5px;
        animation-duration: 2s;       
        animation-iteration-count: infinite;  
        transform-origin: 70% 70%;
        -webkit-animation-duration: 2s;       
        -webkit-animation-iteration-count: infinite;  
        -webkit-transform-origin: 70% 70%;

        &:hover {
          cursor: pointer;
          animation-name: wave-animation;
          -webkit-animation-name: wave-animation;
        }

        @keyframes wave-animation {
          0% { transform: rotate( 0.0deg) }
          10% { transform: rotate(14.0deg) }
          20% { transform: rotate(-8.0deg) }
          30% { transform: rotate(14.0deg) }
          40% { transform: rotate(-4.0deg) }
          50% { transform: rotate(10.0deg) }
          60% { transform: rotate( 0.0deg) }
          100% { transform: rotate( 0.0deg) }
        }

        @-webkit-keyframes wave-animation {
          0% { transform: rotate( 0.0deg) }
          10% { transform: rotate(14.0deg) }
          20% { transform: rotate(-8.0deg) }
          30% { transform: rotate(14.0deg) }
          40% { transform: rotate(-4.0deg) }
          50% { transform: rotate(10.0deg) }
          60% { transform: rotate( 0.0deg) }
          100% { transform: rotate( 0.0deg) }
        }
      }
    }

    h2 {
      font-size: 1.2rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem
    }

    ul {
      margin: 15px auto;
      max-width: 320px;
      font-size: .9rem;
      padding-left: 30px;

      li {
        list-style-type: disc;
        margin-top: 4px;
      }
    }

    input {
      letter-spacing: 1px;
      font-weight: bold;
    }

    .form-section {
      margin-top: 35px;
    }
    
    .button-wrapper{
      margin: 40px 0 20px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    form {
      margin: var(--login-content-wrapper-margin);
    }
  }
}