/* Functional Component styles */
#rewards-history-wrapper {

  & > a {
    padding: 1rem;
  }

  section {
    &:last-child {
      height: calc(100% - 8.3rem - 15px);
    }
  }

  .rewards-history-list {
    height: 100%;

    h1 {
      font-size: 1.3rem;
      margin: 15px;
    }

    h2 {
      font-size: 1.2rem;
    }
  }

  .table {
    margin-top: 4rem;
    padding: 1rem;

    .table-header {
      border-bottom: 1px solid #e2e8f0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: auto;
      padding-bottom: 1rem;
      width: 100%;
    }

    .table-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1rem 0;
      width: 100%;
    }

    .table-row-loading {
      .skeleton-loader {
        margin: 0px !important;
      }
    }
  }

  #reward-history-empty {
    img {
      height: 150px;
      width: 150px;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 400;
    }

    h3 {
      font-weight: 400;
    }
  }

  @media only screen and (max-width: 640px) {
    .rewards-history-list {
      h1 {
        padding-top: 4rem;
      }

      h2 {
        font-size: 1.5rem;
      }
    }

    .table {
      margin-top: 2rem;

      h4 {
        font-size: .9rem;
      }

      p {
        font-size: .9rem;
      }
    }
  }
}
