/* Functional Component styles */
.claim-payment-sidebar-section {
	height: 50px;
	display: flex;
	align-items: center;
	border-left: 3px solid #fff;
	margin: 0;
	position: relative;

  a {
    font-size: 1rem;
  }

	&.active {
		border-color: var(--color-main);
		background: linear-gradient(90deg, rgba(204,222,255,0.25) 0%, rgba(255,255,255,1) 55%);
	}
}