@import "/src/styles/v1/variables/screen/screen.scss";

/* Functional Component styles */
div#identity-validation-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > div.identity-image {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		width: 150px;
		height: 150px;
	}

	h1, h3, p {
		text-align: center;
	}

	& > h1 {
		margin-bottom: 15px;
	}

	& > p.update-ine-paragraph {
		margin: 30px 0 10px;
		font-size: .85rem;
	}

	& > p.missing-ine-paragraph {
		margin: 20px 0 0;

    a { 
      margin: 0 5px;
    }
	}

	& > p {

		a {
			color: var(--color-main);
			font-weight: bold;
			margin-left: 5px;
		}

		span.link {
			color: var(--color-main);
			font-weight: bold;
			cursor: pointer;

			&.disabled {
				pointer-events: none;
				color: var(--color-light-main);
			}
		}

		&.success-p {
			margin-bottom: 50px;
		}
	} 

	& > button {
		width: 200px;
	}

	& > svg {
		&.clock, &.check, &.error {
			height: 80px;
			width: 80px;
			color: var(--color-main);
			margin: 30px 0;
		};

		&.check, &.error {
			background-color: var(--color-green);
			border-radius: 50%;
			color: #fff;
			margin: 30px 0 70px;
		}

		&.error {
			background-color: var(--color-red);
			margin: 30px 0 10px;
		}
	}

	// @media only screen and (max-width: $mobileWidth) {
	// 	justify-content: flex-start;

	// 	& > h1 {
	// 		margin-top: 25px;
	// 	}

	// 	// & > a.back-button {
	// 	// 	top: -20px;
	// 	// 	left: 0px;
	// 	// }
	// }
}