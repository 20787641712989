@use '/src/styles/v2/index.scss' as theme;

.eSignCanvas {
  width: 100%;
  height: 100%;
  position: relative;
  @extend %bg-color-white;

  &__clear-button {
    position: absolute;
    right: 5px;
    top: -2px;
    z-index: 1;

    svg {
      width: 22px;
      height: 22px;
    }

    &:disabled {
      opacity: .3;
    }
  }

  &__content {

    &__sign-area {
      background-color: #fff;
      width: 100%;
      position: relative;
      @extend %bg-color-white;
      @include theme.flexbox-content-horizontal-center;

      #e-sign, #landscape-e-sign {
        cursor: crosshair;
        z-index: 2;
        margin: 50px 0 10px;
        border-top-style: solid;
        border-top-width: 1px;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        @extend %border-color-light-grey;
      }

      #e-sign {
        width: 373px;
        height: 150px;
      }

      #landscape-e-sign {
        width: 90% !important;
        height: 150px;
        max-width: 600px !important;
      }
    }

    &__actions {
      @include theme.flexbox;
      @include theme.justify-content(space-evenly);
      margin: 50px 0 10px;

      button {
        width: 150px;
        padding: 10px;
      }
    }
  }

  &__mobile-warning {

    height: 300px;
    @include theme.flexbox-column-content-center;
    @extend %bg-color-white;
    display: none;

    svg {
      width: 35px;
      height: 35px;
      @extend %text-color-main;
    }
  }


  // @media only screen and (max-width: 550px) {
  //   &__content {
  //     display: none;
  //   }

  //   &__mobile-warning{
  //     display: flex;
  //   }
  // }

  // @media only screen and (orientation: landscape) {
  //   &__mobile-warning {
  //     display: none;
  //   }

  //   &__content {
  //     display: inherit;
  //   }
  // }
}