@import "/src/styles/v1/variables/screen/screen.scss";

div.plan-payment {

	.back-button {
		text-align: left;
    padding-left: 1rem;
	}

	& > section {
		padding: 1rem 4rem 0rem;

		h1.section-title {

			@media only screen and (max-width: 400px) {
				font-size: 1.2rem;
			}
		}

		h3.section-subtitle, h3.salvage-message {
			font-weight: 300;
			font-size: .9rem;

			@media only screen and (max-width: 600px) {
				margin-bottom: 1rem;
			}

			@media only screen and (max-width: 400px) {
				font-size: .8rem;
			}
		}

		h3.salvage-message {
			text-align: center;
			
			@media only screen and (max-width: $mobileWidth) {
				margin: 0 0 10rem 0;
			}
		}

		div.payments-wrapper {
			box-shadow: 0 0 5px var(--color-light-grey);
			border-radius: 8px;
			margin: 0 2rem 1rem 0;

			& > section {
				border-top: 1px solid var(--color-light-grey);

				&:first-child {
					border-top: none;
				}

				div.credit, div.debit {
					padding: .5rem 2rem;
					border-radius: 8px;
					cursor: pointer;
					align-items: center;
					
					p {
						padding-left: .5rem;
						font-weight: 700;
					}

					& > span {
						height: 50px;
						width: 50px;
						margin: 0 0 0 auto;
					}

					@media only screen and (max-width: 400px) {
						padding: .5rem 1rem;
					}
				}

				div.credit-content, div.debit-content {
					padding: 2rem;

					div.input-label-wrapper {
						max-width: 500px;
					}

					div.payment-iframe-wrapper {
						max-width: 503px;
					}

					& > p {
						font-weight: 600;
						margin: 0 0 2rem;
					}

					div.payment-iframe-wrapper {
						margin: 2rem 0;
					}

					@media only screen and (max-width: 600px) {
						padding: 2rem 1rem;
					}

					@media only screen and (max-width: 400px) {
						padding: 1.5rem .5rem;

						& > p {
							margin: 0 0 1rem;
						}
					}
				}

				&.active {
					border-top: none;

					div.credit, div.debit {
						background-color: var(--color-main);
						
						p {
							color: #fff;
						}
					}
				}	
			}

			@media only screen and (max-width: $mobileWidth) {
				margin: 0 0 1rem;
			}

			@media only screen and (max-width: 600px) {
				margin: 0 0 2rem;
			}
		}

		&[is-expanded] {

			div {

				&:last-of-type {
					overflow: visible !important;
				}
			}
		}

		&.content {
			align-items: flex-start;
		}

		&.button-section {
			padding: 0;
			margin-top: auto;

			div.mobile-price-details {
				display: none;
			}

			div.button-wrapper {
				height: 100%;
			}

			&.quoter {

				div.button-wrapper {
					justify-content: center;

					button {
						max-width: 250px;
						margin: 0 2rem;
					}
				}
			}

			button {
				width: 100%;
				max-width: 250px;
			}

			@media only screen and (max-width: 1100px) {
				justify-content: center;
				flex-direction: column;
				display: flex;
			}

			@media only screen and (max-width: $mobileWidth) {
				height: 240px;
				max-height: 240px;
				display: flex;
				flex-direction: column;
				box-shadow: 0 0 0.5rem -0.3rem;
				border-top-left-radius: 30px;
				border-top-right-radius: 30px;

				div.mobile-price-details {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: .5rem;
					height: 80px;
    			align-items: center;

					p.details {
						cursor: pointer;
						color: var(--color-main);
						margin: 5px 0 10px;
					}

					div.subscription {

						div {
							margin: 0 1rem;
						}
					}

					p {
						font-size: .9rem;
						line-height: 1;
					}

					h2, h3 {
						color: var(--color-main);
					}
					
					h2 {
						font-size: 1.5rem;
					}

					h3 {
						font-size: 1.2rem;
						line-height: 1.2;
					}
				}

				div.button-wrapper {
					width: 100%;
					height: auto;
					margin: auto 0 1rem;
				}

				form {
					max-width: 400px;
    			margin: 0 auto .5rem;
				}
			}

			@media only screen and (min-width: 1031px) {
				display: none;
			}
		}

		@media only screen and (max-width: 1270px) {
			padding: 1rem 2rem 0;
		}

		@media only screen and (max-width: $mobileWidth) {
			padding: 1rem 1rem 0;
		}

		@media only screen and (max-width: 600px) {
			padding: 0 0;
		}
	}

	aside {
		width: 300px;
		min-width: 300px;
		padding: 2rem 1rem 1rem;
		margin: 0 0.5rem;
		position: sticky;
		top: calc(var(--header-height) + 1rem);
		border-radius: 10px;
		box-shadow: 0 0 5px var(--color-light-grey);

		&.quoter {
			top: calc(var(--header-height) + 6rem);
		}

		.split-button {
			margin: 15px auto 0;
			display: flex;
			align-items: center;

			p {
				color: var(--color-main);
				font-size: .9rem;
			}

			svg { 
				font-size: 2rem;
				color: var(--color-main);
				margin: -3px 0 0 3px;
				transition: all 100ms;
			}

			&[is-expanded] {

				svg {
					transform: rotate(180deg);
				}
			}
		}

		section {

			&.amounts {

				div.monthly-payments {

					p {
						margin: 0 0 .5rem;

						&.total {
							margin: .5rem 0 0;
						}
					}
				}

				p {
					font-size: .9rem;
					margin: 0 0 1rem;

					&.title {
						font-weight: 600;
						margin: 0;
					}

					&.small {
						font-size: .75rem;
					}

					&.months-error {
						color: var(--color-red);
						font-weight: 600;
					}
				}

				h3 {
					font-size: 1.7rem;
					color: var(--color-main);
					line-height: 1.8rem;
				}

			}

			&.summary {
				margin-top: 0;
				padding-top: 1rem;

				.container {
					border-top: 2px solid var(--color-light-grey);
				}

				h2 {
					margin: .5rem 0;
					font-size: 1rem;

					&.second-title {
						margin-top: 20px;
					}
				}

				ul {

					li {
						font-size: .9rem;
						margin: .4rem 0;
						display: flex;
						justify-content: space-between;

						&.monthly-item {

							div {
								justify-content: space-between;
							}

							p {
								color: var(--color-main);
								margin-top: 1rem;
								font-size: .75rem;
							}
						}

						&.total {
							font-weight: bold;
						}
					}

					p.reimbursementText {
						font-size: .9rem;
						margin: 5px 0 10px;
					}
				}
			}

			div.bottom-section {
				padding-top: 1.5rem;
				border-top: 1px solid var(--color-light-grey);

				form {
					text-align: left;
					max-width: 270px;
					margin: 0 auto;
					
					div {
						font-size: .8rem;
					}
				}

				button {
					margin: 1.5rem 0 0;
					max-width: 220px;
				}

				@media only screen and (max-width: $mobileWidth) {
					display: none;
				}
			}
		}

		@media only screen and (max-width: $mobileWidth) {
			display: none;
		}
	}

	.pictures-prompt-wrapper, .renewal-modal {
    padding: 2rem;
    max-width: 400px;
    width: 70vw;

    .background-image {
      width: 80px;
      height: 80px;
      margin: 0 auto 1rem;
    }

    h2 {
      font-weight: 500;
      font-size: 1.4rem;
    }

    p {

      padding: 1.5rem 0;

      span {
        color: var(--color-red);
        font-weight: bold;
      }
    }

    button {
      max-width: 250px;
      margin: 2rem auto 0;
    }

    @media only screen and (max-width: 650px) {
      padding: 2rem .75rem;
      max-width: 350px;
      width: 100%;

      .background-image {
        width: 65px;
        height: 65px;
      }
      
      h2 {
        font-size: 1.1rem;
      }

      p {
        padding: .75rem 0;
      }
    }
  }

	div.renewal-modal {

		form {
			max-width: 350px;
    	margin: 1.5rem auto;

			.radio-wrapper {
				margin: .5rem 0;
			}
		}

		button {
			min-width: 200px;
			margin: 2rem auto 1rem;
		}
	}

	div.modal-details-wrapper {
		padding: 1rem 2rem;

		svg {
			position: absolute;
			top: 1rem;
			right: 1rem;
			font-size: 1.5rem;
			color: var(--color-grey);
			cursor: pointer;
		}

		section {
			padding: 0 0 1rem;

			&.amounts {

				h3 {
					font-size: 1.5rem;
					color: var(--color-main);
					line-height: 1.2;
				}

				p {
					
					&.small, &.subscription {
						margin: 0 0 .5rem;
					}

					&.small {
						font-size: .8rem;
					}
				}
					
				
				border-bottom: 1px solid #eee;
			}

			&.summary {
				padding: 1rem 0 0;

				h2 {
					font-size: 1rem;
				}

				ul {

					li {
						font-size: .9rem;
						margin: .4rem 0;
						display: flex;
						justify-content: space-between;

						&.monthly-item {

							div {
								justify-content: space-between;
							}

							p {
								color: var(--color-main);
								font-size: .75rem;
								margin-top: 1rem;
								word-wrap: break-word;
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: 550px) {
			max-width: 75vw;
		}
	}
}