/* Functional Component styles */
.otp-wrapper {

	&.phone-mode {
		padding: 0 80px;

		@media only screen and (max-width: 550px) {
			padding: 0;
		}
	}

  section {

    &.flex {
      margin: 0 auto;
      margin-top: 40px;
      padding: 1rem 0;
      max-width: 500px;
    }

    &[verifying-code] {

      #otp-code {
        pointer-events: none;
        opacity: 0.15;
      }

      svg {
        color: var(--color-main);
        margin-bottom: 0;
        width: 3rem;
        height: 3rem;
      }
    }

		div.phone-sms-image {
			height: 100px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			margin: 30px;
		}
		
		div.button-wrapper {
			display: flex;
			
			button {
				margin: 20px auto;
				max-width: 180px;
			}
		}
    
    h1 {
      font-size: 1.4rem;
    }
  
    h2 {
      font-size: 1.2rem;
      font-weight: normal;
      margin: 20px 0;
    }

		p {
			margin: 0;

			&.resend-text {
				font-size: .9rem;
				cursor: pointer;

				button {
					font-weight: bold;
					color: var(--color-main);
					border: none;
					background-color: transparent;
					font-size: .9rem;
          margin: 0 5px;
				}
			}
		}
  
    #otp-code {
  
      margin: 40px ;
  
      input {
        pointer-events: none;
        border: none;
        border-bottom: 2px solid #000;
        width: 20px;
        margin: 0 10px;
        text-align: center;
        font-size: 1.3rem;
        text-transform: uppercase;
  
        &[name=otpc-4] {
          margin-left: 30px;
        }
      }
      
      &:hover {
        cursor: text;
      }
    }

    .code-sender-wrapper {

      padding: 1rem 2rem;
      border-top: var(--dashboard-border-style);

      .sender-icon {
        width: 50px;
        height: 50px;
        font-size: 2.5rem;
      }

      .sender-name {
        margin-left: 10px;

        h3 {
          font-size: 1rem;
          font-weight: 500;
        }

        h4{
          font-size: .9rem;
          font-weight: normal;
        }
      }

      button {
        width: 100px;

        &:disabled {
          opacity: .5;
        }
      }

      @media only screen and (max-width: 420px) {

        padding: 1rem 0;

        button {
          margin-left: 10px;
        }
      }

    }

    .code-sender-wrapper:first-child{
      border-top: none;
    }
  }
}
