/* Functional Component styles */
.validation-error {

  background-color: #fff;
  padding: 2rem;
  transition: 100ms;
  animation: 250ms ease-out pictureError;
  animation-fill-mode:forwards;
  -webkit-animation: 250ms ease-out pictureError;
  -webkit-animation-fill-mode: forwards;


  @keyframes pictureError {
    0% { transform: translateY(5%); opacity: .5; }
    100% { transform: translateY(0); opacity: 1; }    
  }

  h1 {
    margin-top: 10px;
    font-size: 1.7rem;
  }

  p {
    margin: 10px 0 20px;
  }

  button {
    bottom: 40px;
  }

  span {
    height: 130px;
    width: 130px;
  }
}