/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.input-label-wrapper {

  border-style: solid;
  border-width: var(--form-input-border-width);
  border-color: var(--form-input-border-color);
  transition: 150ms;
  margin: var(--form-input-wrapper-margin);

  &, input {
    border-radius: var(--form-input-border-radius);
  }

  &.invalid {
    border-color: var(--color-red);
    .icon-wrapper, label {
      color: var(--color-red);
    }
  }

  &.input-disabled {
    opacity: 0.5;
    input {
      cursor: not-allowed;
    }
  }

  label {
    top: -0.7rem;
    z-index: 1;
    padding: 0 10px;
    left: 0.5rem;

    @media only screen and (max-width: $mobileWidth) {
      font-size: .85rem;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media only screen and (max-width: 585px) {
      max-width: 150px;
    }

    &::after{
      content: "";
      height: 0.3rem;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff;
      z-index: -1;
    }

  }

  .icon-wrapper{
    width: 2rem;
    color: var(--color-light-main);

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  input {

    border: none;
    outline: none;
    display: block;
    padding: var(--form-input-padding);
    font-size: 0.9rem;
    letter-spacing: 0.4px;

    &::placeholder { 
      opacity: .5;
    }

    &.uppercase {
      text-transform: uppercase;
    }


    &.with-icon {
      padding-left: 2.5rem;
    }

    &[type=password] {
      padding-right: 40px;
    }
  }

  .input-loader {
    width: 20px;
    height: 20px;
    right: 10px;
    color: var(--color-main);

    svg {
      width: 20px;
      height: 20px;
    }
  }

  & > button {
    right: 5px;
    height: 100%;
    width: 40px;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
    color: var(--color-grey);
    user-select: none;

    &[is-visible] {
      color: var(--color-main);
    }
    
  }

  &:not(.invalid):focus-within {
    border-color: var(--color-light-main);

    label {
      color: var(--color-main);
    }
  }
}