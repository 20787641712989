/* Functional Component styles */
.c-button {

  --button-border: 2px solid var(--color-main);
  border: none;
  border-radius: 8px;
  padding: 0.6rem 1rem;
  font-weight: 700;
  font-size: 0.9rem;
  transition: 150ms;
  box-shadow: 1px 1px 5px #858585;
  letter-spacing: 1px;
  box-sizing: border-box;

  &[is-loading] {
    pointer-events: none;
  }

  &--noShadow {
    box-shadow: none !important;
  }

  span {

    color: #fff;

    &.hide{
      visibility: hidden;
    }

    .button-icon svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    .button-icon.left svg {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  &[fill=fill] {
    background-color: var(--color-main);
    border: var(--button-border);
    color: #fff;

    &:hover:enabled, &.button-link:hover {

      background-color: var(--color-light-main);
      border-color: var(--color-light-main);
      box-shadow: 1px 2px 5px var(--color-darken-main);
    }

    span {
      color: #fff;
    }
    
    &[disabled] {
      
      background-color: var(--color-light-grey);
      border-color: var(--color-light-grey);
      box-shadow: none;
    }
  }

  &[fill=outline] {
    color: var(--color-main);
    background-color: #fff;
    border: var( --button-border);

    &:hover:enabled, &.button-link:hover {
      background-color: var(--color-light-main);
      border-color: var(--color-light-main);
      box-shadow: 1px 2px 5px var(--color-darken-main);

      span {
        color:#fff;
      }
    }

    span {
      color: var(--color-main);
    }

    
    &[disabled] {
      opacity: 0.3;
      box-shadow: none;
    }
  }

  &[fill=none] {
    color: var(--color-main);
    border: none;
    background-color: transparent;
    box-shadow: none;

    &:hover:enabled, &.button-link:hover {
      text-decoration: underline;
    }

    span {
      color: var(--color-main);
    }

    &[disabled] {
      opacity: 0.3;
    }
  }
    

  &:not([disabled]).color-red {

    &[fill=fill] {
      background-color: var(--color-red);
      border-color: var(--color-red);

      &:hover:enabled, &.button-link:hover {

        background-color: #fd6778;
        border-color: #fd6778;
        box-shadow: 1px 2px 5px #b82c3d;
        
      }
    }

    &[fill=outline] {
      color: var(--color-red);
      background-color: #fff;
      border-color: var(--color-red);
  
      &:hover:enabled, &.button-link:hover {
        background-color: #fd6778;
        border-color: #fd6778;
        box-shadow: 1px 2px 5px #b82c3d;
  
        span {
          color:#fff;
        }
      }
  
      span {
        color: var(--color-red);
      }
    }

    &[fill=none] {
      color: var(--color-red);
  
      &:hover:enabled, &.button-link:hover {
        text-decoration: underline;
      }
  
      span {
        color: var(--color-red);
      }
    }
  }
}