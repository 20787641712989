/* Functional Component styles */
.coverageOption {

  display: inline-flex;
  transition: 150ms;
  margin-top: .5rem;
  position: relative;

  &:hover {
    cursor: pointer;

    &__selected {
      filter: brightness(.95);
    }
  }

  &__selected {
    background-color: var(--color-light-grey);
    border-radius: 1.2rem;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    transition: 150ms;

    p {
      margin-right: .5rem;
      font-size: .9rem;
      font-weight: 500;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &[disabled-options] {
      opacity: .3;
      pointer-events: none;
    }
  }

  &__selected--open {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &__list {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    width: 100%;
    left: 0;
    top: 98%;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    box-shadow: 0px 2px 3px #ccc;

    &__item {
      margin: 1rem;

      p{
        font-size: .9rem;
      }

      p:hover {
        text-decoration: underline;
      }
    }
  }
}