/* Functional Component styles */
.policyPaymentHistory{

  &__info {

    padding: 1rem 0;
    border-bottom: 1px solid var(--color-light-grey);
    
    p {
      text-align: center;
      font-size: 1.1rem;
    }
  }

  &__list {

    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;

    &__item {

      &__counter {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 2rem;
        background-color: var(--color-main);
        color: #fff;
        border-radius: 50%;
        font-size: .8rem;

        &::after, &::before{
          content: "";
          position: absolute;
          height: 280%;
          width: 2px;
          background-color: var(--color-light-grey);
        }

        &::before{
          top: -280%;
        }
        &::after{
          top: 100%
        }

        &.first::before, &.last::after {
          display: none;
        }
      }

      &__content {

        padding: 1rem;
        margin: 1.5rem 0;
        border-radius: 0.5rem;
        box-shadow: 1px 1px 4px #d1d1d1;
        display: flex;
        justify-content: space-between;
        max-width: 600px;
  
        &__section {
  
          &--amount {
            align-items: flex-end;
          }
          
          &__status {
            margin-bottom: .5rem;

            &__value {
              width: 80px;
              text-align: center;
              padding: 0.2rem;
              color: #fff;
              border-radius: 20px;
              font-size: .7rem;
              margin-right: 1rem;
              font-weight: bold;

              &--paid{
                background-color: var(--color-green);
              }
  
              &--pending{
                background-color: var(--color-yellow);
              }
  
              &--error{
                background-color: var(--color-red);
              }
            }

            h2 {
              font-weight: 600;
              font-size: 1.2rem;
            }
          }

          & > p {
            font-weight: 500;
            font-size: .9rem;
          }

          button {
            margin: .35rem;
  
            svg {
              width: 1.75rem;
              height: 1.75rem;
              color: var(--color-main);
              transition: 150ms;
            }

            &:hover svg {
              color: var(--color-darken-main);
            }
          }
        }
      }

    }
  }
}