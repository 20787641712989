/* Functional Component styles */
label {
  display: block;
  font-weight: 600;
  font-size: 0.9rem;
  color: #515458;
  transition: color 150ms;

  span {
    margin-left: 5px;
    color: var(--color-red);
  }
}