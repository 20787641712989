/* Functional Component styles */

.bc-wrapper{
  padding: 1rem 1.2rem;

  h1 {
    font-size: 1.4rem;
    margin-bottom: .25rem;
  }

  a {
    span {
      color: var(--color-main);
    }
      
  }

  a, p {
    span {
      font-size: .85rem;
      font-weight: 500;

    }

    svg {
      font-size: .7rem;
      margin-right: 3.5px;
    }

    & span {
      margin-right: 5px;
    }
  }

  p {
    span {
      font-weight: bold;
    }
  }

  a:hover {
    span {
      text-decoration: underline;
    }
  }
  
}