.spinner-wrapper {
	margin: auto auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  height: 2rem;
  width: 2rem;

  &[spinner-color=main] {
    color: var(--color-main);
  }

  &[spinner-color=white] {
    color: #fff;
  }

  &[spinner-color=black] {
    color: #000;
  }


}
.spinner-dots-wrapper {

  margin: auto auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

  p {
    font-size: .9rem;
    font-weight: bold;
    text-transform: uppercase;
    font-style: italic;

    span {

      opacity: 1;
      animation: loaderDots 1s infinite ease;
      margin-left: 2px;

      @keyframes loaderDots {
        
        from{
          opacity: 1;
        }
        to {
          opacity: 0.2;
        }
      }
    }

    span:nth-child(2) {
      animation-delay: 0.25s;
    }
    span:nth-child(3) {
      animation-delay: .5s;
    }
  }

  .circles {
    width: 100px;
    height: 80px;
  }

  .circle {

    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform-origin: 50%;
    animation: loaderCircle .5s alternate infinite ease;
    top: 30px;
  }

  .circle:nth-child(1) {
    background-color: var(--color-light-main);
  }

  .circle:nth-child(2) {
    left: 35px;
    animation-delay: 0.15s;
    background: var(--color-light-main);
    background: linear-gradient(90deg, var(--color-light-main) 0%, var(--color-main) 100%);

  }

  .circle:nth-child(3) {
    background-color: var(--color-main);
    left: 70px;
    animation-delay: 0.3s;
  }

  @keyframes loaderCircle {
    0% {
      top: 30px;
      border-radius: 50px, 50px 35px 35px;
      transform: scaleX(1.2);
    }

    40% {
      transform: scale(1);
    }

    100% {
      top: 0%;
    }
  }
}