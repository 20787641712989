/* Functional Component styles */
.form-error-wrapper {
  color: var(--color-red);

  svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  p {
    font-size: 0.8rem;
  }
}