/* Functional Component styles */
#vehicle-management-wrapper {

  .info-wrapper {

    margin: 20px;

    svg {
      height: 20px;
      width: 20px;
      margin: 5px 0;
      color: var(--color-main);
    }

    p {
      margin: 0 10px;
      font-size: .8rem;
    }
  }

  form {

    .sections-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;

      & > div {
        margin: var(--policy-form-item-margin);
      }
    }

    .form-section {
      margin: var(--policy-form-section-margin);
    }
  }

  @media only screen and (max-width: 600px) {

    .info-wrapper {
      margin: 0 10px;

      p {
        flex: 1;
      }
    }
      
    form {
      .sections-wrapper {
        grid-template-columns: 1fr;

        & > div {
          margin: 10px 0;
        }
      }
    }
  }
}