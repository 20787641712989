/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policy-endorsements-wrapper {

  height: calc(100vh - var(--header-height));

  .endorsements-content {

    border-radius: var(--dashboard-border-radius);
    border: var(--dashboard-border-style);
    margin: 20px;
    padding: 1rem 0 0; 
  }

  @media only screen and (max-width: $mobileWidth) {
    flex-direction: column; 
  }

  .buttons-wrapper {

    border-top: var(--dashboard-border-style);
    border-radius: var(--dashboard-border-radius);
    box-shadow: var(--dashboard-box-shadow);
    background-color: #fff;
    position: sticky;
    bottom: 0;
    padding: 1rem;

    button {
      width: 250px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    padding-bottom: 75px;

    .endorsements-content {

      .back-button{

        position: fixed;
        z-index: 99;
        top: calc(var(--header-height) + 10px);
        left: 0;
      }
    }

    .buttons-wrapper {
      position: fixed;
      width: 100%;
      left: 0;
    }
  }
}