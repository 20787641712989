@use '/src/styles/v2/index.scss' as theme;

.bill {

  $zIndexOverlay: 9997;
  $zIndexContent: 9998;
  $zIndexLoader: 9999;

  &__content {

    height: calc(100vh - var(--header-height));

    &--flex {
      @include theme.flexbox-content-center;
    }

    &__overlay {
      background-color: #000;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      animation-duration: 250ms;
      animation-fill-mode: forwards;

      &--in {
        animation-name: overlay-in;
        z-index: $zIndexOverlay;
      }

      &--out {
        animation-name: overlay-out;
        z-index: $zIndexOverlay;
      }

      @keyframes overlay-in {
        from {
          opacity: 0;
        }

        to {
          opacity: .15;
        }
      }

      @keyframes overlay-out {
        from {
          opacity: .15;
        }

        to {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &__invoice {

      height: 100%;
      box-sizing: border-box;
      @include theme.flexbox-column;

      &__title {
        margin-top: 10px;
        padding: 0 20px;
      }

      &__content {

        margin-top: 15px;
        @include theme.flexbox;

        &__summary {
          padding: 0 20px;
          @include theme.flex(1);

          &__list {
            padding: 25px 35px;
            border-radius: 15px;
            border-width: 1px;
            border-style: solid;
            @extend %border-color-light-grey;
            @include theme.flexbox-column;

            &__row {

              display: grid;
              grid-template-columns: 1fr 100px 100px;
              grid-auto-rows: minmax(min-content, max-content);
              border-bottom-width: 1px;
              border-bottom-style: solid;
              padding: 5px;
              margin: 15px 0;
              @extend %border-bottom-color-light-grey;

              &--header {
                margin-top: 20px;
                margin-bottom: 5px;
                padding: 0 5px;
              }

              &__description {

                margin-right: 10px;

                &__name {
                  line-height: 1;
                }
              }

              &--last {
                border: none;
              }
            }

            &__price {
              margin-top: 20px;
              border-width: 1px;
              border-style: solid;
              border-radius: 20px;
              padding: 15px;
              width: 250px;
              @extend %border-color-light-grey;
              @include theme.flexbox-column;
              align-self: flex-end;

              &__item {
                @include theme.flexbox-content-between;
              }

              &__item:last-of-type {
                margin-top: 10px;
              }
            }
          }
        }

        &__data {
          min-width: 350px;
          max-width: 500px;
          width: 100%;
          border-radius: 15px;
          border-width: 1px;
          border-style: solid;
          margin-left: 10px;
          margin-right: 20px;
          padding: 20px 10px;
          box-sizing: border-box;
          @include theme.flexbox-column-content-center;
          @include theme.align-self(flex-start);
          @extend %border-color-light-grey;

          &__icon {
            background-color: #FFF8E7;
            padding: 10px;
            border-radius: 12px;
            margin-bottom: 15px;

            svg {
              @extend %text-color-yellow;
            }
          }

          &__message {

            &__button {

              margin-top: 30px;
            }
          }

        }

        @include theme.respond-to(1100px) {

          @include theme.flex-direction(column-reverse);
          @include theme.align-items(center);

          padding: 0 20px 30px;
          box-sizing: border-box;

          &__data {
            @include theme.align-self(inherit);
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 20px;
            min-width: inherit;
          }

          &__summary {
            padding: 0;
            
            &__list {
              border: none;
              padding: 0;
            }
          }
        }
      }

      &__form {
        z-index: $zIndexContent;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        animation-duration: 250ms;
        animation-fill-mode: forwards;
        transform: translateX(100%);

        &--in {
          animation-name: form-horizontal-in;
        }

        &--out {
          animation-name: form-horizontal-out;
        }

        @keyframes form-horizontal-in {
          0% {
            transform: translateX(100%);
            opacity: 0.2;
          }
      
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }

        @keyframes form-horizontal-out {
          0% {
            transform: translateX(0);
            opacity: 1;
          }
      
          100% {
            transform: translateX(100%);
            opacity: 0.2;
          }
        }
      }

      &__loader-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $zIndexLoader;
        width: 100%;
        height: 100%;
        background-color: #00000030;
        @include theme.flexbox-content-center;

        &__loader {
          border-radius: 10px;
          background-color: #fff;
          padding: 20px;
          @include theme.flexbox-column-content-center;
        }
      }
    }

    &__prompt {
      margin: auto;
      border-radius: 14px;
      padding: 40px;
      max-height: 600px;
      width: 600px;
      overflow-y: auto;
      border-width: 1px;
      border-style: solid;
      position: relative;
      @extend %bg-color-white;
      @extend %border-color-light-grey;

      &--center {
        @include theme.flexbox-column-content-center;
        width: 500px;
      }

      &--animation {
        animation-name: prompt-in;
        animation-duration: 250ms;
        animation-fill-mode: forwards;
      }

      @keyframes prompt-in {

        0% {
          opacity: .1;
          transform: translateY(25%);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &__icon-title {
        margin-bottom: 15px;

        svg {
          @extend %text-color-main;
        }
      }

      &__space-bottom {
        margin-bottom: 25px;
      }

      &__space-top {
        margin-top: 25px;
      }

      &__item {
        border-radius: 20px;
        border-width: 1px;
        border-style: solid;
        padding: 15px 20px;
        margin-top: 15px;
        margin-bottom: 15px;
        @extend %bg-color-white;
        @extend %border-color-light-grey;
        @include theme.flexbox-content-vertical-center;

        &__icon {
          @include theme.flexbox-content-center;
          $iconWrapper: &;

          position: relative;
          padding: 8px;

          &__background {
            opacity: .1;
            position: absolute;
            border-radius: 10px;
            height: 100%;
            width: 100%;
          }

          &--main {

            #{$iconWrapper} {

              &__background {
                @extend %bg-color-main;
              }
            }

            svg {
              @extend %text-color-main;
            }
          }

          &--green {
            #{$iconWrapper} {

              &__background {
                @extend %bg-color-green;
              }
            }

            svg {
              @extend %text-color-green;
            }
          }
        }

        &__name {
          margin: 0 15px;
          flex: 1;
        }

        &__files {

          a {
            padding: 10px 12px;
            border-radius: 8px;
            margin: 0 8px;
            border-width: 1px;
            border-style: solid;
            @extend %bg-color-white;
            @extend %border-color-light-grey;
            transition: 150ms;

            svg {
              @extend %text-color-main;
              margin-right: 5px;
            }

            &:hover {
              cursor: pointer;
              background-color: darken(#fff, 5%);
            }
          }
        }

        &__download {

          display: none;

          button {

            svg {
              @extend %text-color-main;
            }
          }
        }
      }

      &__link {

        margin-top: 30px;
        width: 100%;

        a {
          @extend %text-color-main;
          font-weight: bold;
        }
      }

      @include theme.respond-to(600px) {
        border: none;
        align-self: flex-start;
        padding: 30px 20px;
        height: 100%;
        width: 100%;
        max-height: inherit;
        box-sizing: border-box;

        &--center {
          margin-top: 60px;
          @include theme.justify-content(flex-start);
        }

        &__icon-title {
          @include theme.flexbox-content-center;

          svg {
            width: 40px;
            height: 40px;
          }
        }

        & > h1, & > p {
          text-align: center;
        }

        &__item {

          padding: 10px 20px;

          &__files {
            display: none;
          }

          &__download {
            display: block;
          }
        }
      }
    }

    &__bottom-sheet {
      position: absolute;
      background-color: #fff;
      bottom: 0;
      width: 100%;
      z-index: $zIndexContent;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      animation-duration: 250ms;
      animation-fill-mode: forwards;

      &__content {
        padding: 20px;

        &__files {

          &__type {

            margin-top: 30px;
            margin-bottom: 40px;
            @include theme.flexbox;
            @include theme.justify-content(space-evenly);

            a {
              padding: 10px 12px;
              border-radius: 8px;
              margin: 0 8px;
              border-width: 1px;
              border-style: solid;
              @extend %bg-color-white;
              @extend %border-color-light-grey;
              transition: 150ms;

              svg {
                @extend %text-color-main;
                margin-right: 5px;
              }

              &:hover {
                cursor: pointer;
                background-color: darken(#fff, 5%);
              }
            }
          }
        }
      }

      &--in {
        animation-name: bottom-sheet-in;
      }

      &--out {
        animation-name: bottom-sheet-out;
      }

      @keyframes bottom-sheet-in {
        from {
          opacity: .5;
          transform: translateY(100%);
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes bottom-sheet-out {
        0% {
          opacity: 1;
          transform: translateY(0);
        }

        99% {
          opacity: .5;
          transform: translateY(100%);
        }

        100% {
          visibility: hidden;
          transform: translateY(0);
          z-index: -1;
        }
      }
    }
  }
}