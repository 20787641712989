/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;
@import "/src/styles/v1/variables/screen/screen.scss";

.policyVehicleSalvage {
	@include theme.flexbox-column;
	@include theme.flex-grow(1);

	&__content {
		@include theme.flexbox-content-center;

    &__wrapper {
      margin: auto;
      max-width: 550px;

			p {
				margin: 2rem auto 3rem;
				min-width: 300px;
			}
		
			a {
		
				margin: 20px 30px;
				padding: 0.5rem;
				min-width: 115px;
				max-width: 160px;
			}
    }
	}

	&__salvageMessage {
		padding: 20px;
    max-width: 600px;

		&__image {
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			display: block;
			width: 60px;
			height: 60px;
			margin: 0 auto 20px;
		}

		&__title {
			max-width: 500px;
			margin: 0 auto;
		}

		&__description {
			margin: 10px 0 0;
		}

    &__buttons {
			@include theme.flexbox-column-content-center;
      max-width: 500px;
      margin: 30px auto 0;

			&__button {
				max-width: 300px;
				margin: 0 0 20px;
			}
    }

    @include theme.respond-to(475px) {
      padding: 40px 5px 20px;

      &__item {
				max-width: 300px;
        margin: 40px auto;
      }

			&__buttons {

				&__button {
					padding: 10px 20px;
					width: 75%;
					@include theme.font-size(0);
				}
			}
    }
	}

  @media only screen and (max-width: $mobileWidth) {
    & > div div {
      width: auto;
    }
  }
}