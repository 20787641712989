@use '/src/styles/v2/index.scss' as theme;

.claimDamage {

	&__info {
		margin: 20px 0;
		@include theme.flexbox-content-center;

		span {

			margin-right: 10px;
			
			svg {
				width: 20px;
				height: 20px;
				@extend %text-color-main;
			}
		}
	}

	&__preexistence {
		margin: 30px 0;
		text-align: center;
	}

  &__pictures {

    margin-top: 30px;
  }
}