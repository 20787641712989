/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.fab-list-wrapper {

  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;

  & > button {
    width: 60px;
    height: 60px;
    padding: 0;
    background-color: var(--color-main);
    border: none;
    box-shadow: 2px 2px 10px #444444;
    color: #fff;
    border-radius: 20px;
    transition: 150ms;
    -webkit-tap-highlight-color: transparent;

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover {
      background-color: var(--color-light-main);
    }

    @media only screen and (max-width: $mobileWidth) {

      border-radius: 50%;
    }
  }

  .fabs {

    bottom: 125%;

    button {
      margin: 7.5px 0;
      width: 50px;
      height: 50px;

      animation: 150ms ease-out fabIn;
      animation-fill-mode:forwards;
      -webkit-animation: 150ms ease-out fabIn;
      -webkit-animation-fill-mode: forwards;
      opacity: 0;
    }

    $delaySum: .1;
    $delay: 0s;
    @for $i from 1 through 5 {
    
      button:nth-of-type(#{5 - $i}) {
        animation-delay: $delay
      }
      $delay: $delay + .05s;
    }
  
    @keyframes fabIn {
      0% { opacity: 0; transform: translateY(15%) scale(0); }
      100% { opacity: 1; transform: translateY(0) scale(1); }    
    }
    @-webkit-keyframes fabIn {
      0% { opacity: 0; transform: translateY(15%) scale(0); }
      100% { opacity: 1; transform: translateY(0) scale(1); }     
    }
  }
}