/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.policy-list-wrapper {

  ul {

    padding: .5rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    max-width: 850px;

    @media only screen and (max-width: 740px) {
      grid-template-columns: 1fr;
      max-width: 750px;
    }

    li {
  
      max-width: 340px;
      margin: 15px auto;
      padding: .75rem .75rem 0;
      box-shadow: 1px 1px 10px var(--color-light-grey);
      border-radius: 10px;
      transition: 150ms;
      
      section{
  
        padding: .5rem 0;
        margin: 0 1rem;

        .policy-card-renew {
          top: -5px;
          font-size: 0.65rem;
          font-weight: bold;
          margin-top: 5px;
          height: 15px;
          right: 0;
          left: 0;
  
          &[hide-renew] {
            display: none;
          }
        } 
  
        h2, h3 {
          margin-left: 10px;
        }
  
        h2 {
          letter-spacing: 2px;
          font-weight: 600;
          font-size: 1.2rem;
          color: var(--color-main);
        }
        
        h3 {
          font-size: .9rem;
          font-weight: normal;
        }
      
        &.policy-top-section {
  
          border-bottom: 1px solid var(--color-light-grey);
        }
  
        &.policy-bottom-section {
  
          .policy-left-side{
  
            .background-image {
              width: 100px;
              height: 100px;
              margin: auto;
            }         
          }
  
          .policy-right-side {
            margin-left: 1.5rem;
          }
  
          button, a {
            padding: .5rem;
            margin: .5rem;
            font-size: .8rem;
            line-height: 1;
          }
        }
      }
    }
  }

  /** Animation **/
  li{
    transition: 150ms;
    animation: 150ms ease itemIn;
    animation-fill-mode:forwards;
    -webkit-animation: 150ms ease itemIn;
    -webkit-animation-fill-mode: forwards;
    opacity: 0;
  }

  $delaySum: .1;
  $delay: 0s;
  @for $i from 1 through 150 {

    li:nth-of-type(#{$i}){
      animation-delay: $delay;
    }
    $delay: $delay + .05s;
  }

  @keyframes cardIn {
    0% { opacity: 0; transform: translateX(-15%); }
    100% { opacity: 1; transform: translateX(0); }    
  }
  /** End Animation **/

}


.renewal-badge {

  width: 85px;
  height: 85px;
  overflow: hidden;
  z-index: 1;
  top: -10px;
  left: -10px;
  user-select: none;

  span {
    width: 80px;
    padding: 5px 22px;
    background-color: var(--color-green);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    text-shadow: 0 1px 2px #556a3d;
    box-shadow: 0 0 4px #6a8e41;
    left: -30px;
    top: 20px;
    transform: rotate(-45deg);
  }

  &::before, &::after {
    content: '';
    position: absolute;
    z-index: -1;
    display: block;
    border: 5px solid #6a8e41;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &::before{
    bottom: 0;
    left: 0;
  }

  &::after{
    top: 0;
    right: 0;
  }

  &.right-badge {
    left: inherit;
    right: -10px;
    
    span {
      transform: rotate(45deg);
      left: inherit;
      right: -30px;
    }

    &::before, &::after {
      border-left-color: #6a8e41;
      border-right-color: transparent;
    }
    &::after {
      right: auto;
      left: 0;
    }
    &::before {
      left: auto;
      right: 0;
    }
    
  }
}


.policy-empty-list {

  padding: 1rem;

  h2 {
    margin-top: 15px;
    font-size: 1.3rem;
    font-weight: 500;

    span {
      font-weight: bold;
    }
  }

  span {
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: 500px;
    background-position: bottom;
  }
}

