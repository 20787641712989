@import "/src/styles/v1/variables/screen/screen.scss";

html {

  font-size: 15px;

  @media only screen and (min-width: 1400px) {
  
    font-size: 16px;
  }

}