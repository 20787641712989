/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#endorsement-summary-wrapper{

  padding: 0 1rem;
  box-sizing: border-box;

  h1 {
    color: var(--color-main);
    font-size: 1.4rem;
    margin: 5rem 0 2rem;
    
  }

  p {
    max-width: 500px;
    margin: 1rem auto;
  }

  section.relative {
    margin-top: 5rem;

    h2 {
      font-size: 1.2rem;
      margin-bottom: .5rem;
    }

    ul {
      max-width: 500px;
      margin: auto;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
  
    h1 {
      margin: 0;
    }
    
  }
}