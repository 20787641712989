/* Functional Component styles */
@import '/src/styles/v1/variables/screen/screen.scss';

div#quoter-wrapper {
	max-width: 900px;
  min-height: calc(100vh - var(--header-height));
  margin: auto;

  .bg-decoration{
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background-image: 
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fac291fa380e9a6ef6a_Ellipse%20998.svg"),
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fad8d3c272ea20f1ff5_Vector.svg"),
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fadd9b7d83d421ba9ee_Ellipse%20996.svg"),
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fada92c548086c13f40_Ellipse%20995.svg"),
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fadf6399265436ae231_Vector%20(1).svg"),
    url("https://global-uploads.webflow.com/60aea4e5ac6df65cf7e0b8a6/61f40fb6fdbc271398036083_Ellipse%20997.svg");
    background-position: 15% 5%,0 90%,5% 10%,50% 100%,100% 20%,100% 100%;
    background-size: 20%,10%,5%,5%,10%,10%;
    background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,no-repeat,no-repeat;
  }

  .quoter-content {

    background-color: #fff;
    border: var(--dashboard-border-style);
    border-radius: var(--dashboard-border-radius);
    margin: 1rem 0 2rem;
  }

  @media only screen and (max-width: $mobileWidth) {
  
    margin: 0;
  }
}