/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

$incompletedColor: #c8c8c8;

%indicator{
  content: "";
  height: 5px;
  position: absolute;
  width: 75%;
  top: 0;
  bottom: 0;
  background-color: $incompletedColor;
  margin: auto;
  z-index: -1;
}

.step-endorsement-icon {
  color: #fff;
  border-radius: 50%;
  background-color: $incompletedColor;
  transition: 150ms;
  height: 20px;
  width: 20px;
  padding: 5px;

  svg {
    height: 18px;
    width: 18px;
  }

  &[step-active],  &[step-completed] {
    opacity: 1;
  }

  &[step-completed] {
    background-color: var(--color-green);
  }

  &[step-active] {
    background-color: var(--color-main);
  }



  &::after{
    @extend %indicator;
    display: none;
    right: -76%;
  }

  &::before{
    @extend %indicator;
    display: none;
    left: -76%;
  }

  @media only screen and (max-width: $mobileWidth) { 

    padding: 8px;

    &:not([is-last])::after, &:not([is-first]):before {
      display: inherit;
    }

    &[step-completed]::before, &[step-completed]::after {
      background-color: var(--color-green);
      z-index: 1;
    }

    &[step-active]::before, &[step-active]::after {
      background-color: $incompletedColor;
      z-index: 1;
    }
  }
}