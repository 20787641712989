.relative {
  position: relative;
}

.absolute {
  position: absolute;
  
  .absolute-center {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}