/* Functional Component styles */

div.policyCreationStepWrapper {

	section.content-section {
		margin: auto;
	}
	
	div.policy-step-content {
	
		& > form {
			margin: 1rem auto;
			max-width: 380px;
		}
	}
	

	section.plan-management-bottom-section {
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
	}
}