@import "/src/styles/v1/variables/screen/screen.scss";

div#completion-data-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1, p {
		text-align: center;
		margin-bottom: 40px;

		@media only screen and (max-width: 360px){
			margin-bottom: 15px;
		}
	}

	@media only screen and (max-width: 540px){
		h1 {
			padding: 0 20px;
		}
	}

	p {
		width: 80%;

		&.completed-text {
			color: var(--color-main);
			font-weight: bold;
		}

		@media only screen and (max-width: 360px){
			width: 95%;
		}
	}

	svg {
		padding: 10px;
    background-color: var(--color-green);
		width: 35px;
		height: 35px;
		border-radius: 50%;
		fill: #fff;
		margin: 0 0 20px;
		
		@media only screen and (max-width: 360px){
			width: 30px;
			height: 30px;
			padding: 6px;
			margin: 0 0 10px;
		}
	}

	button {
		max-width: 200px;
		letter-spacing: 0;

		@media only screen and (max-width: $mobileWidth) {
			position: absolute;
			bottom: 20px;
		}

		@media only screen and (max-width: 600px){
			margin-top: 10px;
			width: 80%;
			max-width: 80%;
			font-size: 1.2rem;
			height: 50px;
		}

		@media only screen and (max-width: 350px) {
			position: relative;
			bottom: 0;
		}
	}

	.claim-receipt-link {
		background-color: var(--color-green);
		color: #fff;
		padding: .6rem .5rem;
    width: 182px;
		margin: 1rem auto;
		font-size: .9rem;
		border-radius: 10px;
		box-shadow: 0 0 5px;
		box-shadow: 2px 2px 3px var(--color-light-grey);
		transition: background-color 100ms;
		text-align: center;

		@media only screen and (max-width: $mobileWidth) {
			position: absolute;
    	bottom: 50px;
		}

		@media only screen and (max-width: 600px) {
			bottom: 70px;
			width: calc(80% - 1rem);
			height: 32px;
			font-size: 1.2rem;
		}

		&:hover {
			background-color: #279262;
		}
	}

	@media only screen and (max-width: 360px){
		justify-content: start;
	}
}