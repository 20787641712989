/* Functional Component styles */
.input-area-wrapper{

  padding: 1rem 1rem 1.75rem;

  textarea {
    resize: none;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    font-size: 0.9rem;
    letter-spacing: 0.4px;
  }

  .text-max-length {
    font-size: .8rem;
    bottom: 7.5px;
    right: 7.5px;
  }

  label {
    max-width: 300px !important;
  }
}