@import "/src/styles/v1/variables/screen/screen.scss";

div#vehicle-pictures-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	section.content {
    padding-bottom: 100px;
	
		h1 { 
			margin: 20px 0 30px;
		}

		h3 {

			&:last-of-type {
				margin: 30px 0 10px;
			}
		}
	
		& > p {
			width: 80%;
			text-align: center;
			margin: 10px 0 10px;

			&:first-of-type {
				margin: 10px 0 0;
			}
		}

		div.vehicle-pictures {
			flex-wrap: wrap;
      margin: auto;
		}

		form{
			width: 100%;
			max-width: 300px;

			div.input-label-wrapper {
				width: 100%;
	
				@media only screen and (max-width: 600px) {
					width: 280px;
					margin: 20px auto 0;
				}
			}
		}
	}

	@media only screen and (max-width: 400px) {

		section.content {

			h1 {
				margin: 0 0 20px;
			}

			p {
				width: 90%;
				margin: 10px 0 30px;
			}
		}
	}
}