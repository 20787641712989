$step: 10;
$loops: 100;

@for $i from 1 through $loops{
  .width-#{$i} {
    width: #{$i + '%'};
  }

  .height-#{$i} {
    height: #{$i + '%'};
  }
}