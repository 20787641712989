/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.policy-endorsements-step {

  font-size: 0.8rem;
  margin: 10px 5px;

  @media only screen and (max-width: $mobileWidth) {
  
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
  }
}