/* Functional Component styles */
.picture-drop-zone{
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;

  &[drag-overlay] {
    z-index: 10;
    background-color: #fff;
  }

  .drop-zone-icon{

    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;

    p {
      font-weight: 600;
      font-size: .9rem;
      font-style: italic;
    }
    
    p, svg {
      color: var(--color-light-grey);
    }

    svg {
      width: 70px;
      height: 70px;
      margin-top: 20px;
      animation: infinite 2s linear fileDropIcon;
      animation-fill-mode:forwards;
      -webkit-animation: infinite 2s linear fileDropIcon;
      -webkit-animation-fill-mode: forwards;
    }

    @keyframes fileDropIcon {
      0% { transform: translateY(0); }
      25% { transform: translateY(-15%); }   
      50% { transform: translateY(0); }   
      75% { transform: translateY(-15%); } 
      100% { transform: translateY(0); }    
    }
  }
}