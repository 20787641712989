/* Functional Component styles */
.claim-payment-policy-card {
	width: 390px;
	height: 130px;
	display: flex;
	max-width: 90%;
	margin: 15px auto 0;
	border-radius: 15px;
	box-shadow: 0 0 0.5rem -0.3rem #000;

	div.picture-wrapper {
		width: 125px;
		padding: 20px;

		img.vehicle-image {
			//CHANGE IT
		}

		div.no-picture {
			border-radius: 10px;
			border: 2px solid var(--color-main);
			background-color: #fff;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 10px;
			height: 65px;

			img {
				height: 100px;
				width: 100px;
				object-fit: contain;
			}

			p {
				font-size: 0.7rem;
				color: var(--color-main);
			}
		}

		div.skeleton-loader {
			height: 80px;
		}
	}

	div.info-wrapper {
		padding-top: 15px;
		flex: 1;

		span.status {
			color: #fff;
			font-weight: 600;
			font-size: 0.65rem;
			border-radius: 5px;
			padding: 4px 12px;
			margin-right: 10px;
			user-select: none;

			&.active {
				background-color: var(--color-translucent-blue);
				color: var(--color-main);
			}

			&.pending {
				background-color: var(--color-yellow);
			}

			&.canceled {
				background-color: var(--color-canceled);
				color: var(--color-red);
			}

			&.expired {
				background-color: var(--color-expired);
			}
		}

		h1.policy-number {
			font-size: 1.2rem;
			margin: 0 !important;
		}
		
		h2 {
			font-size: 1rem;
			margin: 0;
			line-height: 1;
		}

		p {
			margin: 0 !important;
			line-height: 1.3;
			font-size: .85rem;
			text-align: left !important;

			span {
				font-weight: bold;
			}
		}

		div.skeleton-loader {
			margin-top: 10px;
		}
	}
}