/* Functional Component styles */
.camera-wrapper {

  background-color: #000;
  z-index: 999999;

  @keyframes cameraLibIn {
    0% { transform: translateX(110%); opacity: 0.2; }
    100% { transform: translateX(0); opacity: 1; }
  }

  @keyframes cameraLibOut {
    0% { transform: translateX(0); opacity: 1; }
    100% { transform: translateX(110%); opacity: 0.2; }
  }
  
  button {
    z-index: 10;
  }

  .spinner {

    width: 40px;
    height: 40px;
  }

  .camera-permissions-message {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    max-width: 400px;
    margin: auto;

    h3, svg {
      color: #fff;
    }

    svg {
      margin: auto;
      width: 50px;
      height: 50px;
    }

    h3 {
      font-size: 1.1rem;
      padding: 0 3rem;
      font-weight: 600;
    }
  }

  .camera-actions {

    bottom: 20px;

    button {
      border: none;
      background-color: transparent;
      color: #fff;
      padding: 0;
      margin: 0 2rem;
      font-size: 2.5rem;

      svg {
        border: 3px solid #fff;
        border-radius: 50%;
        padding: .5rem;
      }
    }

    button.take-picture {

      border-radius: 50%;
      width: 80px;
      height: 80px;

  
      &::after, &::before {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position:absolute;
        border-radius: 50%;
        transition: 100ms;
      }
  
      &::after {
        border: 5px solid #fff;
        width: 70px;
        height: 70px;
      }
  
      &::before {
        width: 65px;
        height: 65px;
        background-color: #fff;
        border: 5px solid #000;
      }
  
      &:active {
  
        &::after {
          border-color: #e2e2e2;
        }
  
        &::before {
          background-color: #e2e2e2;
        }
      }
  
      &:disabled {
  
        &::after {
          border-color: #c0c0c0;
        }
  
        &::before {
          background-color: #c0c0c0;
        }
      }
    }
  }





  button.cancel-button {
    background-color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    top: 15px;
    left: 15px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px #414141;
  }

  @media only screen and (orientation: landscape) {

    .camera-actions {
      top: 0;
      bottom: 0;
      right: 30px;
      margin: auto;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      
      button {
        margin: 0;
      }
    }

    .current-camera-image {
      
      img {
        height: 100%;
      }
    }
  }
}