/* Functional Component styles */

.active-policy-claim-card-wraper {
    border-radius: 10px;
    box-shadow: 0 4px 4px #b9b8b8;
    border: 2px solid transparent;
    max-width: 375px;
    height: 100px;
    margin: 15px;
    padding: .8rem;
    transition: 150ms;
    &:hover {
        cursor: pointer;
        background-color: #eeeff3;
        .card-arrow {
            color: var(--color-light-main);
        }
    }
    &[is-loader] {
        pointer-events: none;
        opacity: .6;
    }
    .card-arrow {
        width: 20px;
        border-left: 1px solid #e0e0e0;
        color: var(--color-grey);
    }
}