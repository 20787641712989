/* Functional Component styles */
#policy-cancellation-wrapper {

  padding: 2rem;

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.2rem;
    padding: 0 3rem;
    max-width: 400px;
  }

  ul {

    max-width: 500px;
    margin: 1rem 0;

    li {
      list-style-type: disc;
      margin: 1rem 0;
      font-size: 1.1rem;
    }
  }

  form {

    section {
      margin: 1.5rem 0;
    }
  }

  .button-wrapper {

    max-width: 500px;
    margin: 1rem 0;
  }


  .policy-cancelled  {

    max-width: 500px;
    margin: 3rem auto;

    & > svg {
      background-color: var(--color-green);
      color: #fff;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin: 1rem 0;
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 2rem;
    }

    a {
      width: 200px;
    }
  }
}