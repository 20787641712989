/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.step-endorsement-name {
  margin-left: 10px;
  color: var(--color-main);
  font-weight: 500;

  @media only screen and (max-width: $mobileWidth) { 
    text-align: center;
    margin: 10px 0;
    font-size: .7rem;
  }

  @media only screen and (max-width: 460px) {
    display: none;
  }
}