/* Functional Component styles */
.button-clipboard{

  border-radius: 50%;
  border: none;
  font-size: 1.4rem;
  padding: 0;
  width: 40px;
  height: 40px;
  background-color: var(--color-main);
  color: #fff;
  box-shadow: 1px 1px 3px var(--color-grey);
  transition: 100ms;
  margin: 0 5px;

  &:hover {
     cursor: pointer;
     background-color: var(--color-light-main);
  }

  @media only screen and (max-width: 390px) {
    width: 35px;
    height: 35px;

    svg {
      font-size: 1.3rem;
    }
  }
}

button.clipboard-text {

  background-color: transparent;
  padding: 0;
  border: none;
  color: var(--color-main);
  font-weight: bold;
  font-size: inherit;
}