/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.text {

  &--type-title {
    @include theme.font-size(10);
    font-weight: 600;
  }

  &--type-subtitle {
    @include theme.font-size(4);
    font-weight: 500;
  }

  &--type-paragraph {
    @include theme.font-size(0);
    font-weight: 400;
  }

  &--type-label {
    @include theme.font-size(-3);
    font-weight: 400;
  }

  // Align
  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }

  // Weight
  &--bold {
    font-weight: 600;
  }

  // Display
  &--block {
    display: block
  }

	&--uppercase {
		text-transform: uppercase;
	}

  // Color
  &--color-default {
    @extend %text-color-text;
  }

  &--color-main {
    @extend %text-color-main;
  }

  &--color-white {
    @extend %text-color-white;
  }

  &--color-red {
    @extend %text-color-red;
  }

  &--color-green {
    @extend %text-color-green;
  }
}