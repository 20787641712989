/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policy-creation-wrapper {

  & > div {
    min-height: calc(100vh - var(--header-height));
    padding: 1rem;
  }

  .policy-creation-content {

    // border-radius: var(--dashboard-border-radius);
    // border: var(--dashboard-border-style);

    &[is-last-step] {
      margin: 0;
      border: none;
    }

    .policyCreationStepWrapper {

      & > section:nth-child(1) {

        max-width: 800px;
        margin: 0 auto;
        padding: 1rem 1.5rem;
      }

      & > section {

        header {
          h1 {
            font-size: 1.5rem;
            margin-top: 5px;
            margin-bottom: 5px;
          }
  
          p {
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  

  @media only screen and (max-width: $mobileWidth) {
    flex-direction: column; 

    .policy-creation-content {

      padding-bottom: 100px;
    }
  }
}