/* Functional Component styles */
.claim-policy-card-details{

  min-width: 150px;
  padding: 0 10px;

  h1, p {

    &:not(h1) {
      font-size: .8rem;
    }

    &:not(p){
      font-size: .9rem;
    }

    span {
      font-weight: bold;
    }
  }
}