:root {
    --color-main: #0D4EC9;
    --color-light-main: #3d76e0;
    --color-darken-main: #0a3b97;
    --color-grey: #676767;
    --color-light-grey: #E6E7E9;
    --color-translucent-blue: #e2eef8;
    --color-red: #FF3D54;
    --color-yellow: #FFC536;
    --color-green: #31D16E ;
    --color-disabled: #979797;
    --color-text: #444444;
}