/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.claimPerson {

  height: 100%;
  @include theme.flexbox-column;

  &__header {
    min-height: 80px;
    height: 80px;
    @extend %bg-color-white;
    @include theme.flexbox-content-center;

    &__left,
    &__right {
      width: 80px;
      height: 100%;
      @include theme.flexbox-content-center;
    }

    &__progress {

      height: 8px;
      border-radius: 10px;
      margin: auto;
      position: relative;
      @include theme.flex(1);
      @extend %bg-color-light-grey;

      &__bar {
        height: 100%;
        transition: width 500ms;
        position: absolute;
        border-radius: 10px;
        @extend %bg-color-main;
      }
    }
  }

  &__content {

    &__welcome {
      position: fixed;
      height: 100%;
      left: 0;
      right: 0;
      margin: auto;
      padding: 0 20px;
      @include theme.flexbox-column-content-center;

      &__message {
        font-weight: 400;
      }

      &__title {
        margin: 20px 0;
      }

      &__form {
        margin-top: 30px;
      }

      &__button {
        margin-top: 20px;
        width: 200px;
      }
    }

    &__form,
    &__wrapper {

      margin-bottom: 100px;

      &__field {
        margin: 25px 0;

        &--center {
          @include theme.flexbox-content-center;
        }
      }

      &--wrap {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 190px);

        &>* {
          margin: 15px;
        }
      }
    }

    &__form {

      &__pictures-wrapper {
        min-height: 100px;
        @include theme.flexbox;
        @include theme.flex-wrap(wrap);

        &__item {
          height: 50px;
        }
      }

      &__info {
        padding: 15px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        @extend %border-color-light-grey;
        @include theme.flexbox-content-vertical-center;

        &__right {

          margin-left: 10px;
        }
      }

      &__add-button {
        margin: 20px 0;
        @include theme.flexbox-column-content-center;

        button {

          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-bottom: 10px;
          @extend %text-color-white;
          @extend %bg-color-green;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }

    }

    &__wrapper {

      &__damage {
        padding: 5px 10px;
        margin: 15px 0;
        @extend %bg-color-white;
        @include theme.shadow;
        @include theme.flexbox-content-vertical-center;

        &__image {
          @include theme.background-image(60px, 60px);
        }

        &__type {
          padding: 0 10px;
          @include theme.flex;

          & > p {
            max-width: 210px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        &__buttons {

          width: 60px;
          @include theme.flexbox-content-center;

          &__edit {
            @extend %text-color-main;
          }

          &__remove {
            margin-left: 15px;
            @extend %text-color-red;
          }

          button {

            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      &__add-button {

        margin: 10px 0;
        border-radius: 5px;
        @include theme.shadow;
        @extend %bg-color-white;

        button {
          width: 100%;
          padding: 15px 10px;
          transition: 150ms;
          box-sizing: border-box;
          @include theme.flexbox-content-center;

          &:active {
            @extend %bg-color-light-grey;
          }

          svg {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}