/* Functional Component styles */

div.empty-state {
	display: flex;
	justify-content: center;

	p {
		margin: 3rem 0 0;
		font-size: 1rem;
		color: var(--color-grey);
	}
}