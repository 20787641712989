/* Functional Component styles */
#insurance-pictures-wrapper {

  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 75px;

  h1 {
    font-size: 1.4rem;
    padding: 0 1rem;
  }

  h2 {
    font-size: 1rem;
    margin: .5rem;
  }

  .vehicle-picture-list{
    flex-wrap: wrap;
    max-width: 500px;
    margin: auto;

    &[validating-picture] {
      opacity: .6;
      pointer-events: none;
    }
  }

  .insurance-policy-info {

    li {
      font-size: .9rem;
      margin: 2.5px 0;
    }
  }

  .button-wrapper{
    position: fixed;
    bottom: 0;
    padding: 1rem;
    background-color: #fff;
    left: 0;
  }

  .uploading-wrapper {

    padding: 0 1rem 1rem;
    max-width: 320px;

    .spinner {
      width: 50px;
      height: 50px;
      margin: 10px 0;
    }

    p{
      font-size: .9rem;
    }
  }

  .validation-error, .button-wrapper {

    button {
      max-width: 300px;
    }
  }
}