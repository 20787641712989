/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.menu-section-wrapper {

  margin: .5rem 0;

  .menu-toggle{

    padding: .35rem 1rem;
    border-radius: 8px;
    transition: background-color 150ms;

    @media only screen and (max-width: $mobileWidth) {
      border-radius: 0;
    }

    h2 {
      font-size: 0.85rem;
      font-weight: 600;
      line-height: 1;
      color: var(--color-grey);
      letter-spacing: 1px;
    }

    span {

      transition: transform 150ms;

      svg {
        width: 32px;
        height: 32px;
        color: var(--color-grey);
      }
    }

    &[menu-toggled] {

      h2, span svg {
        color: var(--color-main);
      }

      span {
        transform: rotate(180deg);
      }
    }

    &:hover{
      cursor: pointer;
      background-color: var(--color-translucent-blue);
    }
  }

  ul {

    padding-left: 2rem;

    li {

      padding-left: 5px;
      border-left: 1px solid var(--color-light-grey);
      
    }
  }

}