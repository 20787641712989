/* Functional Component styles */
.checkout-spinner-wrapper {

  z-index: 9999;

  .checkout-spinner-background {
    background: #ababab;
    opacity: 0.5;
  }

  .checkout-spinner-content {

    width: 400px;
    padding: 1.5rem 1rem;
    text-align: center;
    background-color: #fff;

    h1 {
      font-size: 1.8rem;
    }

    h2 {
      font-size: 1.1rem;
      color: var(--color-grey);
      font-weight: normal;
      margin: 5px;
    }
    
    svg {
      color: var(--color-main);
      width: 55px;
      height: 50px;
    }
  }
}