/* Functional Component styles */
.claim-card-details {

  width: 150px;
  padding: 0 10px;

  h1, h2, p {

    &:not(p) {
      font-weight: 600;
    }
    font-size: .9rem;

    span {
      font-weight: bold;
    }
  }

}