/* Functional Component styles */

.range-slider-wrapper {

  .rangeslider {

    box-shadow: none;
    height: 9px;
    background-color: var(--color-light-grey);
  
    .rangeslider__fill{
      background-color: transparent;
      box-shadow: none;
    }
  
    .rangeslider__handle {
  
      background-color: var(--color-main);
      border: none;
      box-shadow: 0px 1px 5px var(--color-light-main);
      width: 28px;
      height: 28px;
      z-index: 1;
      outline: none;
  
      &::after {
        display: none;
      }
    }
  
    .rangeslider__labels{
      margin-top: 5px;
  
      li {
        transition: color 150ms;
        font-size: .8rem;
        width: 70px;
        text-align: center;
  
        &[label-selected] {
          font-weight: bold;
          color: var(--color-main);
        }
  
        &::after {
          content: "";
          position: absolute;
          background-color: var(--color-grey);
          width: 9px;
          height: 9px;
          border-radius: 50%;
          opacity: .2;
          top: -24px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: -1;
        }
      }
  
      li:nth-child(1) {
        &::after {
          right: -20px;
        }
      }
  
      li:last-of-type {
        &::after {
        left: -20px;
        }
      }
    }
  }

  &[is-disabled]{
    pointer-events: none;

    .rangeslider__handle {
      background-color: var(--color-light-grey);
      box-shadow: 0px 1px 5px var(--color-light-grey);
    }

    .rangeslider__labels{

      opacity: .5;
    
      li {

        &[label-selected] {
          color: var(--color-text);
          font-weight: normal;
        }
      }
    }
  }
}
