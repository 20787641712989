/* Functional Component styles */
.policy-document-file-wrapper {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ddd;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-weight: bold;
  transition: 150ms;
  margin: 5px 0 15px;

  &[last-document] {
    border-bottom: none;
  }

  &[primary-file] {

    svg {
      color: var(--color-main);
    }
  }

  span {
    font-size: .9rem;
    flex: 1;
    text-align: left;
  }

  svg {
    width: 25px;
    height: 25px;
  }

  &:hover {

    span, svg {
      color: var(--color-light-main);
    }
  }
}