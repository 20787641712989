/* Functional Component styles */
div.policyCreationStepWrapper {

	.back-button {
		text-align: left;
	}

	.content-section {
		max-width: 800px;
    margin: 0 auto;
		width: 100%;
	}

	.plan-management-bottom-section {
		margin: 0 0;
	}
}