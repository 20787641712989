/* Functional Component styles */
.radio-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 5px 10px;
  cursor: pointer;
  font-size: .9rem;
  font-weight: 500;
  -webkit-tap-highlight-color: transparent;
  
  .radio-text {
    margin-left: 5px;
  }

  input {
    opacity: 0;
    top: 0;
    left: 0;
    margin: 0;
    cursor: pointer;
    z-index: 1;

  }

  .checkmark {
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    min-width: 22px;
    background-color: #fff;
    border-radius: 50%;
    border-style: solid;
    border-width: var(--form-input-border-width);
    border-color: var(--form-input-border-color);

    &:after {
      content: "";
      position: relative;
      margin: auto;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      width: 0;
      height: 0;
      border-radius: 50%;
      background: var(--color-green);
      transition: 100ms;
    }
  }

  input:checked ~ .checkmark,  input[checked]:not([no-form]) ~ .checkmark {
    background-color: #fff;
  }

  input:checked ~ .checkmark:after, input[checked]:not([no-form]) ~ .checkmark:after {
    opacity: 1;
    width: 16px;
    height: 16px;
  }
}