@import "/src/styles/v1/variables/screen/screen.scss";

/* Functional Component styles */
.planManagement {


  &__title{
    font-weight: 500;
    margin-bottom: 2rem;
  }

  &__section {

    width: 350px;
    margin: 1rem auto 1.5rem;

    &--short {
      width: 200px;
    }

    &--minHeight {
      min-height: 0;
    }

    &__info {
      font-size: .9rem;
    }
  }

  &__couponForm {

    border-radius: 5px;
    border: 1px solid var(--color-light-grey);
    padding: .5rem;
    background-color: #fff;

    svg {
      width: 20px;
      height: 20px;
    }

    &__field {
      margin-left: 1rem;

      &__input{

        input {
          border: none;
          font-weight: bold;
          margin-right: .5rem;
  
          &::placeholder {
            font-weight: 500;
            opacity: .5;
          }
        }
  
        &__button {
          color: var(--color-main);
          font-weight: bold;

          &--remove {
            color: var(--color-red);
          }
        }
      }
      
      p {
        font-size: .9rem;
        opacity: .8;
      }

    }

    &[disabled-form] {

      input {
        background: #eee;
        opacity: .8;
        pointer-events: none;
      }
    }
  }
}

.planBg {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;

  &__wrapper {
    position: relative;
    left: 0px;
    width: 100%;
    height: 100%;
    bottom: -580px;

    img {
      width: 100%;
      position: relative;
      top: 1%;
    }

    &__color {
      height: 100%;
      width: 100%;
      position: sticky;
      background-color: #f8f8f8;
      top: 0;
      bottom: 260px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    display: none;
  }
}