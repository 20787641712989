/* Functional Component styles */
.reward-info-wrapper {

  top: 100%;

  & > div {
    background-color: #fff;
    padding: 1rem;
    top: 10px;
    box-shadow: 0 0 5px #c4c4c4;
    border-radius: 10px;
    width: 50vw;
    max-width: 500px;
    min-width: 300px;
  
    &:after {
      content: "";
      position: absolute;
      transform: rotate(45deg);
      box-shadow: 0 0 5px #c4c4c4;
      top: -10px;
      left: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      margin: auto;
      z-index: -1;
    }
  
    &::before {
      content: "";
      position: absolute;
      transform: rotate(45deg);
      top: -10px;
      left: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: #fff;
      margin: auto;
      z-index: 0;
    }
  }

  section {

    padding: .5rem 0;

    p {
      margin: 5px 10px;
      font-size: .85rem !important; 
      font-weight: normal !important;
    }
  }

  section:last-of-type {
    border-top: 1px solid #eee;
  }

  .reward-info-image {

    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}