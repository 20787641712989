@import "/src/styles/v1/variables/screen/screen.scss";
/* Functional Component styles */

.claim-payment-sidebar {
  padding: 2rem 0.5rem 2rem 0;
  font-weight: bold;
  h1 {
    font-size: x-large;
    padding-bottom: 1rem;
  }
  div.claim-topbar {
    display: none;
  }
  .claim-sidebar-options {
    padding-top: 1rem;
  }
  .disabled-link {
    pointer-events: none;
    opacity: 0.7;
    background-color: var(--color-light-grey);
    border-color: var(--color-light-grey);
    box-shadow: none;
  }
  div.claim-sidebar {
    flex-direction: column;
  }
  @media only screen and (max-width: $mobileWidth) {
    padding: 0;
    display: flex;
    align-items: center;
    div.claim-sidebar {
      display: none;
    }
    div.claim-topbar {
      display: block;
      width: 100%;
      $borderStepWidth: 2px;

      section {
        .claims-step-wrapper {
          height: 30px;
          border: $borderStepWidth solid var(--color-light-grey);
          padding: 0.75rem;
          min-width: 120px;

          @for $i from 1 through 4 {
            &.step-#{$i} {
              left: -$borderStepWidth * ($i - 1);
            }
          }

          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #fff;
            transform: rotate(45deg);
            border-top: $borderStepWidth solid var(--color-light-grey);
            border-right: $borderStepWidth solid var(--color-light-grey);
            top: 0;
            bottom: 0;
            margin: auto;
          }

          &::after {
            right: -10px;
            z-index: 1;
          }
          &::before {
            left: -9px;
            z-index: -1;
          }

          &.step-1 {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            &::before {
              content: inherit;
            }
          }

          &.step-4 {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            &::after {
              content: inherit;
            }
          }

          .claims-step-icon,
          .claims-complete-icon, .claims-missing-icon, .claims-denied-icon {
            width: 30px;
            font-size: 1.5rem;
            margin-left: 5px;
          }

          .claims-complete-icon, .claims-missing-icon, .claims-denied-icon {
            display: none;
          }

          p {
            max-width: 100px;
            text-align: left;
            line-height: 1;
            font-weight: 600;
            font-size: 0.8rem;
            margin-left: 5px;
          }

          p,
          .claims-step-icon {
            color: var(--color-grey);
          }

          &[claims-step-active] {
            border-color: var(--color-main);
            z-index: 1;
            p,
            svg {
              color: var(--color-main);
            }
            &::after,
            &::before {
              border-color: var(--color-main);
            }

            &::before {
              z-index: 1;
            }
          }

          &[claims-step-done]:not([claims-step-active]) {
            p {
              color: var(--color-text);
            }
            .claims-complete-icon {
              display: inherit;

              svg {
                background-color: var(--color-green);
                color: #fff;
                border-radius: 50%;
                width: 22px;
                height: 22px;
                margin: auto;
              }
            }

            .claims-step-icon {
              display: none;
            }
          }

					&[claims-step-missing]:not([claims-step-active]) {
            p {
              color: var(--color-text);
            }
            .claims-missing-icon {
              display: inherit;

              svg {
                background-color: var(--color-yellow);
                color: #fff;
                border-radius: 50%;
								width: 14px;
								height: 14px;
								margin: auto;
								padding: 4px;
              }
            }

            .claims-step-icon {
              display: none;
            }
          }

					&[claims-step-warning]:not([claims-step-active]) {
            p {
              color: var(--color-text);
            }
						
            .claims-denied-icon {
              display: inherit;

              svg {
                background-color: var(--color-red);
                color: #fff;
                border-radius: 50%;
								width: 18px;
								height: 18px;
								margin: auto;
								padding: 2px;
              }
            }

            .claims-step-icon {
              display: none;
            }
          }

          @media only screen and (max-width: 590px) {
            padding: 0.4rem;
            min-width: 70px;
            flex-direction: column;
            justify-content: space-evenly;
            height: 55px;

            p {
              font-size: 0.7rem;
              text-align: center;
            }

            .claims-step-icon {
              font-size: 1.3rem;
            }

            &::after,
            &::before {
              height: 10px;
              width: 10px;
            }

            &::after {
              right: -7px;
            }
            &::before {
              left: -6px;
            }
          }
        }

        .claim-topbar-footer {
          width: 160px;
          margin: 15px auto 0;

          .button,
          .claim-receipt-link {
            font-size: 0.7rem;
            border-radius: 5px;
            padding: 0.5rem;
            font-weight: 600;

            &.claim-receipt-link {
              background-color: var(--color-green);
              color: #fff;
              padding: 0.75rem 1rem;
            }
          }
        }

				&.agreed-mode {

					div.claims-step-wrapper {

						&.step-3 {
							left: -2px;
						}

						&.step-4 {
							left: -4px;
						}
					}
				}
      }
    }
  }
  .footer-buttons {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    .button {
      margin: 1rem;
      a {
        width: 180px;
        margin: 1rem auto;
      }
    }
    .claim-receipt-link {
      background-color: var(--color-green);
      color: #fff;
      padding: 0.5rem;
      width: 180px;
      margin: 1rem auto;
      border-radius: 10px;
      box-shadow: 0 0 5px;
      box-shadow: 2px 2px 3px var(--color-light-grey);
      transition: background-color 100ms;
			
      &:hover {
        background-color: #279262;
      }
    }
  }
}
