@import "/src/styles/v1/variables/screen/screen.scss";
/* Functional Component styles */

#plan-review-renewal-wrapper {

	@media only screen and (max-width: $mobileWidth) {
		padding-bottom: 13rem;
	}

	button.back-button {

		@media only screen and (min-width: $mobileWidth) {
			padding-left: 2rem;
		}
	}
}