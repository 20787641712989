@use 'src/styles/v2/index' as theme;
@import '/src/styles/v1/variables/colors/colors.scss';
@import '/src/styles/v1/variables/dashboard/border.scss';
@import '/src/styles/v1/variables/dashboard/header.scss';
@import '/src/styles/v1/variables/form/input-wrapper.scss';
@import '/src/styles/v1/variables/form/policy-form.scss';
@import '/src/styles/v1/variables/login/background.scss';
@import '/src/styles/v1/variables/login/content-wrapper.scss';
@import '/src/styles/v1/variables/screen/screen.scss';

@import '/src/styles/v1/properties/fonts/font-size.scss';
@import '/src/styles/v1/properties/fonts/font-type.scss';
@import '/src/styles/v1/properties/fonts/text.scss';
@import '/src/styles/v1/properties/wrapper/flex.scss';
@import '/src/styles/v1/properties/wrapper/dimension.scss';
@import '/src/styles/v1/properties/wrapper/position.scss';
@import '/src/styles/v1/properties/wrapper/block.scss';
@import '/src/styles/v1/properties/background/image.scss';
@import '/src/styles/v1/properties/plan/plan-bottom-section.scss';

html, textarea, input {
  font-family: theme.$base-font-family, theme.$base-font-emoji-family;
}


html, body {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  margin: 0;
}

html {
  overflow: overlay;
}

body {
  align-items: center;
}

#root {
  width: 100%;

  @media only screen and (max-width: $mobileWidth) {
    min-height: 100%;
  }
}

#main {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  & > div:last-of-type {
    width: 100%;
    max-width: $maxWidth;
    margin: auto;
  }
  
  @media only screen and (max-width: $mobileWidth) {


    & > :last-child {
      padding-top: var(--header-height);
    }
  }
}

input {
  outline: none;
}

button {

  all: unset;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  &:hover:enabled {
    cursor: pointer;
  }

  
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

a {
  text-decoration: none;

  &.underline:hover {
    text-decoration: underline;
  }
}

img {
  user-select: none;
}

span, a, p, h1, h2, h3, h4, h5, h6 {
  color: var(--color-text); 
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  background-color: #f2f2f2;
  padding-bottom: .5rem;
  border-radius: 1rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: darken(#fff, 15%);
  margin: 1rem;
  border-radius: 3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number]{
	-moz-appearance: textfield;
}

/** CHANGE IT */
html, body {

	@media only screen and (max-width: $mobileWidth) {
		-ms-overflow-style: none; /* for Internet Explorer, Edge */
		scrollbar-width: none;

		::-webkit-scrollbar-track {
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			background-color: transparent !important;
			display: none;
		}
		::-webkit-scrollbar {
			width: 0px !important;
			background-color: transparent !important;
			display: none;
		}
		::-webkit-scrollbar-thumb {
			background-color: transparent !important;
			display: none;
		}
	}
}