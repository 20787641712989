@use '/src/styles/v2/index.scss' as theme;

.billForm {

  height: 100%;
  width: 500px;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 25px 30px;
  box-sizing: border-box;
  overflow-y: auto;
  @include theme.flexbox-column;
  @include theme.justify-content(space-between);

  &__section {

    padding: 20px;

    &--action {
      position: sticky;
      bottom: 0;
      padding: 0 15px;
    }

    &__close-button {
      position: absolute;
      top: 20px;
      right: 20px;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    &__form {
      margin-top: 15px;

      &__field {

        margin-bottom: 25px;

        &--space {
          margin-bottom: 40px;
        }

        &--radio {
          margin-bottom: 30px;
        }

        &__input {

          &--uppercase {
            text-transform: uppercase;

            &::placeholder {
              text-transform: none !important;
            }
          }
        }
      }
    }
  }

  @include theme.respond-to(800px) {
    width: 100%;
    border-radius: 0;
    padding: 25px 20px 20px;

    &__section {
      padding: 0;

      &__close-button {
        top: 15px;
      }
    }
  }
}