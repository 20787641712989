/* Colors */
$-color-main: #0D4EC9;
$-color-light-main: #3d76e0;
$-color-darken-main: #0a3b97;
$-color-grey: #676767;
$-color-light-grey: #E6E7E9;
$-color-translucent-blue: #e2eef8;
$-color-red: #FF3D54;
$-color-yellow: #FFC536;
$-color-green: #31D16E ;
$-color-text: #444444;
$-color-white: #ffffff;
$-color-black: #000000;

/* Colors Palette */
$colors: (
  'main': $-color-main,
  'light-main': $-color-light-main,
  'darken-main': $-color-darken-main,
  'grey': $-color-grey,
  'light-grey': $-color-light-grey,
  'translucent-blue': $-color-translucent-blue,
  'red': $-color-red,
  'yellow': $-color-yellow,
  'green': $-color-green,
  'text': $-color-text,
  'white': $-color-white,
  'black': $-color-black,
);

/* Screen Breakpoints */
$breakpoints: (
  'screen-mobile': 480px,
  'screen-medium': 800px,
  'screen-large': 1400px
);

/* Fonts */
$base-font-family: 'Poppins';
$base-font-emoji-family: 'Emoji';
$font-sizes: (
  null: 15px,
  map-get($breakpoints, 'screen-mobile'): 15px,
  map-get($breakpoints, 'screen-medium'): 16px,
  map-get($breakpoints, 'screen-large'): 17px,
);

/* Box Shadow */
$box-shadow-color: rgba(#000000, 0.1);

/* Topbar Height */
$sidebarWidth: 60px;
$tobarHeight: 70px;