@import '/src/styles/v1/variables/screen/screen.scss';

div#quoter-data-form {


	& > div.form-wrapper {

    padding: 2rem 4rem;

		&.opacity {
			opacity: .6;
		}

		& > h1, & > p {
			width: 100%;
			text-align: center;
		}
	
		& > p {
			margin-bottom: 50px;
	
			&.password-text {
				margin: 50px 0;
			}
		}

    @media only screen and (max-width: $mobileWidth){
      padding: 1rem;
    }

	}

  & > div.button-wrapper {
    padding: 1.5rem 0 2rem;
    margin-top: 1.5rem;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0 -1px 10px var(--color-light-grey);
    border-bottom-left-radius: var(--dashboard-border-radius);
    border-bottom-right-radius: var(--dashboard-border-radius);

    button {
      max-width: 200px;
      margin: 0 auto;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 20px;
      font-style: italic;
    }
  }

	@media only screen and (max-width: $mobileWidth){

    div.button-wrapper {
      box-shadow: inherit;
    }
	}
	
	@media only screen and (max-width: 680px) {
		padding: 20px;
	}
	
	& > .spinner {
		position: fixed;
	}
}