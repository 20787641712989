/* Functional Component styles */
.user-name-wrapper {

  margin: 0 10px;

  h1 {
    font-size: 1rem;
    font-weight: 500;
  }

  @media only screen and (max-width: 420px) {

    margin: 0;
    
    h1{

      span {
        display: none;
      }
    }
  }
}