/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#dashboard-wrapper {

  .dashboard-content {
    border: var(--dashboard-border-style);
    border-radius: var(--dashboard-border-radius);
    margin: 20px;

    @media only screen and (max-width: $mobileWidth) {
      margin: 0;
    }
  }
}