@use '/src/styles/v2/index.scss' as theme;

.claimFormDisabled {
  
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  @include theme.flexbox-column;

  &__logo {

    @include theme.flexbox-column-content-center;
    @include theme.flex(.3);

    img {
      width: 250px;
      height: auto;
    }

  }

  &__message {

    &__large {
      margin: 20px 0;
    }

    &__link {

      margin-top: 50px;
      
      a {
        @extend %text-color-main;
      }
    }
  }
}