@import "/src/styles/v1/variables/screen/screen.scss";

:root {

  --login-content-wrapper-max-width: 550px;
  --login-content-wrapper-margin: 2rem auto;
  --login-content-wrapper-padding: 0.5rem 1.5rem;
}


@media only screen and (max-width: $mobileWidth) and (max-height: 750px) {
  
  :root {
    --login-content-wrapper-margin: 1rem auto;
  }
}