.plan-management-bottom-section {
  box-shadow: 0 0 5px #c5c5c5;
  border-radius: var(--dashboard-border-radius);
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 10;
  min-height: 100px;
  max-height: 140px;
  position: sticky;
  padding: .5rem 2rem;
  box-sizing: border-box;

  .plan-cost, .plan-vehicle {
    margin: 0 1rem;
    width: 230px;
  }

  .plan-vehicle {
    border-left: 1px solid var(--color-grey);
  }

  .plan-cost {

    h2{
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 5px;

      &, span {
        color: var(--color-main);
      }
    }

    h3 {
      font-size: 1.1rem;
      line-height: 1;
    }

    h2, h3, p {

      span {
        font-size: .8rem;
        font-weight: 400;
      }
    }

    p {
      font-weight: bold;
      text-decoration: line-through;
      font-size: .9rem;
      font-style: italic;

      &:not(.annual), &:not(.annual) span {
        color: var(--color-main);
      }

    }

    h4 {
      font-size: .8rem;
      font-style: italic;
      margin-bottom: 5px;
    }
  }
  .plan-button {

    button {
      width: 180px;
    }
  }


  @media only screen and (max-width:1100px) {

    position: fixed;
    width: 100%;
    padding: 0.5rem;

    .plan-cost {
      margin: 0;

      h2 {
        font-size: 1.3rem;
      }
    }

    .plan-vehicle {
      display: none;
    }

    .plan-button {

      button {
        width: 100%;
        max-width: 180px;
      }
    }
  }

}