/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.policyEndorsementsHistory{


  &__wrapper {

    height: 100%;

    &__endorsement {

  
      margin: 1rem .5rem;
      padding: 1rem 1.5rem;
      background-color: #fff;
      min-height: 50px;
      border-bottom: 1px solid var(--color-light-grey);;

      h3 {
        font-size: 1.1rem;
        letter-spacing: .5px;
      }

      ul li {
        list-style: disc;
        margin-left: 40px;
    

        p {
          font-size: .9rem;
          span, i {
            font-weight: bold;
          }
        }
      }

      &:hover {
        background-color: #f8f8f8;
      }
    }
    
  }

  &__empty {

    &__image {
      display: block;
      width: 80px;
      height: 80px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 10px 0;
    }

    h3 {
      font-weight: normal;
    }

    h4 {
      font-weight: normal;
      margin: 0.5rem 0 2rem;
      font-size: .9rem;
      color: var(--color-grey);
    }
  }

  &__button-wrapper {
    width: 250px;
    text-align: center;

    &--bottom {
      position: sticky;
      bottom: 0;
      padding: 1.2rem;
      margin-bottom: 1rem;
      width: 100%;
      background-color: #fff;
      box-sizing: border-box;
      border-bottom-left-radius: var(--dashboard-border-radius);
      border-bottom-right-radius: var(--dashboard-border-radius);

      @media only screen and (max-width: $mobileWidth){
        position: fixed;
      }
    }
  }
}