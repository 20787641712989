/* Functional Component styles */

@import "/src/styles/v1/variables/screen/screen.scss";
#claims-wrapper {

  .claims-list-wrapper {
    padding: .5rem;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    
		&.no-claims {
			display: block;
			text-align: center;
		}

		p.empty-data {
			margin-top: 40px;
			color: #3c3c3c;
		}

    @media only screen and (max-width: 740px) {
      grid-template-columns: 1fr;
    }
  }

  .claim-list-counter {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    font-size: 0.7rem;
    background-color: var(--color-darken-main);
    color: #fff;
    box-shadow: 1px 1px 3px #3c3c3c;
  }


  footer {
        border-top: var(--dashboard-border-style);
        border-radius: var(--dashboard-border-radius);
        box-shadow: var(--dashboard-box-shadow);
        background-color: #fff;
        position: sticky;
        bottom: 0;
        a {
            margin: 1rem;
        }
    }
    @media only screen and (max-width: $mobileWidth) {
        padding-bottom: 25px;

        footer {
            position: fixed;
            width: 100%;
        }
    }
}