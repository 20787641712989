@use '/src/styles/v2/index.scss' as theme;

.claimAdjuster {

  padding: 25px 0 15px;

  &__content {

    margin-bottom: 100px;

    &__adjuster-info {

      @include theme.flexbox-column-content-center;
      padding: 0 10px;
      margin-bottom: 100px;

      &__logo {

        margin: 10px 0;
      
        img {
          width: 125px;
          height: 70px;
          margin: 0 auto 10px;
          display: block;
        }
      }

      &__section {
        border-radius: 10px;
        padding: 15px 10px;
        margin: 15px 0;
        width: 100%;

        @include theme.shadow(5px);

        &__info {
          margin: 15px 0 10px;
          padding: 0 5px; 
          position: relative;

          &--hasIcon {
            @include theme.flexbox-content-center;
            margin: 30px 0;

            span {
              flex: 1;
            }
          }

          &__image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 45px;
            height: 45px;
            margin-right: 15px;
          }

          &__policy-status {
            margin: 10px 0;
            @include theme.flexbox-content-center;
          }

          &__link {
            display: block;
            margin: auto;
            border-radius: 10px;
            padding: 10px;
            border-width: 2px;
            border-style: solid;
            margin: 10px auto 20px;
            width: 175px;
            @extend %border-color-main;
          }

          &__coverage {

            margin: 5px 0 10px;
            padding: 5px 10px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            @extend %border-color-light-grey;
            @include theme.flexbox-content-vertical-center;

            &__type {
              @include theme.flexbox-content-vertical-center;
              @include theme.flex(1);

              &__text {
                margin-left: 10px;
              }

              &__icon {
                width: 20px;
                height: 20px;
                border-radius: 5px;
                top: 2px;
                left: 155px;
                @include theme.flexbox-content-center;

                svg {
                  width: 18px;
                  height: 18px;
                  @extend %text-color-white;
                }

                &--enabled {
                  @extend %bg-color-green;
                }

                &--disabled {
                  @extend %bg-color-red;
                }
              }
            }
            
            &__icon {
              position: absolute;
            }
          }

          &__call {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            bottom: 0px;
            margin: auto;
            right: 80px;
            @include theme.flexbox-content-center;

            svg {
              width: 25px;
              height: 25px;
            }
          }

          &--row {
            display: grid;
            grid-template-columns: 30px 110px 30px;
            justify-content: center;
            align-items: center;
          }

          &--short-row {
            margin: auto;
            width: 220px;
          }

          &__value {

            &__icon {
              margin: 0 10px;

              svg {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }

    &__completion {
      @include theme.flexbox-column-vertical-center;
      justify-content: space-evenly;
      height: 450px;
      margin: 20px;
    }

    &__section {
      padding: 0 5px;

      &__header {
        margin: 5px 0 25px;
        padding: 10px 0;
        border-width: 1px;
        border-bottom-style: solid;
        @extend %border-color-light-grey;
        @include theme.flexbox-content-vertical-center;

        &__marker {
          width: 12px;
          height: 12px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;

          &--valid {
            @extend %bg-color-green;
          }

          &--invalid {
            @extend %bg-color-red;
          }
        }
      }

      &__vehiclePicturesText {
        margin-bottom: 35px;
      }

      &__field {

        &__signature {

          &__preview {
            border-radius: 10px;
            border-width: 2px;
            border-style: solid;
            margin: 30px 0 20px;
            position: relative;
            @extend %border-color-light-grey;

            &__edit-button {
              position: absolute;
              border-radius: 50%;
              right: -15px;
              top: -15px;
              width: 35px;
              height: 35px;
              @include theme.shadow;
              @include theme.flexbox-column-content-center;
              @extend %bg-color-main;
              

              svg {
                width: 20px;
                height: 20px;
                @extend %text-color-white;
              }
            }

            img {
              width: 250px;
              height: 120px;
              display: block;
              margin: auto;
            }
          }

          &__button {
            width: 200px;
            margin: 20px auto;
          }
        }

        &__privacyText {
          margin: 20px 0 0;

          span {
            display: block;

            a {
              @extend %text-color-main;
              font-weight: bold;
            }
          }
        }
      }

      &__conclusionText {
        display: block;
        margin: -10px 0 15px;
      }

      &__add-button {
        margin: 20px 0;
        @include theme.flexbox-column-content-center;

        button {

          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-bottom: 10px;
          @extend %text-color-white;
          @extend %bg-color-green;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

	&__arrivalModal {
		padding: 20px;

		&__buttonsWrapper {
			display: flex;
			justify-content: center;
			margin-top: 30px;
			gap: 10px;

			button {
				max-width: 200px;
			}
		}
	}

  &__noticesModal {
    padding: 1rem;

    &__warningImage {
      width: 70px;
      height: 70px;
      margin: 0 auto 10px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h1 {
      margin-bottom: 15px;
    }

    &__advicesWrapper { 
      position: relative;
      max-height: 280px;
      overflow: overlay;
      padding-top: 10px;

      &__item {
        @include theme.flexbox-content-center;
        @include theme.justify-content(flex-start);
        margin: 10px 0;
        gap: 15px;

        &__icon {
          @include theme.flexbox-content-vertical-center;
          width: 30px;

          svg {
            @include theme.font-size(10);
            @extend %text-color-red;
          }
        }

        p {
          flex: 1;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 15px;
        top: 0;
        background-color: #fff;
        background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6110819327731092) 35%);
      }
    }

    &__buttonsWrapper {
      display: flex;
      gap: 40px;
      margin: 2rem 0 0;
    }
  }
}