/* Functional Component styles */
@use '/src/styles/v2/index' as theme;

.claimContent {

  @include theme.flexbox-column;
  height: 100%;

  .backButton {
    position: absolute;
		left: 100px;
    top: 0;
    z-index: 1;

		@include theme.respond-to(1000px) {
      left: 40px;
    }

    @include theme.respond-to(map-get(theme.$breakpoints, 'screen-medium')) {
      left: 10px;
      top: -60px
    }
  }

  &__section {

    &--header {
      @include theme.flexbox-column-vertical-center;
      position: relative;
      padding: 0 20px;
    }

    &--content {
      @include theme.flexbox-column-horizontal-center;
      @include theme.flex(1);
      margin-top: 10px;
      padding: 0 20px;


      .quoterContent__section__transitioner{
        @include theme.respond-to(map-get(theme.$breakpoints, 'screen-mobile')) {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
    }

    &__title {
      @include theme.font-size(6px, true);    
    }

    &__subtitle {
      max-width: 700px;
      margin: auto;
      font-weight: 500;
      margin-top: 15px;
      @include theme.font-size(2px, true);

      @media only screen and (max-width: 500px) {
        max-width: 400px;
      }
    }

    &__message {
      margin-top: 20px;
			padding: 0 20px;
    }

    &__transitioner {
      animation-name: section-in;
      animation-duration: 600ms;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: forwards;
      position: relative;

      &--delay {
        animation-delay: .2s;
        opacity: 0;
        width: 100%;
        max-width: 400px;
      }

      &--landscape {
        max-width: 80%;
      }
    }

    @keyframes section-in {

      0% {
        opacity: .1;
        // transform: translateY(25%);
        bottom: -100px;
      }

      100% {
        opacity: 1;
        // transform: translateY(0);
        bottom: 0;
      }
    }
  }
}