/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.fab {

  border-radius: 20px;
  width: 60px;
  height: 60px;
  padding: 0;
  background-color: var(--color-main);
  border: none;
  color: #fff;
  box-shadow: 2px 2px 10px #444444;
  border: 2px solid transparent;
  transition: 150ms;
  -webkit-tap-highlight-color: transparent;
  
  svg {
    height: 28px;
    width: 28px;
  }

  span {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 130%;
    height: 20px;
    font-weight: bold;
    background: var(--color-light-main);;
    border-radius: 7px;
    padding: 0.15rem 1rem;
    color: #fff;
    font-size: .85rem;
  }

  &:hover {
    background-color: var(--color-light-main);
  }

  &.outline {
    border-color: var(--color-main);
    background-color: #fff;
    color: var(--color-main);

    &:hover {
      background-color: var(--color-light-main);
      border-color: var(--color-light-main);
      color: #fff;
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    border-radius: 50%;
  }
}