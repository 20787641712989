.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.vertical-align-middle {
  vertical-align: middle;
}


.border-box {
  box-sizing: border-box;
}