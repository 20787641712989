/* Functional Component styles */
.checkbox-wrapper {
  margin: 5px 0;
  font-size: .9rem;
  font-weight: 500;

  .check-text {
    margin-left: 30px;

    a {
      position: relative;
      z-index: 10;
      color: var(--color-light-main);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  input {
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    margin: auto;
  }

  .checkmark {
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border-style: solid;
    border-width: var(--form-input-border-width);
    border-color: var(--form-input-border-color);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 3px;
      right: 0;
      margin: auto;
      z-index: 1;
      display: none;

      pointer-events: none;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  // &:hover input ~ .checkmark {
  //   background-color: rgb(255, 255, 255);
  // }

  input:checked ~ .checkmark {
    background-color: var(--color-green);
    border-color: var(--color-green);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &[position-end] {
    justify-content: flex-end;

    .check-text {
      margin-right: 30px;
      margin-left: 0px;
    }
    
    .checkmark {
      left: auto;
      right: 0;
    }
  }
}