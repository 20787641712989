.rewards-history-header-skeleton {
  .skeleton-loader {
    margin: 15px !important;
  }
}

.rewards-history-body-skeleton {
  .table {
    margin-top: 5rem;
  }

  .table-header {
    border-bottom: 1px solid #e2e8f0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    padding-bottom: 1rem;
    width: 100%;
  }

  .skeleton-loader {
    max-width: 125px;
  }
}
