/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#endorsement-completion{

  section.flex {

    margin: 15px 0;

    svg {
      margin: 25px 0;
      background-color: var(--color-green);
      width: 50px;
      height: 50px;
      color: #fff;
      border-radius: 50%;
    }

    p {
      margin: 20px 0;
      max-width: 450px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
  
    justify-content: flex-start;
    
  }
}