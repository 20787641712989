/* Functional Component styles */
#policy-pictures-wrapper {

  padding: 2rem 0 0;

  & > div {

    section {

      padding: 2rem;
      
      h2 {
        font-size: 1.6rem;
      }
  
      p {
  
        margin: 1rem 0;
        font-weight: 300;
        font-size: 1.2rem;
      }
  
      .qr-wrapper {
        transform: scale(1.12);
        $borderThickness: 5px;
        margin: 0 3rem;
        border:$borderThickness solid transparent; /* space for the border */
        background:
          conic-gradient(from 90deg  at top    $borderThickness left  $borderThickness, #0000 90deg, var(--color-text) 0) 0    0,
          conic-gradient(from 180deg at top    $borderThickness right $borderThickness, #0000 90deg, var(--color-text) 0) 100% 0,
          conic-gradient(from 0deg   at bottom $borderThickness left  $borderThickness, #0000 90deg, var(--color-text) 0) 0    100%,
          conic-gradient(from -90deg at bottom $borderThickness right $borderThickness, #0000 90deg, var(--color-text) 0) 100% 100%;
        background-size: 30px 30px;
        background-origin: border-box;
        background-repeat: no-repeat;
  
        span {
          font-size: .9rem;
        }
      }
  
      .insurance-pictures-link {
  
        width: 250px;
        margin: 2rem auto;
        display: block !important;
      }

    }
  }
}