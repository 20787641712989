/* Functional Component styles */
.input-card-wrapper {
  border-style: solid;
  border-width: var(--form-input-border-width);
  border-color: var(--form-input-border-color);
  border-radius: var(--form-input-border-radius);
  transition: 150ms;
  margin: var(--form-input-wrapper-margin);
  padding: var(--form-input-padding);

  label {

    top: -0.75rem;
    z-index: 1;
    padding: 0 10px;
    left: 0.5rem;

    &::after{
      content: "";
      height: 0.3rem;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff;
      z-index: -1;
    }

  }

  &[is-focused] {
    border-color: var(--color-light-main);

    label {
      color: var(--color-main);
    }
  }
}