div#vehicle-data-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;

	section.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;

		h1, p.info {
			text-align: center;
		}

		h1 {
			margin-top: 30px;
			margin-bottom: 10px;
		}

		div.vehicle-management-container {
			margin-top: 50px;
			max-width: 700px;
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;

			h1, p {
				margin: 0;
			}

			p.text {
				margin-bottom: 15px;
			}

			div.vehicle-management-container {
				margin-top: 20px;
			}
		}
	}
}