@import "/src/styles/v1/variables/screen/screen.scss";

.planCoverages {

  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 1px 1px 10px var(--color-light-grey);
  padding: 1rem;
  margin: 2rem;

  &__header {
    height: 70px;
    margin: 0 2rem;

    &__vehicle {

      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }

      h1 {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-left: 1rem;
      }
    }

    &__toggle {

      background-color: var(--color-light-grey);
      border-radius: 50%;
      width: 45px;
      height: 45px;
      transition: 150ms;
      align-self: center;

      &[is-expanded] {
        transform: rotate(180deg);
      }

      svg {
        width: 35px;
        height: 35px;
      }
    }

    @media only screen and (max-width: $mobileWidth) {

      margin: 0;
      height: 50px;

      &__vehicle {

        img {
          display: none;
        }

        h1 {
          font-size: 1rem;
          margin-left: 0;
          max-width: 165px;
        }
      }

      &__toggle {
        width: 35px;
        height: 35px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  &__content {

    &--border {
      border-top: 2px solid var(--color-light-grey);
      margin-top: .5rem;
    }

    &__item {
      margin: 2rem;
      padding: 0 1rem;

      &__image {
        align-items: baseline;

        div {

          background-color: #F2F2F2;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &__values {

        margin-left: 2rem;

        &__name {
          

          h4 {
            font-weight: 600;
          }

          &__toggle {
            width: 100px;
          }
        }


        & > ul {
          margin-top: 1rem;

          li {
            margin-bottom: 1rem;

            span {
              align-items: baseline;
              svg {
                color: var(--color-main);
                width: 25px;
                height: 25px;
              }
            }

            p {
              margin-left: .5rem;
            }
          }
        }

        &__customization {

          max-width: 320px;
        }
      }

      
      @media only screen and (max-width: $mobileWidth) {

        margin: 2rem 0;
        padding: 0;

        &__image {

          display: none;
        }

        &__values {
          margin-left: 0;

          &__name {

            h3 {
              font-size: 1.1rem;
            }
          
            h4 {
              margin-right: .5rem;
              font-size: .9rem;
            }
  
            &__toggle {
              width: 100px;
            }
          }

          ul {

            li {
              span {
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              p {
                font-size: .9rem;
              }
            }
          }
        }
      }
    }

    *[element-disabled] {

      opacity: .4;
      pointer-events: none;
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    margin: 1rem 0;
  }
}