/* Functional Component styles */
.summary-section-wrapper {

  margin: 1rem 0 2rem;

  .summary-toggle {
    
    h3 {

      font-size: 1.3rem;
    }

    span {
      height: 4px;
      background-color: var(--color-light-grey);
      margin: 0 2rem;
    }

    svg {
      height: 35px;
      width: 35px;
      transition: transform 150ms;
    }

    &[is-expanded] {
      svg {
        transform: rotate(180deg);
      }
      
    }

    &:hover{
      cursor: pointer;
    }
  }

  .summary-plan-data{

    padding: 0 1rem;
  }
}