/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.policy-completion-wrapper {

  height: calc(100vh - var(--header-height));

  img {
    max-width: 150px;
    max-height: 150px;
    min-width: 90px;
    min-height: 90px;
    width: 15vw;
    height: 15vw;
  }


  h1 {
    color: var(--color-main);
    font-size: 1.5rem;
    margin: 15px 0;
  }

  h3, h2 {
    font-weight: normal;
    max-width: 450px;
    font-size: 1.1rem;
  }

  h2 {
    margin: 5px 0;

    span {
      font-weight: bold;
    }
  }

  h3 {
    margin: 35px 0;
    font-weight: 600;
  }

  .policy-completion-preview {
    border: 2px solid transparent;
    padding: 0.8rem;
    margin: 10px auto;
    font-weight: bold;
    font-size: 1.5rem;
    font-style: italic;
  }

  .button-wrapper {
    max-width: 250px;
    margin: 20px auto;
  }

  @media only screen and (max-width: $mobileWidth) {
    
    height: 100%;
    padding: 0 2rem;

    h3 {
      margin: 25px auto;
    }

    .policy-completion-preview {
      margin: auto;
    }
  }
}