/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.claimPersonManagement {

  height: 100%;
  @include theme.flexbox-column;

  &__header {
    min-height: 80px;
    height: 80px;
    @extend %bg-color-white;
    @include theme.flexbox-content-center;

    &__left,
    &__right {
      width: 80px;
      height: 100%;
      @include theme.flexbox-content-center;
    }

    &__progress {

      height: 8px;
      border-radius: 10px;
      margin: auto;
      position: relative;
      @include theme.flex(1);
      @extend %bg-color-light-grey;

      &__bar {
        height: 100%;
        transition: width 500ms;
        position: absolute;
        border-radius: 10px;
        @extend %bg-color-main;
      }
    }
  }

  &__content {

    &__form, &__wrapper, &__wrapper__form {
			margin-bottom: 30px;

			&:last-of-type {
				margin-bottom: 100px;
			}
      
			&__title {
				padding: 10px 0;
				margin-bottom: 40px;
				border-bottom-width: 1px;
				border-bottom-style: solid;
				@include theme.flexbox-content-vertical-center;
				@extend %border-color-light-grey;
	
				span {
					display: block;
					width: 10px;
					height: 10px;
					margin-right: 10px ;
					border-radius: 50%;
					@extend %bg-color-green;
				}
	
				&__text {
					@include theme.flex(1);
				}
	
				button {
					@extend %text-color-main;
	
					svg {
						width: 25px;
						height: 25px;
					}
				}
			}

      &__subtitle {
        margin-top: 30px;

        &:first-of-type {
          margin-top: 15px;
        }
      }

			&__damage, &__service {
        padding: 5px 10px;
        @extend %bg-color-white;
        @include theme.shadow;
        @include theme.flexbox-content-vertical-center;

        &__image {
          @include theme.background-image(60px, 60px);
        }

        &__type {
          padding: 0 10px;
          margin-left: 10px;
          @include theme.flex;
        }

        &__buttons {

          &__edit {
            @extend %text-color-main;
          }

          &__remove {
            margin-left: 15px;
            @extend %text-color-red;
          }
          
          button {
            
            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      &__field {
        margin: 25px 0;

        &--center {
          @include theme.flexbox-content-center;
        }
      }

      &--wrap {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 190px);

        &>* {
          margin: 15px;
        }
      }

			&__form {

				&--shortMargin {
					margin-bottom: 10px;
				}
			}
    }

    &__panel {
      @include theme.flexbox;
      margin-top: 30px;
      gap: 20px;
      flex-wrap: wrap;

      &__section {
        @include theme.shadow;
        border-radius: 8px;
        flex: 1 1 45%;
        padding: 5px;
        cursor: pointer;

        &__titleWrapper {
          @include theme.flexbox;
          padding: 15px 0 0 0;

          &__position {
            width: 30px;
            margin: 0 15px 0 5px;

            h1 {
              @include theme.font-size(23);
              line-height: 1;
            }
          }

          &__title {

            h1 {
              @include theme.font-size(-2);
              font-weight: 400;
              line-height: 1;

              &:last-of-type {
                @include theme.font-size(4);
                font-weight: 600;
              }
            }
          }
        }

        &__image {
          margin: 5px 0;
          text-align: center;

          span {
            @include theme.background-image(70px, 70px);
            width: 70px;
            height: 70px;
          }
        }

        &__bottom {

          &__edit {
            border-top: 1px solid #e1e1e1;
            padding: 7px 0;
          }
        }

        &--disable {
          opacity: .4;
          pointer-events: none;
        }
      }
    }

    &__syncEDUA {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #ccc;

      &__button {
        @include theme.flexbox-content-center;
        @extend %bg-color-main;
        @extend %text-color-white;
        box-shadow: 1px 1px 5px #858585;
        gap: 20px;
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        height: 55px;

        &__spinner {
          position: relative;
          height: 40px;
          width: 40px;

          svg {
            margin-top: 0;
            bottom: 18px;
            height: 35px;
            width: 35px;
            color: #fff;
          }
        }

        &--disabled {
          opacity: .4;
          pointer-events: none;
        }
      }

      svg {
        @include theme.font-size(20);
        margin-bottom: 2px;
      }

      p {
        @include theme.font-size(4);
      }
    }

    &__info {
      @include theme.flexbox;
      padding: 0 10px 10px;
      border-radius: 7px;
      margin-bottom: 5px;

      &__warningImage {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: block;
        min-width: 60px;
        margin-right: 20px;
      }
    }

    &__form {

      &__pictures-wrapper {
        min-height: 100px;
        @include theme.flexbox;
        @include theme.flex-wrap(wrap);

        &__item {
          height: 50px;
        }
      }

      &__info {
        padding: 15px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        @extend %border-color-light-grey;
        @include theme.flexbox-content-vertical-center;

        &__right {

          margin-left: 10px;
        }
      }

      &__add-button {
        margin: 20px 0;
        @include theme.flexbox-column-content-center;

        button {

          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-bottom: 10px;
          @extend %text-color-white;
          @extend %bg-color-green;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    &__wrapper {

      &__person {
				margin: 20px 0;
        padding: 5px 10px;
        @extend %bg-color-white;
        @include theme.shadow;
        @include theme.flexbox-content-vertical-center;

				&__status {
					display: block;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					margin: 0 15px 0 5px;

					&--red {
						@extend %bg-color-red;
					}
				}

        &__image {
          @include theme.background-image(60px, 60px);
        }

        &__type {
          @include theme.flex;
          padding: 0 10px;
          margin-left: 10px;

          p {
            max-width: 190px;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            overflow: hidden;
          }
        }

        &__buttons {

          &__edit {
            @extend %text-color-main;
          }

          &__remove {
            margin-left: 15px;
            @extend %text-color-red;
          }

					&__add {
						padding: 7px 10px;
						border-radius: 7px;
						@extend %text-color-white;
						@extend %bg-color-main;

						&[disabled] {
							opacity: 0.4;
						}
					}
          
          button {
            
            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

			&__eSign {
				margin: 40px 0 0;
			}

      &__add-button {

        margin: 10px 0;
        border-radius: 5px;
        @include theme.shadow;
        @extend %bg-color-white;
        
        button {
          width: 100%;
          padding: 15px 10px;
          transition: 150ms;
          box-sizing: border-box;
          @include theme.flexbox-content-center;
          
          &:active {
            @extend %bg-color-light-grey;
          }

          svg {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
        }
      }

			&__signatureText {
				margin: 30px 0;
				span {
					display: block;
				}
			}

      &--listMargin {
        margin-bottom: 165px;
      }
    }

    &__button-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      @include theme.top-shadow;
      z-index: 1;

      & > button {
        max-width: 275px;
        margin: auto;
        display: block;
      }

			&__warningInfo {
				margin: 0 0 15px;

				&__wrapper {
					@include theme.flexbox;
					@include theme.justify-content(space-around);
					@include theme.align-items(center);
					width: 80%;
					margin: 0 auto;

					&__icon {
						@include theme.font-size(15);
					}
				}
			}

      &__info {
        margin-top: 15px;

				&__wrapper {
					@include theme.flexbox;
					@include theme.justify-content(space-around);
					margin: 20px 0;

					&__item {
						@include theme.flexbox;

						&__status {
							display: block;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							margin-right: 5px;

							&--red {
								@extend %bg-color-red;
							}

							&--yellow {
								@extend %bg-color-yellow;
							}

							&--green {
								@extend %bg-color-green;
							}
						}
					}
				}

        button {
          @extend %text-color-main;
          font-weight: bold;
        }
      }
    }

    &__involvedCreation {
      margin-top: 50%;

      button {
        padding: 25px;
        margin: 10px 0;

        span {
          @include theme.font-size(2);
        }
      }
    }
  }
}