/* Functional Component styles */
#support-wrapper {

  h2 {
    font-size: 0.8rem;
    font-weight: 500;
  }

  & > div {

    border-radius: 10px;
    border: 2px solid var(--color-red);
    padding: 0.2rem;

    a, span, svg {
      display: inline-block;
      vertical-align: middle;
      color: var(--color-red);
    }
  
    a, span {
      border-color: var(--color-red);
      margin-left: 5px;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

  }


}