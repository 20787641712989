/* Functional Component styles */
@use '/src/styles/v2/index' as theme;

.deleteItemModal {
	
	&__content {
		padding: 10px 30px;

		&__buttonWrapper {
			@include theme.flexbox-content-center;
			margin: 30px 0 0;
			
			button {
				width: 130px;
				max-width: 150px;
				margin: 0 10px;
			}
		}
	}
}