/* Functional Component styles */
#referrals-wrapper {

  padding: 1rem;


  @media only screen and (max-width: 500px) {
  
    padding: 1rem 0 4rem;
  }
}