/* Functional Component styles */

.summary-item-wrapper{

  margin: 1rem 0;

  &, & > .summary-item-toggle > div.width-100.flex {
    border-radius: 8px;
    box-shadow: 0 0 10px var(--color-light-grey);
  }
  
  & > section {

    &.summary-item-toggle {

      transition: 50ms;
      
      .summary-item-name{
  
        background-color: var(--color-main);
        padding: .0 .5rem;
        border-radius: 8px;
        width: 275px;
  
        .background-image {
          width: 50px;
          height: 50px;
        }
  
        h3 {
          font-size: 1rem;
          color: #fff;
          margin: 0 15px;
        }

        @media only screen and (max-width:500px) {
          width: 250px;

          h3 {
            margin: 0;
          }
        }
      }

      .summary-coverage-details {
        margin-left: 20px;
        
        p{
          color: var(--color-light-main);
          font-weight: 500;
        }
      }
  
      aside {
  
        padding: 0 .5rem;
  
        svg {
  
          width: 40px;
          height: 40px;
          transition: transform 150ms;
        }
        
        &[is-expanded] {
          svg {
            transform: rotate(180deg);
          }
          
        }
      }

      & > .summary-coverage-details {
        padding: 1rem;
        display: none;
        text-align: center;
      }

      &:hover {
        cursor: pointer;
        background-color: #f1f1f1;
      }

      @media only screen and (max-width:850px) {
        
        & > div .summary-coverage-details {
          display: none;
        }

        & > .summary-coverage-details {
          display: flex;
        }

        &:hover {
          background-color: inherit;
        }
      }
    }

    &.summary-item-content{

      h4:nth-of-type(1) {
        color: var(--color-red);
      }
      h4:nth-of-type(2) {
        color: var(--color-green);
      }

      h4 {
        font-size: .9rem;
        margin-top: 10px;
      }

      ul:not(.flex) {
        padding: 0 1rem;

        li {
          font-size: .9rem;
          margin: 2.5px 0;

          span::before{
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            position: absolute;
            background-color: var(--color-text);
            left: -10px;
            top: 7.5px;
          }
        }
      }

      ul:not(.flex):last-of-type{
        padding-bottom: 1rem;
      }

      ul.flex {

        flex-wrap: wrap;
        max-width: 650px;
        margin: auto;

        li {
          margin: 1rem;
          width: 170px;
        }

        @media only screen and (max-width:500px) {

          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

