@import "/src/styles/v1/variables/screen/screen.scss";

div#onboarding-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	h1 {
		margin-bottom: 50px;

		@media only screen and (max-width: 600px) {
			text-align: center;
			margin-bottom: 20px;
			font-size: 1.9rem;
		}
	}

	h3 {
		margin-bottom: 10px;
		width: 100%;
		text-align: center;
	}

	ul {
		width: 50%;

		li {
			margin: 20px 0;
			display: flex;
			justify-content: center;
    	align-items: center;

			svg {
				background-color: #32BA7C;
				padding: .1rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				margin-right: 10px;
			}

			span {
				width: calc(100% - 40px);
			}
		}

		@media only screen and (max-width: $mobileWidth) {
			width: 90%;
			max-width: 500px;
		}
	}

	p {
		text-align: center;
	}

	div.crabeler-option {
		height: 50px;
    border-top: 2px solid #c8c8c8;
		display: flex;
    align-items: center;
    justify-content: space-between;
		min-width: 340px;
		padding: 0 20px;
		cursor: pointer;

		div {
			width: 20px;
			height: 20px;
			border: 1px solid #c2c2c2;
			border-radius: 50%;

			svg {
				visibility: hidden;
				width: 1.5em;
				height: 1.5em;
			}

			&.active {
				background-color: #32BA7C;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					visibility: visible;
				}
			}
		}

		&:first-of-type {
			margin-top: 50px;
		}

		&:last-of-type {
			margin-bottom: 100px;
		}

		&.active {
			font-weight: bold;

			div {
				background-color: #32BA7C;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					visibility: visible;
				}
			}
		}

		@media only screen and (max-width: 420px){
			min-width: 280px;

			&:last-of-type {
				margin-bottom: 40px;
			}
		}
	}

	button.start-button {
		max-width: 200px;
		font-size: 1rem;
		height: 40px;

		@media only screen and (max-width: 600px){
			width: 80%;
			max-width: 200px;
			position: fixed;
			bottom: 20px;
		}
	}

	div.bottom-wrapper {
		display: flex;
		width: 100%;
		margin-top: 50px;

		div {
			width: 50%;
			display: flex;
			align-items: center;

			&.left-side {
				flex-direction: column;
				align-items: end;

				h4 {
					width: 300px;
					margin: 0 0 0 auto;
				}
	
				div.info {
					font-size: .8rem;
					width: 300px;
					margin: 0 0 0 auto;

					@media only screen and (max-width: $mobileWidth){
						font-size: .9rem;
					}
				}
			}

			button {
				margin-left: 50px;
			}
		}

		@media only screen and (max-width: 600px){
			flex-direction: column;
			margin-top: 20px;

			div {
				width: 100%;
				align-items: center;
				justify-content: center;

				h4, div.info {
					margin: auto !important;
					text-align: center;
				}
			}

			div.button-wrapper {
				
				button {
					width: 80%;
					margin: 10px auto 20px;
					max-width: 80%;
					font-size: 1.2rem;
					height: 50px;
				}
			}
		}
	}

	div.button-wrapper {
		background-color: #fff;

		@media only screen and (max-width: $mobileWidth) {
			position: fixed;
			bottom: 0;
			height: 80px;
		}

		button {
			max-width: 200px;
			height: 40px;
			letter-spacing: 0;
		}

		@media only screen and (max-width: $mobileWidth) {
			position: fixed;
			bottom: 0;
		}
	}
}