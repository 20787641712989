/* Functional Component styles */
.password-security-message {

  margin: 5px 0;

  & > p {
    
    &, span {
      color: var(--color-main);
    }
  }

  p {
    
    span {
      font-weight: bold;
    }
  }

  ul {
    margin-left: 10px;

    li {
      padding: .25rem;
      font-size: .8rem;
      list-style-type: disc;
      margin-left: 20px;
    }
  }
}