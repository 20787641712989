/* Functional Component styles */
#recovery-wrapper {

  background: var(--login-background);
  background-size: var(--login-background-size);
  height: calc(100vh - var(--header-height));

  form, .email-sended-wrapper {

    max-width: var(--login-content-wrapper-max-width);
    margin: var(--login-content-wrapper-margin);
    padding: var(--login-content-wrapper-padding);

    .recovery-image{
      width: 120px;
      height: 120px;
      display: block;
      margin: auto;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 2rem;

      margin-bottom: 1rem;
    }

    h2 {
      font-size: 1.4rem;
      font-weight: normal;
      margin: 1.5rem 0;

      span {
        font-weight: bold;
      }
    }

    & > p {
      font-size: 1.1rem;
      font-weight: normal;
      margin-bottom: 1rem;

      i {
        font-weight: 600;
      }
    }

    .form-section {
      margin: 2.5rem 0;
    }

    .button-wrapper {

      p {
        margin: 1rem 0;

        a {
          color: var(--color-main);
          margin-left: 5px;
        }
      }
    }
  }
}