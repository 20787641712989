/* Functional Component styles */
#security-wrapper{

  padding: 1rem;
  box-sizing: border-box;

  h1 {
    margin-bottom: 2rem;
  }
  
  .change-password-wrapper {
    margin: auto;
    max-width: 700px;

    & > form {

      margin-bottom: 1rem;
    }

    .button-wrapper {
      margin: 30px 0;

      button {
        max-width: 250px;
      }

      @media only screen and (max-width: 450px) {

        justify-content: center;
      }
    }
  }


}