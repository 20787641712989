@use '/src/styles/v2/index.scss' as theme;

.documentWrapper {

  margin-top: 10px;

  .react-pdf__Document {

    width: 300px;
    height: 300px;
    margin: auto;
    @include theme.flexbox-column-content-center;
  }

  .react-pdf__Page {
    max-width: calc(100% - 2em);
    margin-top: 10px;
    @include theme.bottom-right-shadow;
    overflow: hidden;
    cursor: pointer;
  }

  &__link {

    margin-top: 15px;

    a {
      @extend %text-color-main;
    }
  }
}