/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policy-details-wrapper {

  padding: 1rem 0 0;

  .policy-details {

    h2 {
      font-size: 1.2rem;
    }

  }

  @media only screen and (max-width: 610px) {
    
    .policy-details {

      h1 {
        padding-top: 3rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      padding-bottom: 120px;
    }
  }

  .policy-vehicle-details .vehicle-item, .policy-status-details p {
    margin: 5px 0;
  }

  .policy-details-vehicle-picture-wrapper {
    border-bottom: 1px solid #f0f5ff;
    border-width: 2px;
    height: 150px;

    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    & .add-picture {
      //FIXME Eliminar cuando exista subida de foto
      height: 100%;
      width: 100%;
      max-width: inherit;
      background-position: center 85%;
      background-size: 750px;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .policy-vehicle-details, .policy-status-details {
    margin: 15px;
    flex: 1;
  }

  .policy-vehicle-details {

    border-right: var(--dashboard-border-style);

    .vehicle-item {

      min-width: 150px;

      h3 {
        font-size: 1rem;
      }
    }
  }

  .policy-status-details{

    h2 {
      margin: 10px 0 0;
    }

    .policy-card-renew{
      font-size: .75rem;

      &[hide-renew] {
        display: none;
      }
    }

    & > div {
      margin-top: 5px;
    }

    & > div, p {

      & > span {
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }

  .policy-coverages-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 700px;
    margin: auto;

    .coverage-item {

      margin: 10px 0px;

      .coverage-item-value {
        margin-left: 35px;
      }

      h3, p {
        font-size: 0.9rem;
      }

      h3 {
        
        .coverage-status {
          background-color: var(--color-green);
          border-radius: 50%;
          color: #fff;
          min-width: 25px;
          height: 25px;
          font-size: 1.4rem;
          margin: 0 5px;

          &.excluded{
            background-color: var(--color-red);
            font-size: 1.2rem;
          }
        }
      }
    }

    @media only screen and (max-width: 610px) {
      grid-template-columns: 1fr;
      width: 320px;
      margin-bottom: 50px;

      .coverage-item {
        border-bottom: 1px solid #bbb;
        padding: 1rem;
        position: relative;

        h3, p {
          font-size: 1rem;
        }

        .coverage-item-value, h3 span:not(.coverage-status) {
          margin-left: 55px;
        }

        h3 .coverage-status {
          position: absolute;
          top: 0;
          bottom: 10px;
          margin: auto;
          width: 30px;
          height: 30px;
        }
      }

      .coverage-item:last-of-type {
        border-bottom: none;
      }
    }
  }

  & section.flex {

    @media only screen and (max-width: 610px) {
      flex-direction: column;

      .policy-status-details {
        order: -1;

      }
      .policy-vehicle-details, .policy-status-details {
        margin: 5px 0;
      }
    }
  }

  footer {
    padding: 0 2rem;
    box-sizing: border-box;

    &[actions-disabled] > * {
      opacity: .5;
      pointer-events: none;
    }

		button.policy-management-btn {
			margin: 1rem;
			font-weight: bold;
			font-family: inherit;
		}

    @media only screen and (max-width: 550px) {
      padding: 1rem;
      height: auto;
      display: flex;
      flex-direction: column;

      & > button, & > div {
        margin: 5px 0;
      }
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    .renewal-badge{
      top: 0;
      right: 0 !important;

      &::after, &::before {
        display: none;
      }
    }
  }
}