//
@mixin sidebar-dimensions {
  box-sizing: border-box;
  top: $tobarHeight;
  height: calc(100vh - $tobarHeight);
}

@mixin content-height {
  height: calc(100vh - $tobarHeight);
}

@mixin topbar-dimensions {
  height: $tobarHeight;
}

@mixin sidebar-default-width {
  min-width: $sidebarWidth;
  width: $sidebarWidth;
  transition: 300ms;
}

@mixin sidebar-expanded-width {
  width: calc($sidebarWidth * 3.5);
  min-width: calc($sidebarWidth * 3.5);
}

@mixin sidebar-action-margin {
  margin-left: 13px;
}
