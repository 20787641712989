//
@mixin shadow($blur: 10px) {
  box-shadow: 0px 0px $blur $box-shadow-color;
}


//
@mixin bottom-shadow($blur: 6px) {
  $offset: $blur;
  box-shadow: 0px $offset $blur $box-shadow-color;
}


//
@mixin bottom-right-shadow($blur: 8px) {
  $offset: calc($blur / 2);
  box-shadow: $offset $offset $blur $box-shadow-color;
}

//
@mixin top-shadow($blur: 6px) {
  $offset: $blur;
  box-shadow: $offset 0px $blur $box-shadow-color;
}