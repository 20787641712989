@import "/src/styles/v1/variables/screen/screen.scss";

.contractingButton{

  position: fixed;
  z-index: 99999;
  top: .9rem;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 340px;

  display: none;

  &--visible {
    display: block;
  }

  &__button {
    box-sizing: border-box;
    max-width: 320px;
    text-transform: uppercase;
    font-size: .8rem;

    &__normal {
      font-weight: 400;
    }

    &--large {
      max-width: 340px;
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    top: inherit;
    bottom: 2rem;

    &__button {
      padding: 1rem;
    }
  }
}