/* Functional Component styles */
.menu-link-wrapper {

  padding: .75rem 0;

  span {

    margin-left: 0.5rem;
    font-size: 0.9rem;
    transition: 100ms;
    transform-origin: left;
  }

  &::before {
    content: "";
    background-color: var(--color-main);
    width: 6px;
    height: 75%;
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
    
    visibility: hidden;
    transform: scale(0);
    transition: 150ms;
  }


  &.selected {

    span {
      transform: scale(1.1);
      font-weight: bold;
      letter-spacing: .5px;
    }

    &::before {
      visibility: visible;
      transform: scale(1);
    }
  }
}