/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.claimPersonSync {

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__info, &__subtitle {
      margin: 20px 0;
      font-weight: 400;
    }

    &__subtitle {
      margin: 40px 0 0;
    }

    &__buttons {
      margin-top: 40%;

      button {
        padding: 25px;
        margin: 10px 0;

        span {
          @include theme.font-size(2);
        }
      }
    }

    &__wrapper {
      @include theme.flexbox;
      @include theme.flexbox-column-content-center;
      @include theme.shadow;
      border-radius: 10px;
      padding: 20px;
      margin: 20px 0;

      &:first-child {
        margin-top: 50px;
      }

      &__value {
        margin-top: 10px;
      }

      &__qr {
        @include theme.flexbox-content-center;
        @extend %bg-color-light-grey;
        width: 250px;
        height: 250px;
        margin: 20px 0;
        border-radius: 10px;
        overflow: hidden;

        &__qrIcon {
          position: absolute;
          z-index: 10;
          @extend %text-color-main;
          @include theme.font-size(70);
        }
      }

      &__qrText {
        margin: 0 0 20px 0;
      }

      &__scanButton {
        max-width: 300px;
        margin: 10px 0;
        padding: 15px;
      }

      &__syncButton {
        @include theme.flexbox-content-center;
        @extend %bg-color-main;
        @extend %text-color-white;
        box-shadow: 1px 1px 5px #858585;
        gap: 20px;
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        height: 55px;
        cursor: pointer;

        &__spinner {

          svg {
            bottom: 18px;
            height: 35px;
            width: 35px;
          }
        }

        &--disabled {
          opacity: .4;
          pointer-events: none;
        }

        svg {
          @include theme.font-size(20);
          margin-bottom: 2px;
        }
  
        p {
          @include theme.font-size(4);
        }
      }
      
      &--centered {
        text-align: center;
      }

      &--horizontal {
        flex-direction: row;
      }

      &__warningInfo {
        height: 50px;
        width: 50px;
        background-position: center;
        background-size: cover;
      }

      &__warningText {
        flex: 1;
        text-align: left;
        margin-left: 20px;
      }
    }

    &__results {
      margin: 40px 0;
      text-align: center;

      &__checkIcon {
        @extend %text-color-green;
        @include theme.font-size(80);
        margin: 0 auto;
      }
    }

    &__button {
      padding: 15px;

      &--sipac {
        margin: 30px auto;
        max-width: 300px;
      }
    }
  }
}