.flex {

  display: flex;

  &.column {
    flex-direction: column;
  }
}

.inline-flex {
  display: inline-flex;

  &.column {
    flex-direction: column;
  }
}

.flex, .inline-flex, .column {

  &.flex-wrapped {
    flex-wrap: wrap;
  }

  &.align-center {
    align-items: center;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-flex-end {
    justify-content: flex-end;
  }

  &.justify-flex-start {
    justify-content: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

}
// Flex-grow
$step: 5;
$loops: 20;
@for $i from 1 through $loops{
    .flex-grow-#{calc($i*$step)}{
      flex-grow: #{calc($i*$step/100)};
      flex-basis: 0;
    }
}