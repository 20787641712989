@import "/src/styles/v1/variables/screen/screen.scss";

div#bank-account-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;

	section.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	
		h1 { 
			margin: 0 0 40px;
		}
	
		p {
			width: 80%;
			text-align: center;
			margin: 20px 0 0;
		}

		div.bank-handler-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80%;
			margin-top: 50px;

			div.bank-tutorial {
				width: calc(100% - 250px);
				height: 250px;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				margin-right: 50px;
				position: relative;
				
				&::after {
					content: '';
					width: 2px;
					height: 100%;
					position: absolute;
					right: -10px;
					background-color: #e3e3e3;
				}
			}

			@media only screen and (max-width: $mobileWidth) {
				flex-direction: column;

				div.bank-tutorial {
					width: 100%;
					margin: 0 0 20px;

					&::after {
						display: none;
					}
				}
			}

			@media only screen and (max-width: 500px) {

				div.bank-tutorial {
					height: 200px;
				}
			}
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;
	
			h1, p {
				margin: 0 0 10px;
			}

			h1 {
				padding: 0 30px;
			}
	
			p {
				margin-bottom: 15px;
				font-size: .9rem;
			}

			h1,h3,p {
				text-align: center;
			}

			div.bank-handler-wrapper {
				margin-top: 10px;
			}
		}
	}

	@media only screen and (max-width: $mobileWidth) {
		overflow: initial;
		padding-bottom: 80px;
	}

	@media only screen and (max-width: 400px) {

		section.content {

			h1 {
				margin: 0 0 20px;
			}

			p {
				width: 90%;
				margin: 10px 0 20px;

				&:last-of-type {
					margin-bottom: 0px;
				}
			}
		}
	}
}