/* Functional Component styles */
.policy-cancelled-prompt-wrapper {

  max-width: 400px;
  padding: 1rem;

  & > span {

    width: 70px;
    height: 70px;
    margin: 0 auto 1rem;
  }

  h1 {
    font-size: 1.3rem;
    font-weight: 600;
  } 

  h2 {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 2rem;
  }

  button {

    max-width: 300px;
    margin: 2rem auto 0;
  }

  @media only screen and (max-width: 450px) {
    max-width: 330px;
  }
}