/* Functional Component styles */
@use '/src/styles/v2/index.scss' as theme;

.claimPersonManagement {

  height: 100%;
  @include theme.flexbox-column;

  &__header {
    min-height: 80px;
    height: 80px;
    @extend %bg-color-white;
    @include theme.flexbox-content-center;

    &__left,
    &__right {
      width: 80px;
      height: 100%;
      @include theme.flexbox-content-center;
    }

    &__progress {

      height: 8px;
      border-radius: 10px;
      margin: auto;
      position: relative;
      @include theme.flex(1);
      @extend %bg-color-light-grey;

      &__bar {
        height: 100%;
        transition: width 500ms;
        position: absolute;
        border-radius: 10px;
        @extend %bg-color-main;
      }
    }
  }

  &__content {

    &__form, &__wrapper, &__wrapper__form {

			&:last-of-type {
				margin-bottom: 100px;
			}

      &__warningText {
        display: block;
        margin: 0 auto;
      }
      
			&__title {
				padding: 10px 0;
				margin-bottom: 40px;
				border-bottom-width: 1px;
				border-bottom-style: solid;
				@include theme.flexbox-content-vertical-center;
				@extend %border-color-light-grey;
	
				span {
					display: block;
					width: 10px;
					height: 10px;
					margin-right: 10px ;
					border-radius: 50%;
					@extend %bg-color-green;
				}
	
				&__text {
					@include theme.flex(1);
				}
	
				button {
					@extend %text-color-main;
	
					svg {
						width: 25px;
						height: 25px;
					}
				}
			}

			&__damage, &__service {
        padding: 5px 10px;
        @extend %bg-color-white;
        @include theme.shadow;
        @include theme.flexbox-content-vertical-center;

        &__image {
          @include theme.background-image(60px, 60px);
        }

        &__type {
          padding: 0 10px;
          margin-left: 10px;
          @include theme.flex;
        }

        &__buttons {

          &__edit {
            @extend %text-color-main;
          }

          &__remove {
            margin-left: 15px;
            @extend %text-color-red;
          }
          
          button {
            
            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      &__field {
        margin: 25px 0;

        &--center {
          @include theme.flexbox-content-center;
        }
      }

      &--wrap {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 190px);

        &>* {
          margin: 15px;
        }
      }

			&__form {

				&--shortMargin {
					margin-bottom: 10px;
				}
			}
    }

    &__form {

      &__pictures-wrapper {
        min-height: 100px;
        @include theme.flexbox;
        @include theme.flex-wrap(wrap);

        &__item {
          height: 50px;
        }
      }

      &__info {
        padding: 15px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        @extend %border-color-light-grey;
        @include theme.flexbox-content-vertical-center;

        &__right {

          margin-left: 10px;
        }
      }

      &__add-button {
        margin: 20px 0;
        @include theme.flexbox-column-content-center;

        button {

          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-bottom: 10px;
          @extend %text-color-white;
          @extend %bg-color-green;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    &__wrapper {

      &__person {
				margin: 20px 0;
        padding: 5px 10px;
        @extend %bg-color-white;
        @include theme.shadow;
        @include theme.flexbox-content-vertical-center;

				&__status {
					display: block;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					margin: 0 15px 0 5px;

					&--red {
						@extend %bg-color-red;
					}
				}

        &__image {
          @include theme.background-image(60px, 60px);
        }

        &__type {
          padding: 0 10px;
          margin-left: 10px;
          @include theme.flex;
        }

        &__buttons {

          &__edit {
            @extend %text-color-main;
          }

          &__remove {
            margin-left: 15px;
            @extend %text-color-red;
          }

					&__add {
						padding: 7px 10px;
						border-radius: 7px;
						@extend %text-color-white;
						@extend %bg-color-main;
					}
          
          button {
            
            svg {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

			&__eSign {
				margin: 40px 0 0;
			}

      &__add-button {

        margin: 10px 0;
        border-radius: 5px;
        @include theme.shadow;
        @extend %bg-color-white;
        
        button {
          width: 100%;
          padding: 15px 10px;
          transition: 150ms;
          box-sizing: border-box;
          @include theme.flexbox-content-center;
          
          &:active {
            @extend %bg-color-light-grey;
          }

          svg {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
        }
      }

			&__signatureText {
				margin: 30px 0;
				span {
					display: block;
				}
			}
    }

    &__button-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      @include theme.top-shadow;
      z-index: 1;

      & > button {
        max-width: 275px;
        margin: auto;
        display: block;
      }

			&__warningInfo {
				margin: 0 0 15px;

				&__wrapper {
					@include theme.flexbox;
					@include theme.justify-content(space-around);
					@include theme.align-items(center);
					width: 80%;
					margin: 0 auto;

					&__icon {
						@include theme.font-size(15);
					}
				}
			}

      &__info {
        margin-top: 15px;

				&__wrapper {
					@include theme.flexbox;
					@include theme.justify-content(space-around);
					margin: 20px 0;

					&__item {
						@include theme.flexbox;

						&__status {
							display: block;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							margin-right: 5px;

							&--red {
								@extend %bg-color-red;
							}

							&--yellow {
								@extend %bg-color-yellow;
							}

							&--green {
								@extend %bg-color-green;
							}
						}
					}
				}

        button {
          @extend %text-color-main;
          font-weight: bold;
        }
      }
    }
  }
}