@import "/src/styles/v1/variables/screen/screen.scss";

/* Functional Component styles */
#claim-payments-wrapper {
	background-color: #fff;

	.sidebar-wrapper {
    min-width: 300px;
    height: calc(100vh - var(--header-height));
    position: sticky;
    top: var(--header-height);
		background-color: #fff;

		@media only screen and (max-width: 1399px){
			min-width: 280px;
		}

    footer {
      padding: 3rem 1rem;
    }
  }

  .claim-payment-content {
		flex: 1;
    border: var(--dashboard-border-style);
    border-radius: var(--dashboard-border-radius);
    margin: 20px;
		background-color: #fff;

    .picture-item {
      margin: 1rem;
    }

  }

	@media only screen and (max-width: $mobileWidth) {
		background-color: #fff;
		flex-direction: column;
    padding-bottom: 100px;

		.sidebar-wrapper {
			position: sticky;
			top: var(--header-height);
			height: 120px;
			background-color: #fff;
			z-index: 999;
      padding: 15px 5px 5px;
		}

		.claim-payment-content {
			margin: 0;
		}
	}
}