@import "/src/styles/v1/variables/screen/screen.scss";

:root {


  --dashboard-border-style: 1px solid #d6d6d6;
  --dashboard-border-radius: 30px;
  --dashboard-box-shadow: inherit;
}

@media only screen and (max-width: $mobileWidth) {

  :root {

    --dashboard-border-style: none;
    --dashboard-border-radius: 0px;
    --dashboard-box-shadow: 0 0 10px #eee !important;
  }
}