/* Functional Component styles */
#person-management-wrapper {

  form {

    .sections-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;

      & > div {
        margin: var(--policy-form-item-margin);

				&.last-item {
					order: 1;
				}
      }
    }

    .form-section {
      margin: var(--policy-form-section-margin);
    }

    .check-current-postal-code {
      margin: 0 15px;
      .checkbox-wrapper {
        font-size: .8rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {

    form {
      .sections-wrapper {
        grid-template-columns: 1fr;

        & > div {
          margin: 10px 0;
        }
      }
    }

  }
}