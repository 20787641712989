/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.init-wrapper {
  position: fixed;
  height: 100%;
  width: 100% !important;
  max-width: inherit !important;
  transform: scale(1.2);
  left: 20px;
  top: 0;
  z-index: 9999999;
  background-color: #fff;

  .asd {
    position: absolute;
    width: 100px;
    height: 80px;
    background-color: #fff;
    left: 50%;
    z-index: 2;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -100px;
    right: 0;
  }

  .svg-loader {
    transform: rotate(-90deg);
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
    margin-right: 13px;
    position: absolute;
    background-color: #fff;
    width: 200px;
    height: 200px;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      margin: auto;
      left: 10px;
      right: 0;
    }

    &.rotate {
      animation: .75s infinite linear svgLoader;
    }
    
    &.present {
      animation: .6s ease-out svgPresenter;
      animation-fill-mode: forwards;

      &::after {
        animation: .2s ease-out svgPresenterAfter;
        animation-fill-mode: forwards;
      }
    }

    @keyframes svgLoader {
      0% {
        transform: rotate(-90deg);
      }
      100% {
        transform: rotate(270deg);
      }
    }

    @keyframes svgPresenter {
      0% {
        transform: rotate(-90deg);
        background-color: rgba(#fff, 1);
      }

      25% {
        transform: rotate(0deg);
        background-color: rgba(#fff, 1);
      }

      100% {
        transform: rotate(0deg) translateX(-82px);
        background-color: rgba(#fff, 0);
      }
    }

    @keyframes svgPresenterAfter {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(0);
        opacity: 0;
      }
    }
  }

  .logo-letters-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 130px;
  }

  svg {
    transform: scale(1.5);

    &.letter-a {
      margin-right: 11px;
    }

    &.letter-i {
      margin-left: 3px;
      margin-bottom: 1px;
    }

    &.translation {

      &.letter-r, &.letter-a, &.letter-b, &.letter-i {
        animation: .3s ease-out letterTranslate;
      }

      &.letter-r {
        animation-delay: .25s;
      }

      &.letter-a {
        animation-delay: .2s;
      }

      &.letter-b {
        animation-delay: .15s;
      }

      &.letter-i {
        animation-delay: .1s;
      }
    }

    @keyframes letterTranslate {
      0% {
        transform: scale(1.5) translateX(-100px);
        opacity: .0;
      }
      100% {
        transform: scale(1.5) translateX(0);
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: $mobileWidth) {
    padding-top: 0 !important;
  }
}
