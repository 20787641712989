@import "/src/styles/v1/variables/screen/screen.scss";
/* Functional Component styles */

.back-button {

  padding: 0.8rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  align-self: flex-start;

  @media only screen and (max-width: $mobileWidth) {
		
		padding-left: 0px;
		
	}

  &[link-overlay] {
    position: absolute;
    z-index: 10;
  }

  svg, span {

    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 5px;
    font-size: 1.1rem;
  }

  &:hover {

    span {
      text-decoration: underline;
    }
  }

	@media only screen and (max-width: $mobileWidth) {
		svg {
			font-size: 1.3rem;
		}

		&.hide-text-on-mobile {
			padding-top: 0;

			span {
				display: none;
			}
		}
	}
}