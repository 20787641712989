/* Functional Component styles */
.login-email-validation-wrapper {

  padding: 1rem 2rem;

  & > button {
    background-color: transparent;
    border: none;
    padding: 0;
    top: 10px;
    right: 20px;
    font-size: 1.5rem;
  }

  span {
    width: 120px;
    height: 120px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
  }

  h2 {
    font-size: 1.2rem;
    margin: 1rem;
    font-weight: normal;
    padding: 0 2rem;
  }

  h3 {

    margin: 1rem;
    font-size: 1rem;
    font-weight: 200;
    
  }

  & > div.flex {

    width: 300px;
    margin: auto;

    p {
      font-size: .65rem;
      margin: .5rem 0;
    }

    button {
      margin: .75rem 0;
    }
  }

}