/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.session-wrapper {
  
  transition: 150ms;
  margin: 0 20px;

  &:hover {

    .session-user {
      background-color: darken(#fff, 4%);
    }
  }

  .session-user{

    transition: 150ms;
    min-width: 250px;

      &:hover {
      cursor: pointer;
      }
  }

  .session-options-wrapper {
    top: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px #dddddd;
    padding: 1rem;
    z-index: 999;

    section {
      margin: 5px;
      padding: .5rem;

      &:not(.nav-options) {
        padding: 1rem .5rem;
      }

      border-bottom: 1px solid #dddddd;

      p { 

        font-size: .95rem;

        a {
          color: var(--color-light-main);
          text-decoration: underline;
        }
      }

      & > a{
      
        font-weight: 600;

      }
    }

    section:last-of-type {
      border: none;
    }
  }

  @media only screen and (max-width: 740px) {
    margin: 0;

    .session-user {
      justify-content: flex-end;
      margin: 0 10px;
      min-width: inherit;
    }

    .session-options-wrapper {
      width: 250px;
      right: 10px;
    }
  }

}