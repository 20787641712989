/* Functional Component styles */

.claim-picture {
    width: 125px;
    &[with-loader] .claim-default-picture {
        border-color: transparent;
    }
    .claim-default-picture {
        border-radius: 10px;
        border: 2px solid var(--color-translucent-blue);
        background-color: #fff;
        span {
            font-size: 0.7rem;
            color: var(--color-main);
        }
    }
    img {
        width: max-content;
    }
}