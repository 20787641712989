/* Functional Component styles */
$pictureWrapperSize: 200px;

.picture-management-wrapper {

  height: $pictureWrapperSize;
  width: $pictureWrapperSize;
  padding: 0.75rem 0.75rem 0;
  border-radius: 10px;
  border: 2px solid var(--color-translucent-blue);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  &[picture-approved]{
    pointer-events: none;

    & > label {
      display: none;
    }
  }

  h2 {
    font-weight: 600;
    font-size: .9rem;
    max-width: 100px;
    min-height: 40px;
  }

  .picture-status {
    font-size: .8rem;
    font-weight: bold;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--color-light-grey);
    box-shadow: 0 0 5px var(--color-light-grey);
    padding: 0.35rem 0.5rem;
    right: 5px;
    top: 5px;
  }

  .item-picture {

    margin: 5px 0;

    & > img, span {
      width: calc($pictureWrapperSize/1.2);
      height: calc($pictureWrapperSize/1.5);
    }

    span {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    & > img {
      object-fit: cover;
      
    }

    svg {
      color: var(--color-light-main);
    }

    .pdf-file {
      width: calc($pictureWrapperSize/2);
      height: calc($pictureWrapperSize/2);
      margin-top: 10px;
    }
  }

  & > label {
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 10px;
    z-index: 2;

    button {
      border: none;
      font-size: 1.7rem;
      border-radius: 50%;
      padding: 5px;
      background-color: var(--color-main);
      color: #fff;
      transition: background-color 150ms;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;

      button {
        background-color: var(--color-main);
      }
    }
  }


}