@import "/src/styles/v1/variables/screen/screen.scss";

div#vehicle-owner-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;

	section.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	
		h1 { 
			margin: 0 0 60px;
		}

		div.owner-option {
			height: 50px;
			border-top: 2px solid #c8c8c8;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-width: 340px;
			padding: 0 20px;
			cursor: pointer;
	
			div {
				width: 20px;
				height: 20px;
				border: 1px solid #c2c2c2;
				border-radius: 50%;
	
				svg {
					visibility: hidden;
					width: 1.5em;
					height: 1.5em;
				}
	
				&.active {
					background-color: #32BA7C;
					display: flex;
					align-items: center;
					justify-content: center;
	
					svg {
						visibility: visible;
					}
				}
			}
	
			&:first-of-type {
				margin-top: 50px;
			}
	
			&.active {
				font-weight: bold;
	
				div {
					background-color: #32BA7C;
					display: flex;
					align-items: center;
					justify-content: center;
	
					svg {
						visibility: visible;
					}
				}
			}
	
			@media only screen and (max-width: 420px){
				min-width: 280px;
			}
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;
	
			h1, p {
				margin: 0;
			}
	
			p {
				margin-bottom: 15px;
			}
		}
	}
	
	div.button-section {
		margin-top: 20px;
	}

	@media only screen and (max-width: $mobileWidth) {
		overflow: initial;
	}

	@media only screen and (max-width: 400px) {

		section.content {

			h1 {
				margin: 0 0 20px;
			}

			p {
				width: 90%;
				margin: 10px 0 20px;
			}
		}
	}
}