/* Functional Component styles */
#insurance-vehicle-wrapper {

  background-color: #fff;
  z-index: 99990;
  padding: 1.1rem !important;
  max-width: 1300px;
  left: 0;
  right: 0;
  margin: auto;
  overflow-y: auto;
}