/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#policyOwnerWrapper {

	button.back-button {
		text-align: left;
		padding-left: 0;
	}

  //padding: 1rem;

  & > div {

    p {
      margin: 2rem auto 3rem;
      min-width: 300px;
    }
  
    a {
  
      margin: 20px 30px;
      padding: 0.5rem;
      min-width: 115px;
      max-width: 160px;
    }
  
    div {
      margin: auto;
      max-width: 550px;

    }
  }

  @media only screen and (max-width: $mobileWidth) {
    & > div div {
      width: auto;
    }
  }
}