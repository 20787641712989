@use '/src/styles/v2/index.scss' as theme;

.eSign {
  width: 100%;
  height: 100%;
  position: relative;
  @include theme.flexbox-content-center;

  &__wrapper {
    height: 100px;
    min-width: 300px;
    border-bottom-width: 2px;
    border-bottom-style: dashed;
    @include theme.flexbox-column-content-center;
    @extend %border-color-light-grey;
    @extend %bg-color-white;
  }

  &__resizeInfo {
    position: relative;
    
    svg {
      margin-top: 50px;
    }
  }
}
