@import "/src/styles/v1/variables/screen/screen.scss";

div#policy-data-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	div.loading-wrapper {
		text-align: center;
    font-size: 2rem;
    margin: auto;
	}

	section.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: auto;
	
		h1 { 
			margin: 20px 0 30px;
		}

		h3 {
			text-align: center;

			&:last-of-type {
				margin: 30px 0 10px;
			}
		}
	
		& > p {
			width: 80%;
			text-align: center;
			margin: 10px 0 10px;
      vertical-align: middle;

			&:first-of-type {
				margin: 10px 0 0;
			}
		}

		div.input-label-wrapper {
			width: 40%;
			margin-top: 40px;

			@media only screen and (max-width: 600px) {
				width: 80%;
			}
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;

			h1, p {
				margin: 0;
			}

			p.text {
				margin-bottom: 15px;
			}
		}
	}

	@media only screen and (max-width: 400px) {

		section.content {

			h1 {
				margin: 0 0 20px;
			}

			h3 {
				margin: 10px 0 10px;
			}

			p {
				width: 90%;
				margin: 10px 0 30px;
			}
		}
	}
}