/* Functional Component styles */
#password-restore-wrapper {

  background: var(--login-background);
  background-size: var(--login-background-size);
  height: calc(100vh - var(--header-height));

  .wrapper-content {

    max-width: var(--login-content-wrapper-max-width);
    margin: 1rem auto;

    h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    & > form {
      margin: 30px 0;
    }
		
		@media only screen and (max-width: 600px) {
			padding: 0 20px;
		}
  }

  .button-wrapper {
    margin: 30px auto;
  }
}