.background-image {

  background-repeat: no-repeat;
  background-position: center;

  &.image-contain {
    background-size: contain;
  }

  &.image-cover {
    background-size: cover;
  }
}