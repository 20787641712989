@import "/src/styles/v1/variables/screen/screen.scss";

.planDetails {

  // background-color: #F8F8F8;

  max-width: 1000px;
  margin: auto;

  &__section {

    &__type {
      padding: 1rem;

      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 1rem;
      }

      &__action {
        border-radius: 1.5rem;
        background-color: var(--color-light-grey);
        padding: .7rem 1rem;
        margin: 0 2rem;
        transition: 150ms;

        span {
          margin: 0 .5rem;
          font-size: 1rem;
          font-weight: 500;
        }

        svg {
          height: 20px;
          width: 20px;
          margin-left: .5rem;
        }

        &:hover {
          filter: brightness(.95);
        }

        &--mobile {
          display: none;
        }

        @media only screen and (max-width: $mobileWidth) {
        
          display: none;

          svg {
            height: 18px;
            width: 18px;
            margin-left: 0;
          }

          &--mobile {
            display: inherit;
          }
        }
      }
    }

    @media only screen and (max-width: $mobileWidth) {
      
      &__type {
        
        padding: 1rem 0;

        &__action {
          padding: .25rem .5rem;
          margin: 0 .5rem;

          span {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}