
@import "/src/styles/v1/variables/screen/screen.scss";

:root {

  --policy-form-padding: 2rem;
  --policy-form-max-width: 900px;
  --policy-form-margin: auto;
  --policy-form-item-margin: 10px;
  --policy-form-section-margin: 20px 10px;
  
}

@media only screen and (max-width: $mobileWidth) {

  :root {
    --policy-form-padding: 0rem 1rem;
  }
}