@use '/src/styles/v2/index.scss' as theme;

.claimSummary {
  
  &__section {
    margin-top: 10px;

    &__title {
      padding: 10px 0;
      margin-bottom: 20px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      @include theme.flexbox-content-vertical-center;
      @extend %border-color-light-grey;

      span {
        display: block;
        width: 10px;
        height: 10px;
        margin-right: 10px ;
        border-radius: 50%;
        @extend %bg-color-green;
      }

      &__text {
        @include theme.flex(1);
      }

      button {
        @extend %text-color-main;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    &__info {

      &__item {
        margin: 5px 0;

        &__picture {
          @include theme.flexbox-content-center;

          &__wrapper {
            
            margin-bottom: 10px;

            img {
              width: 80px;
              height: 80px;
              object-fit: contain;
            }
          }
        }
      }

      &__pictures {

        @include theme.flexbox-content-vertical-center;
        @include theme.flex-wrap(wrap);

        &__picture {

          margin: 10px;

          img {
            width: 60px;
            height: 60px;
            object-fit: contain;
          }
        }
      }

      &__damages {

        padding: 0 15px;
        margin-bottom: 120px;
      }
    }
  }

  &__section:first-child {
    margin-top: 0;
  }
}