@mixin font-size($diff: 0px, $important: false) {
  @each $breakpoint, $size in $font-sizes {
    @if $breakpoint == null {
      @if $important == false {
        font-size: $size + $diff;
      } @else {
        font-size: $size + $diff !important;
      }
    }
    @else {
      @media screen and (min-width: $breakpoint) {
        @if $important == false {
          font-size: $size + $diff;
        } @else {
          font-size: $size + $diff !important;
        }
      }
    }
  }
}