.radio-dropdown-wrapper {
	transition: border-color 100ms;
  background-color: #fff;
  border-style: solid;
  border-radius: 8px;
  border-width: 1px;
  border-color: transparent;
	cursor: pointer;

	&.transparent {
		background-color: transparent;
	}

	div.placeholder {

		p {
			font-weight: 600;
			font-size: 1rem;
			max-width: 85%;

			span {
				font-size: .85rem !important;
				font-weight: normal;
				color: var(--color-text) !important;
			}

			@media only screen and (max-width: 600px) {
				font-size: .85rem;
			}
		}

		svg {
			position: absolute;
			right: .25rem;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 35px;
			height: 35px;
			transition: transform 150ms;
		}

		@media only screen and (max-width: 400px) {
			padding: .5rem;
		}
	}

	ul.dropdown-opts {
		background-color: #fff;
		top: 60px;
		box-shadow: 0 0 .5rem -.3rem;
		border-radius: 8px;
		overflow: hidden;

		li {

			padding: .4rem 0;
			border-top: 1px solid var(--color-light-grey);
		}

		@media only screen and (max-width: 600px) {
			top: 50px;

			li {
				padding: .2rem 0;
				font-size: .8rem;
			}
		}
	}

	&.active {
		//box-shadow: 0 0 .5rem -.3rem;

		div.placeholder {

			svg {
				transform: rotate(180deg);
			}
		}
	}
}
