@use '/src/styles/v2/index.scss' as theme;

.claimPicture {

  &__picture {

    border-radius: 12px;
    width: 100%;
    max-width: 250px;
    height: 125px;
    margin: 20px auto 10px;
    position: relative;
    @include theme.flexbox-column-content-center;
    @include theme.shadow(5px);
    @extend %bg-color-white;

    &__edit, &__close {
      @include theme.shadow;
      padding: 5px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      position: absolute;
      top: -15px;
      right: -20px;
      z-index: 1;

      svg {
        @extend %text-color-white;
      }
    }

		&__edit {
			@extend %bg-color-main;

			svg {
        @extend %text-color-white;
        width: 22px;
        height: 22px;
      }
		}

		&__close {
			top: -10px;
			right: -15px;
			padding: 3px;
			@extend %bg-color-grey;

			svg {
        width: 27px;
        height: 27px;
      }
		}

    &__button {
      width: 100%;
      height: 100%;

      svg {
        width: 50px;
        height: 50px;
        @extend %text-color-light-grey;
      }
    }

    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 12px;
    }
  }
}