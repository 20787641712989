@import "/src/styles/v1/variables/screen/screen.scss";

div#id-documents-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	height: 100%;

	section.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: auto;
	
		h1 { 
			margin: 0 0 60px;
		}
	
		p {
			width: 80%;
			text-align: center;
			margin: 20px 0 30px;
		}

		div.documents-wrapper {
			display: flex;
			justify-content: space-evenly;
			flex-wrap: wrap;

			div.picture-card-wrapper {
				margin-top: 20px;
			}
		}

		@media only screen and (max-width: 540px){
			justify-content: flex-start;
	
			h1, p {
				margin: 0 0 10px;
			}
	
			p {
				margin-bottom: 15px;
			}

			h1,h3,p {
				text-align: center;
			}
		}
	}

	@media only screen and (max-width: $mobileWidth) {
		overflow: initial;
	}

	@media only screen and (max-width: 400px) {

		section.content {

			h1 {
				margin: 0 0 20px;
				text-align: center;
			}

			p {
				text-align: center;
				width: 90%;
				margin: 10px 0 30px;
			}
		}
	}
}