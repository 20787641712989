/* Functional Component styles */
.claim-payment-indicator {
	border: 1px solid #c9c9c9;
	height: 20px;
	width: 20px;
	position: absolute;
	right: 12px;
	top: 0;
	bottom: 0;
	margin: auto 0;
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;

  &.active {
    border: 2px solid var(--color-main);

		svg {
			fill: var(--color-main);
			stroke: var(--color-main);
		}
    
  }

	&.done {
		background-color: var(--color-green);
    border: 1px solid var(--color-green);

		svg {
			fill: #fff;
			stroke: #fff;
		}
	}

	&.missingInfo {
		background-color: var(--color-yellow);
		border: 1px solid var(--color-yellow);

		svg {
			width: 60%;
			height: 60%;
			fill: #fff;
		}
	}

	&.hasErrors {
		background-color: var(--color-red);
		border: 1px solid var(--color-red);

		svg {
			width: 110%;
			height: 110%;
			fill: #fff;
		}
	}
}