/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

#endorsements-steps-wrapper {

  min-width: 250px;
  height: calc(100vh - var(--header-height));
  position: sticky;
  top: var(--header-height);
  background-color: #fff;

  .endorsements-steps-topbar {
    margin: auto;
    padding: 2rem 0 0;
    display: none;

  }

  .endorsements-steps-sidebar {

    padding: 2rem;
  }

  @media only screen and (max-width: $mobileWidth) {
    height: auto;
    z-index: 99;
    pointer-events: none;
    padding-top: 3rem;

    .endorsements-steps-sidebar {
      display: none;
    }

    .endorsements-steps-topbar {
      display: flex;
    }
  }

  @media only screen and (max-width: 460px) {
    .endorsements-steps-topbar {
      padding: .5rem;
    }
  }

}

