/* Functional Component styles */
.claim-payment-sidebar-text {

  margin-left: 0.5rem;
  font-size: 0.8rem;
  transition: 100ms;
  transform-origin: left;

  &.selected {
		color: var(--color-main);
    font-size: 0.9rem;
    font-weight: 600;
  }
}