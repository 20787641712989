/* Functional Component styles */
.policy-warning {

  margin: 15px auto 0;
  padding: .75rem;
  border-radius: 10px;

  
  span {
    color: #fff;
    font-size: .9rem;
    font-weight: 400;
  }
  
  a {
    border: none;
    background-color: #fff;
    color: var(--color-light-main);
    width: 110px;
    padding: .75rem;
    font-weight: bold;
    border-radius: 10px;
    font-size: .9rem;
    margin: 0 10px;
    line-height: 1;
    
    &:hover {
      background-color: #f1f1f1;
    }
  }

  &.pictures {
    background-color: var(--color-red);
  }
  &.payment {
    background-color: var(--color-yellow);
  
    span {
      color: var(--color-text);
    }

    a {
      width: 120px;
    }
  }

}
