/* Functional Component styles */

.endorsement {
  padding: 1rem 0.5rem;

  .endorsement-inputs > div {
    flex: 1;
    margin: 0 5px;

    .input-disabled {
      background-color: #eeeeee;
    }

    .new-endorse-data-input {
      text-transform: uppercase;
    }
  }

  div.absolute {
    top: 5px;
    right: 0px;
    width: 25px;
    height: 25px;
    display: none;

    button {
      border-radius: 50%;
      border: none;
      background-color: var(--color-light-main);
      color: #fff;
      font-size: 1.1rem;
      padding: 0;
      line-height: 1;
      box-shadow: 1px 1px 3px var(--color-grey);

      &:hover {
        opacity: 0.9;
      }
    }
  }

  &:not([index-zero]):hover div.absolute {
    display: block;
  }


  @media only screen and (max-width: 750px) {
    max-width: 450px;
    margin: 1rem auto;
    border-bottom: 1px solid var(--color-translucent-blue);

    .endorsement-inputs {
      flex-direction: column;
    }

    &:not([index-zero]) div.absolute {
      display: block;
    }
  }
}
