@use '/src/styles/v2/index.scss' as theme;

.claimSignature{

  margin: 20px 0;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
  @extend %border-color-light-grey;

  &__content {

    height: 200px;
    @include theme.flexbox-content-center
  }

  &__name {
    margin: 10px 0;
  }
}