/* Functional Component styles */
.password-managment-wrapper {

  .password-section {
    margin-top: 20px;
  }

  .password-params {
  
    ul {

      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 400px;

      li {
        margin: 2.5px 0;

        span {

          &.validation-icon {

            color: var(--color-grey);
            margin-right: 10px;

            &.invalid-param {
              width: 22px;
              height: 24px;
              
              &::after{
                content: "";
                width: 5px;
                height: 5px;
                background-color: var(--color-grey);
                border-radius: 50%;
                display: block;
                margin: auto;
              }
            }
            


            &.valid-param {
              color: var(--color-green);

              svg {
                width: 22px;
                height: 22px;
              }
            }
          }

          font-size: .9rem;
        }
      }
    }

    @media only screen and (max-width: 450px) {
      margin: 0;
    }
  }
}
