/* Functional Component styles */
#reward-details-wrapper{

  min-width: 300px;
  max-width: 450px;
  width: 50vw;
  padding: 1rem .5rem;
  min-height: 200px;

  .spinner {
    width: 40px;
    height: 40px;
  }

  & > button {
    right: 5px;
    top: 5px;
    border-radius: 50%;
    border: none;
    background-color: #fff;
    width: 25px;
    height: 25px;
    padding: 0;
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.4rem;
    margin: .5rem;
  }

  section {

    img {
      object-fit: cover;
      width: 275px;
      height: 135px;
      border-radius: 5px;
      margin: 20px auto;
    }


    & > span {
      width: 50px;
      height: 50px;
      margin: 5px 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    h3 {

      font-size: 1rem;

      span {
        font-weight: bold;
        font-size: 1.5rem;
      }
    }

    &.current-points {
      height: 50px;
      justify-content: flex-end;
    }


    &.buttons-wrapper {
      margin: 20px auto;
      max-width: 250px;
       
      button {
        margin: 10px 0;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 0;

    section {

      img {
        margin: 0 auto;
      }

      & > span {
        width: 30px;
        height: 30px;
        margin: 10px 0 0;
      }

      h3 {
        font-size: .8rem;

        span {
          font-size: 1.2rem;
        }
      }

      &.current-points {
        height: 30px;
      }

      &.buttons-wrapper {
        margin: 0 auto 20px;
      }
    }
  }
}