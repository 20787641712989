.text-center {

  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-line-through {
  text-decoration: line-through;
}