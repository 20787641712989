/* Functional Component styles */
.insurance-picture-item{
  margin: 15px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #d6d6d6;

  & > section {

    &.vehicle-picture-name {
      background-color: var(--color-main);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      h3 {
        padding: .5rem 1rem;
        font-size: .9rem;
        color: #fff;
        font-weight: normal;
      }
    }
    
    span {
      background-size: 95%;
    }

    &.flex-grow-100 {

      max-width: 170px;
      min-width: 150px;
      width: 45vw;
      height: 45vw;
      max-height: 170px;
      min-height: 150px;

      img {
        object-fit: cover;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  & > button {

    background-color: var(--color-light-main);
    border: none;
    border-radius: 50%;
    padding: 0;
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 10px;

    svg {
      color: #fff;
      font-size: 2rem;
    }


  }
}

.vehicle-picture-options {
  
  button {
    max-width: 300px;
    margin: 15px auto;
  }
}