/* Functional Component styles */
.policy-onboarding-wrapper {
	max-height: 600px;
	width: 600px;
	padding: 2rem 0;

	h2 {
		margin: 0;
		font-size: 1.3rem;
	}

	p {
		margin: 0;
	}

	div.onboarding-image {
		width: calc(100% - 20px);
    height: 250px;
		margin: 25px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	}

	div.buttons-wrapper {
		display: flex;
		justify-content: center;

		button {
			width: 200px;
			margin: 0 10px;
		}

		@media only screen and (max-width: 600px) {
			flex-direction: column-reverse;
			align-items: center;

			button {
				margin: 10px 0;
			}
		}
	}

	@media only screen and (max-width: 600px){
		width: 90vw;
		height: 90%;
		
		button {
			margin-bottom: 10px;
		}
	}

	@media only screen and (max-width: 540px){
		h1 {
			font-size: 1.6rem;
		}

		h2 {
			font-size: 1rem;
		}

		p {
			font-size: .9rem;
		}

		div.onboarding-image {
			height: 200px;
			margin: 25px 0 0;
		}
	}
}