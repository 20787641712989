@import "/src/styles/v1/variables/screen/screen.scss";

:root {

  --form-input-border-width: 2px;
  --form-input-border-radius: 10px;
  --form-input-border-color: #afb6c0;
  --form-input-padding: .9rem 0.7rem;
  --form-input-wrapper-margin: 7px 0;
}

@media only screen and (max-width: $mobileWidth) {
  :root {
    --form-input-padding: .8rem 0.7rem;
  }
}