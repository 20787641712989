@import "/src/styles/v1/variables/screen/screen.scss";

div.payment-option-wrapper {

	div.payments {
		border: 2px solid var(--color-light-grey);
    border-radius: 8px;
		margin: 1.5rem 0;
		transition: background-color 200ms;

		&:hover {
			background-color: #eeeeee;
		}
		//padding: .5rem 2rem;

		section {

			& > p {
				margin: 1rem 0;
			}
			
			&.left-section {
				padding: .8rem 0 .8rem 1.5rem;
				border-radius: 8px;

				& > div {
					height: 2.5rem;
					align-items: center;
					border-right: 2px solid var(--color-light-grey);

					p {
						padding-left: .5rem;
						font-weight: 600;

						&.mobile-text {
							display: none;
						}

						@media only screen and (max-width: 500px) {
							display: none;

							&.mobile-text {
								display: block;
							}
						}
					}
				}

				@media only screen and (max-width: 550px) {
					padding: .6rem 0 .6rem 1rem;

					& > div {

						p {
							padding-left: .3rem;
							font-size: .9rem;
						}
					}
				}
			}

			&.right-section {
				padding: 0;

				& > p {
					margin: 0 1rem;

					font-weight: 600;

					span {
						font-weight: 300;
					}
				}
				
				@media only screen and (max-width: 500px) {

					& > p {
						font-size: .9rem;
						margin: 0 .8rem;
					}
				}
			}

			&.active {
				background-color: var(--color-main);
				
				div {
					border-right: 0;

					p {
						color: #fff;
					}
				}
			}
			// &:last-of-type {
			// 	border-left: 2px solid var(--color-light-grey);
			// 	padding: 0;
			// }
		}

		&.payments {

			section.right-section {

				p {
					margin: 0 1rem;

					&.advisor {
						font-size: .7rem;
						font-weight: 600;

						&.mobile-text {
							display: none;
						}
					}
				}

				@media only screen and (max-width: 500px) {

					p {
						margin: 0 .8rem;
						font-size: .9rem;

						&.advisor{
							font-size: .6rem;
						}
					}
				}

				@media only screen and (max-width: 405px){

					p {
						font-size: .8rem;

						&.advisor {
							display: none;

							&.mobile-text {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	p.months-text {
		margin: .5rem 0 0 auto;
		font-size: .7rem;
		font-weight: 600;
	}
}