/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

$menuWidth: 250px;

#menu-wrapper {

  aside:not(.mobile) {
    position: sticky;
    top: var(--header-height);
    height: calc(100vh - var(--header-height));
  }

  aside{
    min-width: $menuWidth;

    &.mobile {
      display: none;
      z-index: 99;
      transform: translateX(-$menuWidth - 10px);
      transition: 250ms;
      padding-top: var(--header-height);

      .menu-logo {

        &:hover{ 
          cursor: pointer;
        }
  
        svg {
          font-size: 1.8rem;
          margin: 20px;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: -1;
    }

    nav {
      margin-top: 2rem;
    }
  }

  .menu-content-background {
    z-index: 98;
    background-color: #000;
    opacity: 0.3;
    display: none;
  }

  footer {

    padding: 3rem 1rem;
  }

  @media only screen and (max-width: $mobileWidth) {


    aside {
      display: none;

      &.mobile {
    
        display: flex;
      
        &::after {
          top: 0;
          box-shadow: 0 0 10px #6a6a6a;
        }
      }
    }


    &[menu-visible] {

      .menu-content-background {
        display: inherit;
        width: 100vw;
        height: 100vh;
        pointer-events: inherit;
      }

      aside.mobile {
        transform: translateX(0);
      }
    }
  }
  
}