/* Functional Component styles */
#endorsement-identity-completed-wrapper {
  height: calc(80vh - var(--header-height));

  .identity-completed-background {
    width: 360px;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
  }

  .identity-completed-icon{
    width: 50px;
    height: 50px;
    background-color: var(--color-green);
    color: #fff;
    border-radius: 50%;
    font-size: 2.5rem;
    z-index: 1;
    bottom: 30px;
    left: 70px;
    right: 0;
    margin: auto;
    border: 3px solid #fff;
  }

  h1 {
    font-weight: normal;
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 600px) {

    padding: 1rem;

    .identity-completed-background {
      width: 300px;
      height: 240px;
    }

    .identity-completed-icon{
      bottom: 10px;
      left: 50px;
    }

    h1, h2 {
      font-size: 1.3rem;
    }

    p {
      font-size: 1.1rem;
    }
  }
}