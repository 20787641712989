/* Functional Component styles */
.policy-documents-wrapper{

  .documents-toggle{
    border-radius: 8px;
    border: 2px solid var(--color-main);
    font-weight: bold;
    background-color: #fff;
    padding: 0.6rem 1rem;
    width: 240px;
    height: 40px;
    -webkit-user-select: none;
    user-select: none;
    transition: 150ms;
    box-sizing: border-box;
    font-size: 0.8rem;

    &:hover{
      cursor: pointer;
      background-color: var(--color-light-main);
      border-color: var(--color-light-main);

      span, svg {
        color: #fff;
      }
    }
    
    span, svg {
      display: inline-block;
      vertical-align: middle;
      color: var(--color-main);
    }

    svg {
      margin-left: 10px;
      width: 25px;
      height: 25px;
    }
  }


  .documents {
    position: absolute;
    top: -800%;
    background-color: #fff;
    width: 300px;
    padding: 1rem;
    box-shadow: 1px 2px 5px #bbb;
    border-radius: 20px;
    left: -25px;

    & > * {
      transition: 150ms;
      animation: 200ms ease itemIn;
      animation-fill-mode:forwards;
      -webkit-animation: 200ms ease itemIn;
      -webkit-animation-fill-mode: forwards;
      opacity: 0;
    }

    $delaySum: .1;
    $delay: 0s;
    @for $i from 1 through 4 {
    
      button:nth-of-type(#{$i}) {
        animation-delay: $delay
      }
      $delay: $delay + .1s;
    }

    @keyframes itemIn {
      0% { opacity: 0; transform: translateX(-15%); }
      100% { opacity: 1; transform: translateX(0); }    
    }

    &::after {
      content: "";
      position: absolute;
      width: 50px;
      height: 50px;
      background: #fff;
      transform: rotate(45deg);
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      z-index: -1;
      box-shadow: 1px 2px 5px #bbb
    }

    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      background: #ffffff;
      transform: rotate(45deg);
      left: 0;
      right: 0;
      margin: auto;
      bottom: -15px;
      z-index: 0;
    }
  }
}