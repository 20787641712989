/* Functional Component styles */
.input-phone-wrapper{

  .country-flag {
    width: 30px;
    height: 20px;
  }

  span {
    margin-left: 5px;
    font-size: .9rem;
  }

  .country-code-wrapper{

    padding: 0 .5rem;
    border-radius: var(--form-input-border-radius);
    transition: 150ms;
    



    svg {
      font-size: 1.2rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #eee;
      
    }
  }
  
  .country-selected input {
    padding-left: .25rem;
  }

  .countries {
    position: absolute;
    width: 100%;
    left: 0;
    top: 120%;
    overflow-y: auto;
    max-height: 250px;
    z-index: 99;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    padding: 0.5rem 0;

    .search-country {

      margin: 0.5rem 1rem;
      border-bottom: 2px solid #ddd;

      svg {
        font-size: 1.2rem;
        color: var(--color-grey);
      }

      input {
        flex: 1;
        padding: 0.5rem;
      }

      &:focus-within {
        border-color: var(--color-main);

        svg {
          color: var(--color-main);
        }
      }
    }
  }
}