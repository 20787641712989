/* Functional Component styles */
@import "/src/styles/v1/variables/screen/screen.scss";

.appHeader {
  height: var(--header-height);
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 9990;
  box-shadow: var(--dashboard-box-shadow);

  &__content {

    max-width: $maxWidth;
    margin: auto;
    height: 100%;

    &__logo {

      button {

        font-size: 1.8rem;
        background-color: transparent;
        border: none;
        width: 30px;
        height: 30px;
        display: none;
        margin: 0 1.5rem;
        padding: 0;
      }
    }
  }

  @media only screen and (max-width: $mobileWidth) {

    width: 100%;
    position: fixed;
  
    &__content {

      &__logo {

        align-items: center;

        span[hide-logo] {
          display: none;
        }
        
        button {
          display: block;
        }
      }
    }
  }
  
}