@use '/src/styles/v2/index.scss' as theme;

.documentSignature {

  @include theme.content-height;

  &__content {

    max-width: 450px;
    margin: 30px auto;
    position: relative;

    &__form {

      margin-top: 20px;
      @include theme.flexbox-column-content-center;
    }

    &__signature {

      margin: 20px 10px;
      padding: 20px;
      border-radius: 10px;
      border-width: 1px;
      border-style: solid;
      @extend %border-color-light-grey;
    }

    &__completed {

      margin-top: 50px;
      padding: 20px;

      &__info {
        margin-top: 30px;
      }

      &__link {

        margin-top: 30px;

        a {
          @extend %text-color-main;
        }
      }

    }


    &__button-back {
      position: absolute;
      top: -10px;
      left: 20px;
      z-index: 10;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__button-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 100%;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      @include theme.top-shadow;
      z-index: 100;

      &>button {
        max-width: 275px;
        margin: auto;
        display: block;
      }

    }
  }



}