/* Functional Component styles */

.datepicker-wrapper {

  &.calendar-active {
    border-color: var(--color-light-main) !important;

    label {
      color: var(--color-main);
    }
  }

  input {
    pointer-events: none;
  }
  
  .calendar-wrapper {
    z-index: 99;
    box-shadow: 1px 2px 10px #ababab;
    border-radius: 5px;

    &.bottom {
      top: 100%;
    }

    &.top {
      bottom: 125%;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

