/* Functional Component styles */
.iddle-wrapper{

  padding: 1rem;
  max-width: 300px;

  svg {
    width: 45px;
    height: 45px;
    color: var(--color-main);
  }

  h2 {
    margin: 10px 0 15px;
  }

  .description {
    margin: 20px 0;
  }

  button {
    max-width: 200px;
    margin: 10px auto;
  }
}